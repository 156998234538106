/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { Accordion } from '../../../components/Accordion';
import { Button } from '../../../components/Button';
import { FlexGrid } from '../../../components/FlexGrid';
import { PageHeader } from '../../../components/PageHeader';
import { Text } from '../../../components/Text';
import { COLORS } from '../../../styles/variables';
import {
    DateIcon,
    TimeIcon,
    BonusPointsIcon,
    TargetScansIcon,
} from '../../../assets/icons';
import { ScanningContestType, LookupValue } from '../../../types';
import { notificationIcons } from '../../Videos/components/SendNotification';
import { SuccessModal } from '../../../components/SuccessModal';
import { getUrlKeepQuery } from '../../../utils';
import { CreationService } from '../../../services/Creation.service';
import { DropDownPopulate } from '../../../services/Api.service';
import { SectionHeader } from '../../../components/SectionHeader';
import { useDispatch } from 'react-redux';
import { updateModalAction } from '../../../store/store.actions';

const EditBtn = styled(Button)`
    font-size: 15px;
    font-family: 'AR';
`;

const Label = styled(Text)`
    color: ${COLORS.darkGray};
    font-size: 0.75rem;
`;

const Value = styled(FlexGrid)`
    width: 50%;
    flex-direction: column;
    gap: 0.5em;
    padding: 1em 2em;
    min-width: 0;
`;

const Form = styled(FlexGrid)`
    max-width: 700px;
    margin-top: 0.5em;
`;

const Action = styled(FlexGrid)`
    border-top: 1px solid ${COLORS.lightGray};
    width: 100%;
    justify-content: space-between;
    padding: 1em;
    margin-top: 2em;
    align-items: center;
`;

interface Props {
    mode?: 'Create' | 'Edit' | 'View';
    stepNavigator: any;
    scanningContestState: [
        ScanningContestType,
        React.Dispatch<React.SetStateAction<ScanningContestType>>
    ];
    dropdownPopulate?: DropDownPopulate;
}

export const Review: React.FC<Props> = ({
    stepNavigator,
    scanningContestState,
    mode,
    dropdownPopulate,
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [submitModalOpen, setSubmitModalOpen] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [scanningContest] = scanningContestState;
    const {
        identifierDefinition,
        content,
        targetGeography,
        targetUser,
        sendNotification,
    } = scanningContest;

    console.log('==scanningContest==', scanningContest);

    const location = useLocation() as any;

    const [sectorMapping, setSectorMapping] = useState(
        new Map<string, LookupValue>()
    );
    const [subSectorsMapping, setSubSectorsMapping] = useState(
        new Map<string, LookupValue>()
    );

    const isInAppNotification = sendNotification.notificationType.includes(
        'In-App Notifications'
    );

    const isPushNotification =
        sendNotification.notificationType.includes('Push notifications');

    useEffect(() => {
        if (dropdownPopulate.sectors && dropdownPopulate.subSectors) {
            setSectorMapping(
                new Map(dropdownPopulate.sectors.map((elem) => [elem.id, elem]))
            );
            setSubSectorsMapping(
                new Map(
                    // @ts-ignore
                    Object.keys(dropdownPopulate.subSectors)
                        .map((sectorId) => {
                            return dropdownPopulate.subSectors[sectorId].map(
                                (elem) => [elem.id, elem]
                            );
                        })
                        .reduce((pre, cur) => pre.concat(cur), [])
                )
            );
        }
    }, [dropdownPopulate]);

    const createScanningContest = () => {
        setDisableSubmit(true);
        const isUpdate = mode === 'Edit';
        CreationService.createUpdateScanningContest(dispatch, isUpdate, true, scanningContest)
            .then((result) => {
                setDisableSubmit(false);
                scanningContest.raw = result;
                setSubmitModalOpen(true);
            })
            .catch((e) => {
                console.error('===Failed to create update scanningContest===', e);
                setDisableSubmit(false);
                dispatch(
                    updateModalAction({
                        open: true,
                        title: 'error',
                        content: `Failed to ${isUpdate ? 'update' : 'create'} scanning contest. ${e}. ${e?.config?.url ? `url: ${e?.config?.url}` : ''}`,
                    })
                );
            });
    };

    return (
        <FlexGrid width="100%" direction="column">
            <SuccessModal
                header={
                    mode === 'Edit'
                        ? 'Edit Scanning Contest'
                        : 'Create New Scanning Contest'
                }
                open={submitModalOpen}
                onClose={() => {
                    setSubmitModalOpen(false);
                    navigate(
                        getUrlKeepQuery('/scheme-offers/scanning-contests'),
                        {
                            state: { page: location.state?.page },
                        }
                    );
                }}
                body={
                    mode === 'Edit'
                        ? 'Scanning contest has been updated successfully.'
                        : 'New scanning contest have been submitted successfully.'
                }
            />
            <PageHeader
                title={
                    <Text font="OS" fontSize="1.375rem" uppercase>
                        Review
                    </Text>
                }
            />
            <Form direction="column" p="0.5em" width="100%">
                <Accordion
                    info={{
                        title: 'Show or Hide Details',
                        content:
                            'Click to show or hide in each section. And you can edit each section by clicking edit. Submit if all data is correct.',
                    }}
                    title={
                        <FlexGrid
                            flex="1"
                            justifyContent="space-between"
                            alignItems="center"
                            m="0 0.5em 0 0"
                        >
                            <Text font="OS Bold" uppercase>
                                Identifier Definition
                            </Text>
                            {mode !== 'View' && (
                                <EditBtn
                                    m="0"
                                    p="0"
                                    negative
                                    onClick={() => {
                                        stepNavigator(0);
                                    }}
                                >
                                    Edit
                                </EditBtn>
                            )}
                        </FlexGrid>
                    }
                    content={
                        <FlexGrid width="100%" wrap="wrap">
                            <Value>
                                <Label>Contest Name</Label>
                                <Text>{identifierDefinition.name}</Text>
                            </Value>
                            <Value>
                                <Label>App Language</Label>
                                <Text>
                                    {identifierDefinition.appLanguages.join(
                                        ', '
                                    )}
                                </Text>
                            </Value>
                            <Value>
                                <Label>Start Date</Label>
                                <FlexGrid
                                    alignItems="center"
                                    width="100%"
                                    gap="0.5em"
                                >
                                    <DateIcon />
                                    <Text>
                                        {identifierDefinition.startDate}
                                    </Text>
                                </FlexGrid>
                            </Value>
                            <Value>
                                <Label>Start Time</Label>
                                <FlexGrid
                                    alignItems="center"
                                    width="100%"
                                    gap="0.5em"
                                >
                                    <TimeIcon />
                                    <Text>
                                        {identifierDefinition.startTime}
                                    </Text>
                                </FlexGrid>
                            </Value>
                            <Value>
                                <Label>End Date</Label>
                                <FlexGrid
                                    alignItems="center"
                                    width="100%"
                                    gap="0.5em"
                                >
                                    <DateIcon />
                                    <Text>{identifierDefinition.endDate}</Text>
                                </FlexGrid>
                            </Value>
                            <Value>
                                <Label>End Time</Label>
                                <FlexGrid
                                    alignItems="center"
                                    width="100%"
                                    gap="0.5em"
                                >
                                    <TimeIcon />
                                    <Text>{identifierDefinition.endTime}</Text>
                                </FlexGrid>
                            </Value>
                        </FlexGrid>
                    }
                />
                <Accordion
                    info={{
                        title: 'Show or Hide Details',
                        content:
                            'Click to show or hide in each section. And you can edit each section by clicking edit. Submit if all data is correct.',
                    }}
                    title={
                        <FlexGrid
                            flex="1"
                            justifyContent="space-between"
                            alignItems="center"
                            m="0 0.5em 0 0"
                        >
                            <Text font="OS Bold" uppercase>
                                Content Definition
                            </Text>
                            {mode !== 'View' && (
                                <EditBtn
                                    m="0"
                                    p="0"
                                    negative
                                    onClick={() => {
                                        stepNavigator(0);
                                    }}
                                >
                                    Edit
                                </EditBtn>
                            )}
                        </FlexGrid>
                    }
                    content={
                        <FlexGrid width="100%" wrap="wrap" direction="column">
                            <Value>
                                <Label>Applicable Brand</Label>
                                {content.applicableBrands.map(
                                    (brand, index) => (
                                        <Text
                                            key={`contest-brand-${index}`}
                                            color={COLORS.green}
                                        >
                                            {brand}
                                        </Text>
                                    )
                                )}
                            </Value>
                            <Value>
                                <Label>Applicable SKUs</Label>
                                {content.applicableSKUs.map((sku, index) => (
                                    <Text
                                        key={`contest-sku-${index}`}
                                        color={COLORS.green}
                                    >
                                        {sku}
                                    </Text>
                                ))}
                            </Value>
                            {content.schemes.map((schemeOffer, index) => (
                                <Accordion
                                    key={`contest-scheme-offer-${index}`}
                                    defaultOpen={false}
                                    info={{
                                        title: 'Show or Hide Details',
                                        content:
                                            'Click to show or hide in each section. And you can edit each section by clicking edit. Submit if all data is correct.',
                                    }}
                                    title={
                                        <SectionHeader
                                            title={`SCHEME AND OFFER #${
                                                index + 1
                                            }`}
                                            bg="#F5F5F5"
                                            fontFamily="OS"
                                            height="40px"
                                            titleMargin="0 0 0 1em"
                                        />
                                    }
                                    content={
                                        <>
                                            <Value>
                                                <Label>Type</Label>
                                                <Text>
                                                    {
                                                        schemeOffer.content
                                                            .schemeType
                                                    }
                                                </Text>
                                            </Value>
                                            <Value>
                                                <Label>Target Scans</Label>
                                                <FlexGrid
                                                    alignItems="center"
                                                    width="100%"
                                                    gap="0.5em"
                                                >
                                                    <TargetScansIcon />
                                                    <Text>
                                                        {schemeOffer.content
                                                            .targetScans || 0}
                                                    </Text>
                                                </FlexGrid>
                                            </Value>
                                            <Value>
                                                <Label>
                                                    Points for Achievement
                                                </Label>
                                                <FlexGrid
                                                    alignItems="center"
                                                    width="100%"
                                                    gap="0.5em"
                                                >
                                                    <BonusPointsIcon />
                                                    <Text>
                                                        {schemeOffer.content
                                                            .awardedPoints || 0}
                                                    </Text>
                                                </FlexGrid>
                                            </Value>
                                        </>
                                    }
                                />
                            ))}
                        </FlexGrid>
                    }
                />
                <Accordion
                    defaultOpen={false}
                    info={{
                        title: 'Show or Hide Details',
                        content:
                            'Click to show or hide in each section. And you can edit each section by clicking edit. Submit if all data is correct.',
                    }}
                    title={
                        <FlexGrid
                            flex="1"
                            justifyContent="space-between"
                            alignItems="center"
                            m="0 0.5em 0 0"
                        >
                            <Text font="OS Bold" uppercase>
                                Content
                            </Text>
                            {mode !== 'View' && (
                                <EditBtn
                                    m="0"
                                    p="0"
                                    negative
                                    onClick={() => {
                                        stepNavigator(1);
                                    }}
                                >
                                    Edit
                                </EditBtn>
                            )}
                        </FlexGrid>
                    }
                    content={
                        <>
                            {identifierDefinition.appLanguages.map(
                                (language) => (
                                    <>
                                        <div
                                            style={{
                                                width: '100%',
                                                borderBottom: `1px solid ${COLORS.gray}`,
                                            }}
                                        >
                                            <Text
                                                key={`video-text-${language}`}
                                                m="0.5em 0 0 1.1em"
                                                font="OS"
                                                fontSize="1.1em"
                                                fontWeight="10"
                                            >
                                                {language}
                                            </Text>
                                        </div>
                                        <FlexGrid
                                            key={`scanningContest-flexgrid1-${language}`}
                                            width="100%"
                                            wrap="wrap"
                                            direction="column"
                                        >
                                            <Value>
                                                <Label>Headline</Label>
                                                <Text>
                                                    {
                                                        content.headline[
                                                            language ||
                                                                'English'
                                                        ]
                                                    }
                                                </Text>
                                            </Value>
                                            <Value>
                                                <Label>Description</Label>
                                                <Text>
                                                    {
                                                        content.description[
                                                            language ||
                                                                'English'
                                                        ]
                                                    }
                                                </Text>
                                            </Value>
                                        </FlexGrid>
                                    </>
                                )
                            )}
                        </>
                    }
                />
                <Accordion
                    defaultOpen={false}
                    info={{
                        title: 'Show or Hide Details',
                        content:
                            'Click to show or hide in each section. And you can edit each section by clicking edit. Submit if all data is correct.',
                    }}
                    title={
                        <FlexGrid
                            flex="1"
                            justifyContent="space-between"
                            alignItems="center"
                            m="0 0.5em 0 0"
                        >
                            <Text font="OS Bold" uppercase>
                                Target Geography
                            </Text>
                            {mode !== 'View' && (
                                <EditBtn
                                    m="0"
                                    p="0"
                                    negative
                                    onClick={() => {
                                        stepNavigator(2);
                                    }}
                                >
                                    Edit
                                </EditBtn>
                            )}
                        </FlexGrid>
                    }
                    content={
                        <FlexGrid width="100%" wrap="wrap">
                            <Value>
                                {targetGeography.regions.map(
                                    (region, index) => (
                                        <Text
                                            key={`scanningContestTargetGeography-${index}`}
                                        >
                                            {region}
                                        </Text>
                                    )
                                )}
                            </Value>
                            <Value>
                                {targetGeography.states.map((state, index) => (
                                    <Text
                                        key={`scanningContestTargetState-${index}`}
                                    >
                                        {state}
                                    </Text>
                                ))}
                            </Value>
                            <Value>
                                <Label>
                                    Distributors (
                                    {targetGeography.distributors.length})
                                </Label>
                                {targetGeography.distributors.map(
                                    (distributor, index) => (
                                        <Text
                                            key={`scanningContestDistributors-${index}`}
                                        >
                                            {distributor}
                                        </Text>
                                    )
                                )}
                            </Value>
                            <Value>
                                <Label>District</Label>
                                {targetGeography.districts.map(
                                    (district, index) => (
                                        <Text
                                            key={`scanningContestDistricts-${index}`}
                                        >
                                            {district}
                                        </Text>
                                    )
                                )}
                            </Value>
                            <Value>
                                <Label>Pincode</Label>
                                <Text>{targetGeography.pincode}</Text>
                            </Value>
                            <Value>
                                <Label>RM Area</Label>
                                {targetGeography.rmAreas.map(
                                    (rmArea, index) => (
                                        <Text
                                            key={`scanningContestRmAreas-${index}`}
                                        >
                                            {rmArea}
                                        </Text>
                                    )
                                )}
                            </Value>
                        </FlexGrid>
                    }
                />
                <Accordion
                    defaultOpen={false}
                    info={{
                        title: 'Show or Hide Details',
                        content:
                            'Click to show or hide in each section. And you can edit each section by clicking edit. Submit if all data is correct.',
                    }}
                    title={
                        <FlexGrid
                            flex="1"
                            justifyContent="space-between"
                            alignItems="center"
                            m="0 0.5em 0 0"
                        >
                            <Text font="OS Bold" uppercase>
                                Target User
                            </Text>
                            {mode !== 'View' && (
                                <EditBtn
                                    m="0"
                                    p="0"
                                    negative
                                    onClick={() => {
                                        stepNavigator(3);
                                    }}
                                >
                                    Edit
                                </EditBtn>
                            )}
                        </FlexGrid>
                    }
                    content={
                        <FlexGrid width="100%" wrap="wrap">
                            <Value>
                                <Label>User Type</Label>
                                <Text>{targetUser.userTypes.join(', ')}</Text>
                            </Value>
                            {targetUser.userTypes.includes('Mechanics') && (
                                <>
                                    <Value>
                                        <Label>Space of Mechanic</Label>
                                        <Text>
                                            {targetUser.spaceOfMechanics.join(
                                                ', '
                                            )}
                                        </Text>
                                    </Value>
                                    {/* <Value>
                                        <Label>Connekt</Label>
                                        <Text>
                                            {targetUser.connekt ? 'Yes' : 'No'}
                                        </Text>
                                    </Value> */}
                                </>
                            )}
                            <Value>
                                <Label>Upload Mobile</Label>
                                <Text color={COLORS.green}>
                                    {targetUser.mobile}
                                </Text>
                            </Value>
                            <Value>
                                <Label>Upload PID</Label>
                                <Text color={COLORS.green}>
                                    {targetUser.PID}
                                </Text>
                            </Value>
                            <Value>
                                <Label>Dealer/WS Segment</Label>
                                {targetUser.dealerSegments.map(
                                    (segmentId, index) => (
                                        <Text
                                            key={`scanningContestDealerSegments-${index}`}
                                        >
                                            {sectorMapping.get(segmentId)
                                                ?.name ?? segmentId}
                                        </Text>
                                    )
                                )}
                            </Value>
                            <Value>
                                <Label>
                                    Dealer/WS Sub Segment (
                                    {targetUser.dealerSubSegments.length})
                                </Label>
                                {targetUser.dealerSubSegments.map(
                                    (segmentId, index) => (
                                        <Text
                                            key={`scanningContestDealerSubSegments-${index}`}
                                        >
                                            {subSectorsMapping.get(segmentId)
                                                ?.name ?? segmentId}
                                        </Text>
                                    )
                                )}
                            </Value>
                            <Value>
                                <Label>Mechanics Segment</Label>
                                {targetUser.mechanicsSegments.map(
                                    (segmentId, index) => (
                                        <Text
                                            key={`scanningContestSegments-${index}`}
                                        >
                                            {sectorMapping.get(segmentId)
                                                ?.name ?? segmentId}
                                        </Text>
                                    )
                                )}
                            </Value>
                            <Value>
                                <Label>
                                    Mechanics Sub Segment (
                                    {targetUser.mechanicsSubSegments.length})
                                </Label>
                                {targetUser.mechanicsSubSegments.map(
                                    (segmentId, index) => (
                                        <Text
                                            key={`scanningContestSubSegments-${index}`}
                                        >
                                            {subSectorsMapping.get(segmentId)
                                                ?.name ?? segmentId}
                                        </Text>
                                    )
                                )}
                            </Value>
                        </FlexGrid>
                    }
                />
                <Accordion
                    defaultOpen={false}
                    info={{
                        title: 'Show or Hide Details',
                        content:
                            'Click to show or hide in each section. And you can edit each section by clicking edit. Submit if all data is correct.',
                    }}
                    title={
                        <FlexGrid
                            flex="1"
                            justifyContent="space-between"
                            alignItems="center"
                            m="0 0.5em 0 0"
                        >
                            <Text font="OS Bold" uppercase>
                                Send Notification
                            </Text>
                            {mode !== 'View' && (
                                <EditBtn
                                    m="0"
                                    p="0"
                                    negative
                                    onClick={() => {
                                        stepNavigator(5);
                                    }}
                                >
                                    Edit
                                </EditBtn>
                            )}
                        </FlexGrid>
                    }
                    content={identifierDefinition.appLanguages.map(
                        (language) => (
                            <>
                                <Text
                                    key={`scanningContest-text-${language}`}
                                    m="0.5em 0 0 1.6em"
                                >
                                    {language}
                                </Text>
                                <FlexGrid
                                    key={`scanningContest-flexgrid-${language}`}
                                    width="100%"
                                    wrap="wrap"
                                    direction="column"
                                >
                                    {isInAppNotification && (
                                        <>
                                            <Value>
                                                <Label>
                                                    Send notification to
                                                </Label>
                                                <Text>In App Notification</Text>
                                            </Value>
                                            <Value>
                                                <Label>Icon</Label>
                                                <FlexGrid
                                                    alignItems="center"
                                                    gap="0.5em"
                                                >
                                                    {
                                                        notificationIcons[
                                                            sendNotification
                                                                .icon
                                                        ]
                                                    }
                                                    {sendNotification.icon}
                                                </FlexGrid>
                                            </Value>

                                            <Value>
                                                <Label>Body Text</Label>
                                                <Text>
                                                    <div
                                                        style={{
                                                            margin: 0,
                                                            padding: 0,
                                                        }}
                                                        dangerouslySetInnerHTML={{
                                                            __html: sendNotification
                                                                .inAppNotificationContent
                                                                .bodyText[
                                                                language ||
                                                                    'English'
                                                            ],
                                                        }}
                                                    />
                                                </Text>
                                            </Value>
                                        </>
                                    )}
                                    {isPushNotification && (
                                        <>
                                            <Value>
                                                <Label>
                                                    Send notification to
                                                </Label>
                                                <Text>Push Notification</Text>
                                            </Value>

                                            <Value>
                                                <Label>Preview Text</Label>
                                                <Text>
                                                    {
                                                        sendNotification
                                                            .pushNotificationContent
                                                            .headline[
                                                            language ||
                                                                'English'
                                                        ]
                                                    }
                                                </Text>
                                            </Value>

                                            <Value>
                                                <Label>Notification Text</Label>
                                                <Text>
                                                    <div
                                                        style={{
                                                            margin: 0,
                                                            padding: 0,
                                                        }}
                                                        dangerouslySetInnerHTML={{
                                                            __html: sendNotification
                                                                .inAppNotificationContent
                                                                .bodyText[
                                                                language ||
                                                                    'English'
                                                            ],
                                                        }}
                                                    />
                                                </Text>
                                            </Value>
                                        </>
                                    )}
                                </FlexGrid>
                            </>
                        )
                    )}
                />

                <Action>
                    <Button
                        negative
                        icon="back"
                        title="Back"
                        onClick={() => {
                            if (mode === 'View') {
                                navigate(-1);
                            } else {
                                stepNavigator((prev: number) => prev - 1);
                            }
                        }}
                    />
                    {mode !== 'View' && (
                        <Button
                            onClick={() => createScanningContest()}
                            p="1em 2em"
                            title="Submit"
                            disabled={disableSubmit}
                        />
                    )}
                </Action>
            </Form>
        </FlexGrid>
    );
};
