import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
    BonusPointsIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    CloseGreenIcon,
    MinusIcon,
    PlusIcon,
    PointsIcon,
} from '../../../assets/icons';
import {
    LIMIT_DESCRIPTION_LENGTH,
    LIMIT_HEADLINE_LENGTH,
    NUM_OPTIONS_QUIZ,
} from '../../../common/constants';
import { Button } from '../../../components/Button';
import { ButtonGroup } from '../../../components/ButtonGroup';
import { FlexGrid } from '../../../components/FlexGrid';
import { IconButton } from '../../../components/IconButton';
import { Input } from '../../../components/Input';
import { PageHeader } from '../../../components/PageHeader';
import { PointsPickerInput } from '../../../components/PointsPickerInput';
import { QuizOptionsInput } from '../../../components/QuizOptionsInput';
import { RadioGroup } from '../../../components/RadioGroup';
import { SectionHeader } from '../../../components/SectionHeader';
import { Text } from '../../../components/Text';
import { updateModalAction } from '../../../store/store.actions';
import { COLORS } from '../../../styles/variables';
import { VideoType } from '../../../types';
import {
    composeDate,
    ContentStatus,
    genNewQuestion,
    getContentStatus,
    isRequireOrWhiteSpaces,
    isStringRequiredMissing,
} from '../../../utils';

const Form = styled(FlexGrid)`
    padding: 1em 2em;
    min-width: 500px;
    flex-direction: column;
    gap: 0.5em;
    margin-top: 0.5em;
`;

const Action = styled(FlexGrid)`
    border-top: 1px solid ${COLORS.lightGray};
    width: 100%;
    justify-content: space-between;
    padding: 1em 0em 1em 0em;
    margin-top: 2em;
    align-items: center;
`;

interface Props {
    stepNavigator?: any;
    videoState: [VideoType, React.Dispatch<React.SetStateAction<VideoType>>];
    initVideoData: VideoType;
}

export const AddQuiz: React.FC<Props> = ({
    stepNavigator,
    videoState,
    initVideoData,
}) => {
    const dispatch = useDispatch();

    const [video, setVideo] = videoState;
    const { quiz } = video;

    const [hasQuiz, setHasQuiz] = useState(!!quiz && !quiz.markDeleted);

    const { appLanguages } = video.identifierDefinition;
    const [activeLanguage, setActiveLanguage] = useState(appLanguages[0]);

    const [questionsCount, setQuestionsCount] = useState(
        quiz?.content.questions.length || 0
    );
    const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);

    const dataIncomplete =
        hasQuiz &&
        !!quiz &&
        (isRequireOrWhiteSpaces(quiz?.content.title) ||
            isRequireOrWhiteSpaces(quiz?.content.description) ||
            quiz.content.questions.length <= 0);

    useEffect(() => {
        setHasQuiz(!!quiz && !quiz.markDeleted);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    console.log('==hasQuiz==', hasQuiz);

    const quizStatus = getContentStatus(
        quiz,
        composeDate(
            initVideoData?.identifierDefinition.startDate,
            initVideoData?.identifierDefinition.startTime
        )?.toDate(),
        composeDate(
            initVideoData?.identifierDefinition.endDate,
            initVideoData?.identifierDefinition.endDate
        )?.toDate()
    );
    const isQuizPublished =
        quizStatus == ContentStatus.LIVE || quizStatus == ContentStatus.EXPIRED;

    useEffect(() => {
        const newVideo = { ...video };
        if (!hasQuiz) {
            if (newVideo.quiz) {
                newVideo.quiz.markDeleted = true;
                setVideo(newVideo);
            }
            return;
        }
        if (!newVideo.quiz) {
            newVideo.quiz = {
                content: {
                    title: {},
                    description: {},
                    questions: [
                        {
                            content: {
                                questionText: {},
                                options: [],
                                reason: {},
                                points: 0,
                                correctAnswerIndex: undefined,
                            },
                        },
                    ],
                    bonusPoints: 0,
                },
            };
        } else {
            newVideo.quiz.markDeleted = false;
        }
        const newQuiz = newVideo.quiz;
        const numQuestions = newQuiz.content.questions.length;
        if (numQuestions <= 0) {
            newQuiz.content.questions = [genNewQuestion(appLanguages)];
        }
        appLanguages.forEach((language) => {
            if (!newQuiz.content.title[language]) {
                newQuiz.content.title[language] = '';
            }
            if (!newQuiz.content.description[language]) {
                newQuiz.content.description[language] = '';
            }
            for (let i = 0; i < numQuestions; ++i) {
                const question = newQuiz.content.questions[i];
                if (!question.content.questionText[language]) {
                    question.content.questionText[language] = '';
                }
                for (let j = 0; j < NUM_OPTIONS_QUIZ; ++j) {
                    if (!question.content.options[j]) {
                        question.content.options[j] = {};
                    }
                    if (!question.content.options[j][language]) {
                        question.content.options[j][language] = '';
                    }
                }
                if (!question.content.reason[language]) {
                    question.content.reason[language] = '';
                }
            }
        });
        setVideo(newVideo);
        setQuestionsCount(newQuiz.content.questions.length);
    }, [hasQuiz]);

    const checkedLanguage = appLanguages.map(
        (language) =>
            // Boolean(
            //     sendNotification.headline[language] &&
            //         sendNotification.bodyText[language]
            // )
            language === activeLanguage
    );

    console.log('quiz', quiz);

    return (
        <FlexGrid width="100%" direction="column">
            <PageHeader
                title={
                    <Text font="OS" fontSize="1.375rem" uppercase>
                        Add Quiz
                    </Text>
                }
            />
            <Form>
                <RadioGroup
                    label="Do you want to have a Quiz?"
                    info={{
                        title: 'Add Quiz',
                        content:
                            'Short descriptions goes here, lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam.',
                    }}
                    options={[
                        { label: 'Yes', value: 'Yes' },
                        { label: 'No', value: 'No' },
                    ]}
                    value={hasQuiz ? 'Yes' : 'No'}
                    onChange={(val) => {
                        setHasQuiz(val === 'Yes');
                    }}
                />
                {hasQuiz && quiz && (
                    <>
                        <ButtonGroup
                            titles={appLanguages}
                            info={{
                                title: 'Multiple Languages',
                                content:
                                    'Please fill in all the different language sections according your choices, short desc goes here, lorem ipsum dolor sit amet, consetetur .',
                            }}
                            active={activeLanguage}
                            onChange={setActiveLanguage}
                            checked={checkedLanguage}
                        />
                        <Input
                            label="Title"
                            maxLength={LIMIT_HEADLINE_LENGTH}
                            value={quiz.content.title[activeLanguage]}
                            onChange={(val) =>
                                setVideo((state) => {
                                    const newState = { ...state };
                                    newState.quiz.content.title[
                                        activeLanguage
                                    ] = val;
                                    return newState;
                                })
                            }
                        />
                        <Input
                            label="Description"
                            maxLength={LIMIT_DESCRIPTION_LENGTH}
                            value={quiz.content.description[activeLanguage]}
                            onChange={(val) =>
                                setVideo((state) => {
                                    const newState = { ...state };
                                    newState.quiz.content.description[
                                        activeLanguage
                                    ] = val;
                                    return newState;
                                })
                            }
                        />
                        <PointsPickerInput
                            label="Bonus points"
                            icon={<BonusPointsIcon />}
                            info={{
                                title: 'Bonus points',
                                content:
                                    'You can add bonus points goes here, lorem ipsum dolor sit amet, consetetur sadi elitr, sed diam nonumy eirmod tempor labore et dolore magna aliquyam.',
                            }}
                            value={quiz.content.bonusPoints}
                            onChange={(val) =>
                                setVideo((state) => {
                                    const newState = { ...state };
                                    newState.quiz.content.bonusPoints = val;
                                    return newState;
                                })
                            }
                            disabled={isQuizPublished}
                        />
                        <SectionHeader
                            title={`QUESTION #${activeQuestionIndex + 1}`}
                            bg="#F5F5F5"
                            fontFamily="OS"
                            height="40px"
                            titleMargin="0 0 0 1em"
                            right={
                                <FlexGrid alignItems="center">
                                    <FlexGrid alignItems="center">
                                        {questionsCount > 1 && (
                                            <IconButton
                                                negative
                                                p="0"
                                                m="0em 1em"
                                                icon={
                                                    <MinusIcon
                                                        color={
                                                            isQuizPublished
                                                                ? COLORS.gray
                                                                : undefined
                                                        }
                                                    />
                                                }
                                                disabled={isQuizPublished}
                                                onClick={() => {
                                                    setVideo((state) => {
                                                        const newState = {
                                                            ...state,
                                                        };
                                                        newState.quiz.content.questions.splice(
                                                            activeQuestionIndex,
                                                            1
                                                        );
                                                        return newState;
                                                    });
                                                    const prevQuestionCount =
                                                        questionsCount;
                                                    setQuestionsCount(
                                                        prevQuestionCount - 1
                                                    );
                                                    if (
                                                        activeQuestionIndex >=
                                                        prevQuestionCount - 1
                                                    ) {
                                                        setActiveQuestionIndex(
                                                            prevQuestionCount -
                                                                2
                                                        );
                                                    }
                                                }}
                                            />
                                        )}
                                        {activeQuestionIndex ===
                                            questionsCount - 1 && (
                                            <IconButton
                                                negative
                                                p="0"
                                                m="0em 1em"
                                                icon={
                                                    <PlusIcon
                                                        color={
                                                            isQuizPublished
                                                                ? COLORS.gray
                                                                : undefined
                                                        }
                                                    />
                                                }
                                                disabled={isQuizPublished}
                                                onClick={() => {
                                                    setVideo((state) => {
                                                        const newState = {
                                                            ...state,
                                                        };
                                                        newState.quiz.content.questions.push(
                                                            genNewQuestion(
                                                                appLanguages
                                                            )
                                                        );
                                                        return newState;
                                                    });
                                                    const prevQuestionCount =
                                                        questionsCount;
                                                    setQuestionsCount(
                                                        questionsCount + 1
                                                    );
                                                    setActiveQuestionIndex(
                                                        prevQuestionCount
                                                    );
                                                }}
                                            />
                                        )}
                                        <Button
                                            p="0"
                                            m="0em 1em"
                                            negative
                                            onClick={() => {
                                                setActiveQuestionIndex(
                                                    Math.max(
                                                        0,
                                                        activeQuestionIndex - 1
                                                    )
                                                );
                                            }}
                                            disabled={activeQuestionIndex < 1}
                                        >
                                            <ChevronRightIcon
                                                color={
                                                    activeQuestionIndex < 1
                                                        ? COLORS.gray
                                                        : undefined
                                                }
                                            />
                                        </Button>
                                        <Button
                                            p="0"
                                            m="0em 1em"
                                            negative
                                            onClick={() => {
                                                setActiveQuestionIndex(
                                                    Math.min(
                                                        questionsCount - 1,
                                                        activeQuestionIndex + 1
                                                    )
                                                );
                                            }}
                                            disabled={
                                                activeQuestionIndex + 1 >=
                                                questionsCount
                                            }
                                        >
                                            <ChevronLeftIcon
                                                color={
                                                    activeQuestionIndex + 1 >=
                                                    questionsCount
                                                        ? COLORS.gray
                                                        : undefined
                                                }
                                            />
                                        </Button>
                                    </FlexGrid>
                                </FlexGrid>
                            }
                        />
                        <FlexGrid
                            direction="column"
                            flex="1"
                            width="100%"
                            p="1em 2em 1em 0em"
                        >
                            <PointsPickerInput
                                label="Points per question"
                                icon={<PointsIcon />}
                                info={{
                                    title: 'Points per points',
                                    content:
                                        'You can add points per question goes here, lorem ipsum dolor sit amet, consetetur sadi elitr, sed diam nonumy eirmod tempor labore et dolore magna aliquyam.',
                                }}
                                value={
                                    quiz.content.questions[activeQuestionIndex]
                                        .content.points
                                }
                                disabled={isQuizPublished}
                                onChange={(val) =>
                                    setVideo((state) => {
                                        const newState = { ...state };
                                        const question =
                                            newState.quiz.content.questions[
                                                activeQuestionIndex
                                            ];
                                        question.content.points = val;
                                        return newState;
                                    })
                                }
                            />
                            <Input
                                placeholder="Enter Your Question"
                                maxLength={LIMIT_DESCRIPTION_LENGTH}
                                value={
                                    quiz.content.questions[activeQuestionIndex]
                                        .content.questionText[activeLanguage]
                                }
                                onChange={(val) =>
                                    setVideo((state) => {
                                        const newState = { ...state };
                                        const question =
                                            newState.quiz.content.questions[
                                                activeQuestionIndex
                                            ];
                                        question.content.questionText[
                                            activeLanguage
                                        ] = val;
                                        return newState;
                                    })
                                }
                            />
                            <QuizOptionsInput
                                reason={
                                    quiz.content.questions[activeQuestionIndex]
                                        .content.reason[activeLanguage]
                                }
                                correctAnswerIndex={
                                    quiz.content.questions[activeQuestionIndex]
                                        .content.correctAnswerIndex
                                }
                                onSelected={(index) => {
                                    setVideo((state) => {
                                        const newState = { ...state };
                                        const question =
                                            newState.quiz.content.questions[
                                                activeQuestionIndex
                                            ];
                                        question.content.correctAnswerIndex =
                                            index;
                                        return newState;
                                    });
                                }}
                                onReasonChanged={(val) =>
                                    setVideo((state) => {
                                        const newState = { ...state };
                                        const question =
                                            newState.quiz.content.questions[
                                                activeQuestionIndex
                                            ];
                                        question.content.reason[
                                            activeLanguage
                                        ] = val;
                                        return newState;
                                    })
                                }
                                onOrderChanged={(
                                    optionIndexes,
                                    selectedIndex
                                ) =>
                                    setVideo((state) => {
                                        const newState = { ...state };
                                        const question =
                                            newState.quiz.content.questions[
                                                activeQuestionIndex
                                            ];
                                        const newOptions = new Array(
                                            question.content.options.length
                                        );
                                        for (
                                            let i = 0;
                                            i < optionIndexes.length;
                                            ++i
                                        ) {
                                            const newOptionIndex =
                                                optionIndexes[i];
                                            newOptions[i] = {
                                                ...question.content.options[
                                                    newOptionIndex
                                                ],
                                            };
                                        }
                                        question.content.options = newOptions;
                                        question.content.correctAnswerIndex =
                                            selectedIndex;
                                        return newState;
                                    })
                                }
                                onChange={(val, optionIndex) =>
                                    setVideo((state) => {
                                        const newState = { ...state };
                                        const question =
                                            newState.quiz.content.questions[
                                                activeQuestionIndex
                                            ];
                                        question.content.options[optionIndex][
                                            activeLanguage
                                        ] = val;
                                        return newState;
                                    })
                                }
                                optionItems={
                                    quiz.content.questions[activeQuestionIndex]
                                        .content.options &&
                                    quiz.content.questions[activeQuestionIndex]
                                        .content.options.length ===
                                        NUM_OPTIONS_QUIZ
                                        ? [
                                              {
                                                  optionValue:
                                                      quiz.content.questions[
                                                          activeQuestionIndex
                                                      ].content.options[0][
                                                          activeLanguage
                                                      ],
                                                  placeHolder: 'Enter Answer',
                                                  optionIndex: 0,
                                              },
                                              {
                                                  optionValue:
                                                      quiz.content.questions[
                                                          activeQuestionIndex
                                                      ].content.options[1][
                                                          activeLanguage
                                                      ],
                                                  placeHolder: 'Enter Answer',
                                                  optionIndex: 1,
                                              },
                                              {
                                                  optionValue:
                                                      quiz.content.questions[
                                                          activeQuestionIndex
                                                      ].content.options[2][
                                                          activeLanguage
                                                      ],
                                                  placeHolder: 'Enter Answer',
                                                  optionIndex: 2,
                                              },
                                              {
                                                  optionValue:
                                                      quiz.content.questions[
                                                          activeQuestionIndex
                                                      ].content.options[3][
                                                          activeLanguage
                                                      ],
                                                  placeHolder: 'Enter Answer',
                                                  optionIndex: 3,
                                              },
                                          ]
                                        : []
                                }
                                answerAdjustmentDisabled={isQuizPublished}
                            />
                        </FlexGrid>
                    </>
                )}
                <Action>
                    <Button
                        negative
                        icon="back"
                        title="Back"
                        onClick={() =>
                            stepNavigator((prev: number) => prev - 1)
                        }
                    />
                    <Button
                        p="1em 2em"
                        icon="next"
                        title="Next"
                        disabled={dataIncomplete}
                        onClick={() => {
                            // Check multi-language fields are filled
                            if (quiz) {
                                if (hasQuiz) {
                                    for (const language of appLanguages) {
                                        const questions =
                                            quiz.content.questions;
                                        for (
                                            let i = 0;
                                            i < questions.length;
                                            ++i
                                        ) {
                                            const question = questions[i];
                                            if (
                                                isStringRequiredMissing(
                                                    question.content
                                                        .questionText[language]
                                                )
                                            ) {
                                                dispatch(
                                                    updateModalAction({
                                                        open: true,
                                                        title: 'error',
                                                        content: `The title of question #${
                                                            i + 1
                                                        } in ${language} is missing`,
                                                    })
                                                );
                                                return;
                                            }

                                            if (
                                                isStringRequiredMissing(
                                                    question.content.reason[
                                                        language
                                                    ]
                                                )
                                            ) {
                                                dispatch(
                                                    updateModalAction({
                                                        open: true,
                                                        title: 'error',
                                                        content: `The correct answer reason of question #${
                                                            i + 1
                                                        } in ${language} is missing`,
                                                    })
                                                );
                                                return;
                                            }

                                            for (
                                                let j = 0;
                                                j <
                                                question.content.options.length;
                                                ++j
                                            ) {
                                                const option =
                                                    question.content.options[j];
                                                if (
                                                    isStringRequiredMissing(
                                                        option[language]
                                                    )
                                                ) {
                                                    dispatch(
                                                        updateModalAction({
                                                            open: true,
                                                            title: 'error',
                                                            content: `The option ${
                                                                j + 1
                                                            } text of question #${
                                                                i + 1
                                                            } in ${language} is missing`,
                                                        })
                                                    );
                                                    return;
                                                }
                                            }

                                            if (question.content.points <= 0) {
                                                dispatch(
                                                    updateModalAction({
                                                        open: true,
                                                        title: 'error',
                                                        content: `Please assign points to question #${
                                                            i + 1
                                                        }.`,
                                                    })
                                                );
                                                return;
                                            }

                                            if (
                                                question.content
                                                    .correctAnswerIndex ===
                                                undefined
                                            ) {
                                                dispatch(
                                                    updateModalAction({
                                                        open: true,
                                                        title: 'error',
                                                        content: `Please select a correct option for question #${
                                                            i + 1
                                                        }.`,
                                                    })
                                                );
                                                return;
                                            }
                                        }
                                    }
                                    const newVideo = { ...video };
                                    newVideo.quiz.markDeleted = false;
                                    setVideo(newVideo);
                                } else {
                                    // Mark the linked quiz should be deleted
                                    const newVideo = { ...video };
                                    newVideo.quiz.markDeleted = true;
                                    setVideo(newVideo);
                                }
                            }

                            stepNavigator((prev: number) => prev + 1);
                        }}
                    />
                </Action>
            </Form>
        </FlexGrid>
    );
};
