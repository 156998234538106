import React, { useEffect, useState } from 'react';
import { Input } from './Input';
import {
    LIMIT_DEFAULT_TEXT_LENGTH,
    LIMIT_DESCRIPTION_LENGTH,
    NUM_OPTIONS_QUIZ,
} from '../common/constants';
import { DragIndicatorIcon, QuizIncorrectOptionIcon } from '../assets/icons';
import QuizCorrectOption from '../assets/icons/quiz-correct-option';

interface OptionItemProps {
    optionValue: string;
    placeHolder: string;
    optionIndex: number;
}

interface InputProps {
    optionItems: OptionItemProps[];
    correctAnswerIndex?: number;
    onSelected?: (index: number) => void;
    reason?: string;
    onReasonChanged?: (val: string) => void;
    onChange: (val: string, optionIndex: number) => void;
    onOrderChanged: (optionIndexes: number[], index: number) => void;
    textEditingDisabled?: boolean;
    answerAdjustmentDisabled?: boolean;
}

const initialDnDState = {
    draggedFrom: null,
    draggedTo: null,
    isDragging: false,
    originalOrder: [],
    updatedOrder: [],
    originalSelectedOrder: [],
    updatedSelectedOrder: [],
};

export const QuizOptionsInput: React.FC<InputProps> = ({
    optionItems,
    correctAnswerIndex,
    onSelected,
    reason,
    onReasonChanged,
    onChange,
    onOrderChanged,
    textEditingDisabled,
    answerAdjustmentDisabled,
}) => {
    const [items, setItems] = useState([...optionItems]);
    // const [itemIndexes, setItemIndexes] = useState(Array.from(Array(optionItems.length).keys()));
    const [itemsSelected, setItemsSelected] = useState(
        Array(NUM_OPTIONS_QUIZ).fill(false)
    );
    // console.log('===items==', items, optionItems, itemIndexes);
    const [dragAndDrop, setDragAndDrop] = useState(initialDnDState);
    const [selectedIndex, setSelectedIndex] = useState(correctAnswerIndex);

    useEffect(() => {
        setItems(optionItems);
        // console.log('===optionItems==', optionItems);
        // console.log('===items==', items);
    }, [optionItems]);

    useEffect(() => {
        setSelectedIndex(correctAnswerIndex);
        const selectedArray = Array(NUM_OPTIONS_QUIZ).fill(false);
        if (correctAnswerIndex !== undefined) {
            selectedArray[correctAnswerIndex] = true;
        }
        setItemsSelected(selectedArray);
    }, [correctAnswerIndex]);

    // onDragStart fires when an element
    // starts being dragged
    const onDragStart = (event) => {
        if (answerAdjustmentDisabled) {
            return;
        }
        const initialIndex = Number(event.currentTarget.dataset.index);

        setDragAndDrop({
            ...dragAndDrop,
            draggedFrom: initialIndex,
            isDragging: true,
            originalOrder: items,
            originalSelectedOrder: itemsSelected,
        });
        // Note: this is only for Firefox.
        // Without it, the DnD won't work.
        // But we are not using it.
        event.dataTransfer.setData('text/html', '');
    };

    // onDragOver fires when an element being dragged
    // enters a droppable area.
    // In this case, any of the items on the list
    const onDragOver = (event) => {
        if (answerAdjustmentDisabled) {
            return;
        }
        // in order for the onDrop
        // event to fire, we have
        // to cancel out this one
        event.preventDefault();

        let newList = dragAndDrop.originalOrder;
        let newSelectedlist = dragAndDrop.originalSelectedOrder;

        // index of the item being dragged
        const draggedFrom = dragAndDrop.draggedFrom;

        // index of the droppable area being hovered
        const draggedTo = Number(event.currentTarget.dataset.index);

        const itemDragged = newList[draggedFrom];
        const remainingItems = newList.filter(
            (item, index) => index !== draggedFrom
        );

        const itemSelected = newSelectedlist[draggedFrom];
        const remainingSelectedList = newSelectedlist.filter(
            (item, index) => index !== draggedFrom
        );

        newList = [
            ...remainingItems.slice(0, draggedTo),
            itemDragged,
            ...remainingItems.slice(draggedTo),
        ];
        // for (let i = 0; i < newList.length; ++i) {
        //     newList[i].optionIndex = i;
        // }

        newSelectedlist = [
            ...remainingSelectedList.slice(0, draggedTo),
            itemSelected,
            ...remainingSelectedList.slice(draggedTo),
        ];

        if (draggedTo !== dragAndDrop.draggedTo) {
            setDragAndDrop({
                ...dragAndDrop,
                updatedOrder: newList,
                updatedSelectedOrder: newSelectedlist,
                draggedTo,
            });
            setItems(newList);
            // setItemsSelected(newSelectedlist);
            // const indexSelected = newSelectedlist.indexOf(true);
            // console.log('==indexSelected=1=', indexSelected);
            // if (indexSelected >= 0) {
            //     setSelectedIndex(indexSelected);
            // }
        }
    };

    // Update the rendered list
    // and reset the DnD state
    const onDrop = (event) => {
        if (answerAdjustmentDisabled) {
            return;
        }
        setItems(dragAndDrop.updatedOrder);
        setItemsSelected(dragAndDrop.updatedSelectedOrder);
        const indexSelected = dragAndDrop.updatedSelectedOrder.indexOf(true);
        if (indexSelected >= 0) {
            setSelectedIndex(indexSelected);
            // onSelected && onSelected(indexSelected);
            console.log('==indexSelected=2=', indexSelected);
        }
        onOrderChanged(
            dragAndDrop.updatedOrder.map((item) => item.optionIndex), indexSelected
        );

        setDragAndDrop({
            ...dragAndDrop,
            draggedFrom: null,
            draggedTo: null,
            isDragging: false,
        });
    };

    const onDragLeave = () => {
        if (answerAdjustmentDisabled) {
            return;
        }
        setDragAndDrop({
            ...dragAndDrop,
            draggedTo: null,
        });
    };

    return (
        <>
            {items.map((optionItem: OptionItemProps, index: number) => (
                <div
                    key={`quiz-option-${index}`}
                    data-index={index}
                    style={{ width: '100%' }}
                    draggable="true"
                    onDragStart={onDragStart}
                    onDragOver={onDragOver}
                    onDrop={onDrop}
                    onDragLeave={onDragLeave}
                >
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <DragIndicatorIcon />
                        <div
                            draggable={textEditingDisabled ? "false" : "true" }
                            onDragStart={(event) => {
                                // Prevent dragging the child elements
                                event.preventDefault();
                                event.stopPropagation();
                            }}
                            style={{ width: '100%' }}
                        >
                            <Input
                                placeholder={optionItem.placeHolder}
                                maxLength={LIMIT_DESCRIPTION_LENGTH}
                                value={optionItem.optionValue}
                                icon={
                                    selectedIndex === index ? (
                                        <QuizCorrectOption />
                                    ) : (
                                        <QuizIncorrectOptionIcon />
                                    )
                                }
                                iconPosition="end"
                                onIconClicked={() => {
                                    if (answerAdjustmentDisabled) {
                                        return;
                                    }
                                    setItemsSelected((state) => {
                                        const newState = state.map(
                                            (item, itemIndex) =>
                                                index === itemIndex
                                        );
                                        return newState;
                                    });
                                    setSelectedIndex(index);
                                    onSelected && onSelected(index);
                                }}
                                onChange={(val) =>
                                    onChange(val, optionItem.optionIndex)
                                }
                                disabled={textEditingDisabled}
                            />
                        </div>
                    </div>
                </div>
            ))}
            <Input
                m="0.5em 0 0 0"
                label="Reason"
                multiline
                value={reason}
                onChange={onReasonChanged}
                disabled={textEditingDisabled}
                maxLength={LIMIT_DEFAULT_TEXT_LENGTH}
            />
        </>
    );
};
