import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import 'moment-timezone';
import { FlexGrid } from '../../../components/FlexGrid';
import {
    Table,
    TableCell,
    TableHead,
    // TablePagination,
    TableRow,
} from '../../../components/Table';
import { Text } from '../../../components/Text';
import { COLORS } from '../../../styles/variables';
import { ArrowDownIcon, BonusPointsIcon } from '../../../assets/icons';
import { Button } from '../../../components/Button';
import { Modal } from '../../../components/Modal';
import { IconButton } from '../../../components/IconButton';
import { useScanningContestHook } from '../hooks/use-scanning-contest-hook';
import { Pagination } from '../../../components/Pagination';
import {
    ScanningContestItemToEditModel,
    getDefaultTimezone,
    getUrlKeepQuery,
    roundDecimals,
} from '../../../utils';
import { sortFunc } from '../../../common';
import { ButtonGroup } from '../../../components/ButtonGroup';
import { ApiService } from '../../../services/Api.service';
import { CreationService } from '../../../services/Creation.service';
import { useDispatch } from 'react-redux';

const TableActionBtn = styled(Button)`
    text-transform: none;
    font-family: 'AR';
    border-radius: 0;
    margin: 0;
    border-left: 1px solid ${COLORS.lightGray};

    &:first-of-type {
        border-left: none;
    }
`;

const LinkText = styled(Text)`
    max-width: 280px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const ScanningContestsTable: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [current, setCurrent] = useState(null);
    const [update, setUpdate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [activeLanguage, setActiveLanguage] = useState('');
    const perPage = 5;
    const DEFAULT_SORT = {
        name: 0,
        startDate: 0,
        totalPoints: 0,
    };
    const [sort, setSort] = useState<any>(DEFAULT_SORT);

    const location = useLocation() as any;
    const [page, setPage] = useState(location.state?.page || 1);

    const [{ isLoading, hookData: scanningContestsObj }] =
        useScanningContestHook(
            {
                // page,
                // perPage,
                // sortBy: `${sort.date ? '' : '-'}fields.startDate`,
            },
            [update]
        );

    const [deleteModal, setDeleteModal] = useState(false);
    const [detailsModal, setDetailsModal] = useState(false);

    let scanningContests = sortFunc(sort, scanningContestsObj.mapped ?? []);
    const total = scanningContestsObj.raw?.total || 0;
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    scanningContests = scanningContests.slice(startIndex, endIndex);

    const itemToEditModel = async (scanningContest) => {
        return await ScanningContestItemToEditModel(scanningContest);
    };

    const [scanningContestsDashboardStats, setScanningContestsDashboardStats] =
        useState({
            pointsDisbursed: 0,
            scanningContestsActive: 0,
            scanningContestsExpired: 0,
            totalParticipants: 0,
            totalContestLaunched: 0,
        });

    useEffect(() => {
        ApiService.getScanningContestsDashboardStats()
            .then((response) => {
                // {
                //     pointsDisbursed: 311;
                //     scanningContestsActive: 3;
                //     scanningContestsExpired: 1;
                //     totalParticipants: 1;
                //     totalContestLaunched: 4;
                // }
                const data = response.data;
                setScanningContestsDashboardStats(data);
                console.log('==getScanningContestsDashboardStats==', data);
            })
            .catch((err) =>
                console.error(
                    '===Failed to fetch scanning contests dashboard stats===',
                    err
                )
            );
    }, []);

    return (
        <>
            {!isLoading && (
                <>
                    <Modal
                        open={deleteModal}
                        header="Delete Scanning Contest"
                        onClose={() => setDeleteModal(false)}
                        content={
                            <FlexGrid
                                direction="column"
                                width="100%"
                                gap="2em"
                                p="2em 0em"
                            >
                                <Text color={COLORS.darkGray}>
                                    Are you sure you want to delete this
                                    scanningContest?
                                </Text>
                                <Text color={COLORS.darkGray}>
                                    <Text
                                        color={COLORS.darkGray}
                                        span
                                        font="AR Bold"
                                    >
                                        Note:{' '}
                                    </Text>
                                    Deleting a scanning contest is a permanent
                                    action and cannot be undone.
                                </Text>
                            </FlexGrid>
                        }
                        actions={
                            <FlexGrid alignItems="center">
                                <Button
                                    onClick={() => {
                                        CreationService.deleteScanningContest(dispatch, current)
                                            .then(() => {
                                                setUpdate(!update);
                                                if (total % perPage === 1) {
                                                    setPage(
                                                        Math.max(page - 1, 1)
                                                    );
                                                }
                                            })
                                            .catch(() => {
                                                alert(
                                                    'Cannot delete published'
                                                );
                                            });
                                        setDeleteModal(false);
                                    }}
                                    p="1.3em 2.5em"
                                >
                                    DELETE
                                </Button>
                                <Button
                                    onClick={() => setDeleteModal(false)}
                                    negative
                                    p="0.5em 1em"
                                >
                                    CANCEL
                                </Button>
                            </FlexGrid>
                        }
                    />
                    {current && (
                        <Modal
                            open={detailsModal}
                            header={`${current.identifierDefinition.name} details`}
                            onClose={() => setDetailsModal(false)}
                            content={
                                <FlexGrid direction="column">
                                    <Table>
                                        <TableRow>
                                            <TableHead
                                                padding="2em 0em 0em 0em"
                                                borderBottomDisabled
                                            >
                                                <FlexGrid
                                                    alignItems="center"
                                                    gap="0.8em"
                                                >
                                                    No. of Participants
                                                </FlexGrid>
                                            </TableHead>
                                            <TableHead
                                                padding="2em 0em 0em 0em"
                                                borderBottomDisabled
                                            >
                                                <FlexGrid
                                                    alignItems="center"
                                                    gap="0.8em"
                                                >
                                                    Total Points Disbursed
                                                </FlexGrid>
                                            </TableHead>
                                            <TableHead
                                                padding="2em 0em 0em 0em"
                                                borderBottomDisabled
                                            >
                                                <FlexGrid
                                                    alignItems="center"
                                                    gap="0.8em"
                                                >
                                                    Avg Points Disbursed
                                                </FlexGrid>
                                            </TableHead>
                                            <TableHead
                                                padding="2em 0em 0em 0em"
                                                borderBottomDisabled
                                            >
                                                <FlexGrid
                                                    alignItems="center"
                                                    gap="0.8em"
                                                >
                                                    Total Coupons Scanned
                                                </FlexGrid>
                                            </TableHead>
                                            <TableHead
                                                padding="2em 0em 0em 0em"
                                                borderBottomDisabled
                                            >
                                                <FlexGrid
                                                    alignItems="center"
                                                    gap="0.8em"
                                                >
                                                    Avg Coupons Scanned
                                                </FlexGrid>
                                            </TableHead>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                padding="0em 0em 0em 0em"
                                                borderBottomDisabled
                                                verticalAlign="top"
                                                width="250px"
                                            >
                                                <Text
                                                    font="OS"
                                                    fontSize="1.5rem"
                                                    color={COLORS.black}
                                                >
                                                    {current.statsData
                                                        ?.noOfParticipants ?? 0}
                                                </Text>
                                            </TableCell>
                                            <TableCell
                                                padding="0em 0em 0em 0em"
                                                borderBottomDisabled
                                                verticalAlign="top"
                                                width="250px"
                                            >
                                                <Text
                                                    font="OS"
                                                    fontSize="1.5rem"
                                                    color={COLORS.black}
                                                >
                                                    {`${
                                                        current.statsData
                                                            ?.pointsDisbursed ??
                                                        0
                                                    } pts`}
                                                </Text>
                                            </TableCell>
                                            <TableCell
                                                padding="0em 0em 0em 0em"
                                                borderBottomDisabled
                                                verticalAlign="top"
                                                width="250px"
                                            >
                                                <Text
                                                    font="OS"
                                                    fontSize="1.5rem"
                                                    color={COLORS.black}
                                                >
                                                    {`${
                                                        roundDecimals(current.statsData
                                                            ?.averagePointsDisbursed ??
                                                        0, 2)
                                                    } pts`}
                                                </Text>
                                            </TableCell>
                                            <TableCell
                                                padding="0em 0em 0em 0em"
                                                borderBottomDisabled
                                                verticalAlign="top"
                                                width="250px"
                                            >
                                                <Text
                                                    font="OS"
                                                    fontSize="1.5rem"
                                                    color={COLORS.black}
                                                >
                                                    {`${
                                                        current.statsData
                                                            ?.totalCouponsScanned ??
                                                        0
                                                    }`}
                                                </Text>
                                            </TableCell>
                                            <TableCell
                                                padding="0em 0em 0em 0em"
                                                borderBottomDisabled
                                                verticalAlign="top"
                                                width="250px"
                                            >
                                                <Text
                                                    font="OS"
                                                    fontSize="1.5rem"
                                                    color={COLORS.black}
                                                >
                                                    {roundDecimals(current.statsData
                                                        ?.averageCouponsScanned ??
                                                        0, 2)}
                                                </Text>
                                            </TableCell>
                                        </TableRow>
                                    </Table>
                                    <ButtonGroup
                                        titles={
                                            current.identifierDefinition
                                                .appLanguages
                                        }
                                        active={activeLanguage}
                                        onChange={setActiveLanguage}
                                        checked={current.identifierDefinition.appLanguages.map(
                                            (language) =>
                                                // Boolean(content.headline[language] && content.bodyText[language])
                                                language === activeLanguage
                                        )}
                                    />
                                    <Table>
                                        <TableRow>
                                            <TableHead>
                                                <FlexGrid
                                                    alignItems="center"
                                                    gap="0.8em"
                                                >
                                                    TYPE
                                                </FlexGrid>
                                            </TableHead>
                                            <TableHead>
                                                <FlexGrid
                                                    alignItems="center"
                                                    gap="0.8em"
                                                >
                                                    TARGET
                                                </FlexGrid>
                                            </TableHead>
                                            <TableHead>
                                                <FlexGrid
                                                    alignItems="center"
                                                    gap="0.8em"
                                                >
                                                    POINTS TO BE WON
                                                </FlexGrid>
                                            </TableHead>
                                            <TableHead>
                                                <FlexGrid
                                                    alignItems="center"
                                                    gap="0.8em"
                                                >
                                                    NO. OF PARTICIPANTS
                                                </FlexGrid>
                                            </TableHead>
                                            <TableHead>
                                                <FlexGrid
                                                    alignItems="center"
                                                    gap="0.8em"
                                                >
                                                    NO. OF PARTICIPANTS THAT
                                                    ACHIEVE TARGET
                                                </FlexGrid>
                                            </TableHead>
                                            <TableHead>
                                                <FlexGrid
                                                    alignItems="center"
                                                    gap="0.8em"
                                                >
                                                    % ACHIVEMENT
                                                </FlexGrid>
                                            </TableHead>
                                        </TableRow>
                                        {current.statsData.targetLevelStatistics.map(
                                            (schemeStats, index) => (
                                                <TableRow
                                                    key={`scheme-detail-${index}`}
                                                >
                                                    <TableCell verticalAlign="top">
                                                        <Text
                                                            fontSize="0.875rem"
                                                            workBreakAll
                                                        >
                                                            {typeof schemeStats.pointsToBeWon ===
                                                                'string' &&
                                                            schemeStats.pointsToBeWon.includes(
                                                                'X'
                                                            )
                                                                ? 'Multiplier'
                                                                : 'Slab'}
                                                        </Text>
                                                    </TableCell>
                                                    <TableCell verticalAlign="top">
                                                        <Text
                                                            fontSize="0.875rem"
                                                            workBreakAll
                                                        >
                                                            {schemeStats.target ===
                                                            1
                                                                ? `${schemeStats.target} coupon`
                                                                : `${
                                                                      schemeStats.target ??
                                                                      0
                                                                  } coupons`}
                                                        </Text>
                                                    </TableCell>
                                                    <TableCell verticalAlign="top">
                                                        <Text
                                                            fontSize="0.875rem"
                                                            workBreakAll
                                                        >
                                                            {schemeStats.pointsToBeWon ===
                                                            1
                                                                ? `${schemeStats.pointsToBeWon} point`
                                                                : `${
                                                                      schemeStats.pointsToBeWon ??
                                                                      0
                                                                  } points`}
                                                        </Text>
                                                    </TableCell>
                                                    <TableCell verticalAlign="top">
                                                        <Text
                                                            fontSize="0.875rem"
                                                            workBreakAll
                                                        >
                                                            {schemeStats.noOfParticipants ??
                                                                0}
                                                        </Text>
                                                    </TableCell>
                                                    <TableCell verticalAlign="top">
                                                        <Text
                                                            fontSize="0.875rem"
                                                            workBreakAll
                                                        >
                                                            {schemeStats.achieved ??
                                                                0}
                                                        </Text>
                                                    </TableCell>
                                                    <TableCell verticalAlign="top">
                                                        <Text
                                                            fontSize="0.875rem"
                                                            workBreakAll
                                                        >
                                                            {`${
                                                                roundDecimals(schemeStats.percentAchievement ??
                                                                0, 2)
                                                            }%`}
                                                        </Text>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </Table>
                                </FlexGrid>
                            }
                        />
                    )}
                    <Table>
                        <TableRow>
                            <TableHead
                                padding="2em 0em 0em 0em"
                                borderBottomDisabled
                            >
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Total Contests
                                </FlexGrid>
                            </TableHead>
                            <TableHead
                                padding="2em 0em 0em 0em"
                                borderBottomDisabled
                            >
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Contests Active
                                </FlexGrid>
                            </TableHead>
                            <TableHead
                                padding="2em 0em 0em 0em"
                                borderBottomDisabled
                            >
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Contests Expired
                                </FlexGrid>
                            </TableHead>
                            <TableHead
                                padding="2em 0em 0em 0em"
                                borderBottomDisabled
                            >
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Point Disbursed
                                </FlexGrid>
                            </TableHead>
                            <TableHead
                                padding="2em 0em 0em 0em"
                                borderBottomDisabled
                            >
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Total Participants
                                </FlexGrid>
                            </TableHead>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                padding="0em 0em 0em 0em"
                                borderBottomDisabled
                                verticalAlign="top"
                                width="250px"
                            >
                                <Text
                                    font="OS"
                                    fontSize="1.5rem"
                                    color={COLORS.black}
                                >
                                    {
                                        scanningContestsDashboardStats.totalContestLaunched
                                    }
                                </Text>
                            </TableCell>
                            <TableCell
                                padding="0em 0em 0em 0em"
                                borderBottomDisabled
                                verticalAlign="top"
                                width="250px"
                            >
                                <Text
                                    font="OS"
                                    fontSize="1.5rem"
                                    color={COLORS.black}
                                >
                                    {
                                        scanningContestsDashboardStats.scanningContestsActive
                                    }
                                </Text>
                            </TableCell>
                            <TableCell
                                padding="0em 0em 0em 0em"
                                borderBottomDisabled
                                verticalAlign="top"
                                width="250px"
                            >
                                <Text
                                    font="OS"
                                    fontSize="1.5rem"
                                    color={COLORS.black}
                                >
                                    {
                                        scanningContestsDashboardStats.scanningContestsExpired
                                    }
                                </Text>
                            </TableCell>
                            <TableCell
                                padding="0em 0em 0em 0em"
                                borderBottomDisabled
                                verticalAlign="top"
                                width="250px"
                            >
                                <Text
                                    font="OS"
                                    fontSize="1.5rem"
                                    color={COLORS.black}
                                >
                                    {`${scanningContestsDashboardStats.pointsDisbursed} pts`}
                                </Text>
                            </TableCell>
                            <TableCell
                                padding="0em 0em 0em 0em"
                                borderBottomDisabled
                                verticalAlign="top"
                                width="250px"
                            >
                                <Text
                                    font="OS"
                                    fontSize="1.5rem"
                                    color={COLORS.black}
                                >
                                    {/* {29.4K} */}
                                    {
                                        scanningContestsDashboardStats.totalParticipants
                                    }
                                </Text>
                            </TableCell>
                        </TableRow>
                    </Table>
                    <Table>
                        <TableRow>
                            <TableHead>
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Contest Name
                                    {sort.name === 0 && (
                                        <IconButton
                                            negative
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    name: 1,
                                                });
                                            }}
                                            transform="rotate(-90deg)"
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.name === 1 && (
                                        <IconButton
                                            negative
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    name: -1,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.name === -1 && (
                                        <IconButton
                                            negative
                                            transform="rotate(180deg)"
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    name: 0,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                </FlexGrid>
                            </TableHead>
                            <TableHead>Description</TableHead>
                            <TableHead>Applicable SKUs</TableHead>
                            <TableHead>
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Start Date
                                    {sort.startDate === 0 && (
                                        <IconButton
                                            negative
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    startDate: 1,
                                                });
                                            }}
                                            transform="rotate(-90deg)"
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.startDate === 1 && (
                                        <IconButton
                                            negative
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    startDate: -1,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.startDate === -1 && (
                                        <IconButton
                                            negative
                                            transform="rotate(180deg)"
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    startDate: 0,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                </FlexGrid>
                            </TableHead>
                            <TableHead>
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Total Points
                                    {sort.totalPoints === 0 && (
                                        <IconButton
                                            negative
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    totalPoints: 1,
                                                });
                                            }}
                                            transform="rotate(-90deg)"
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.totalPoints === 1 && (
                                        <IconButton
                                            negative
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    totalPoints: -1,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.totalPoints === -1 && (
                                        <IconButton
                                            negative
                                            transform="rotate(180deg)"
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    totalPoints: 0,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                </FlexGrid>
                            </TableHead>
                            <TableHead>Actions</TableHead>
                        </TableRow>
                        {scanningContests.map((scanningContest, key) => {
                            return (
                                <TableRow key={key}>
                                    <TableCell>
                                        <Text
                                            link
                                            fontSize="0.875rem"
                                            color={COLORS.green}
                                            onClick={() => {
                                                navigate(
                                                    getUrlKeepQuery(
                                                        `/scanningContest/${scanningContest.id}`
                                                    )
                                                );
                                            }}
                                        >
                                            {scanningContest?.name}
                                        </Text>
                                    </TableCell>
                                    <TableCell>
                                        {scanningContest?.description}
                                    </TableCell>
                                    <TableCell>
                                        {(
                                            scanningContest?.applicableSKUs ||
                                            []
                                        ).map((sku) => {
                                            const name = sku;

                                            return (
                                                <LinkText
                                                    key={uuidv4()}
                                                    fontSize="0.875rem"
                                                    color={COLORS.green}
                                                >
                                                    {name}
                                                </LinkText>
                                            );
                                        })}
                                    </TableCell>
                                    <TableCell>
                                        <FlexGrid direction="column">
                                            <Text fontSize="0.875rem">
                                                {moment
                                                    .tz(
                                                        scanningContest?.startDate,
                                                        'DD MMMM YYYY',
                                                        getDefaultTimezone()
                                                    )
                                                    .format('MMM DD, YYYY')}
                                            </Text>
                                            <Text fontSize="0.875rem">
                                                {scanningContest?.status}
                                            </Text>
                                        </FlexGrid>
                                    </TableCell>
                                    <TableCell>
                                        <FlexGrid gap="1em" alignItems="center">
                                            <BonusPointsIcon />
                                            <Text fontSize="0.875rem">
                                                {`${
                                                    scanningContest?.totalPoints ??
                                                    0
                                                }+${
                                                    scanningContest?.multiplierPoints
                                                }x pts`}
                                            </Text>
                                        </FlexGrid>
                                    </TableCell>
                                    <TableCell>
                                        <FlexGrid alignItems="center">
                                            <TableActionBtn
                                                onClick={async () => {
                                                    const scanningContestItemModel =
                                                        await ScanningContestItemToEditModel(
                                                            scanningContest
                                                        );
                                                    setDetailsModal(true);
                                                    setActiveLanguage(
                                                        scanningContestItemModel
                                                            .identifierDefinition
                                                            .appLanguages[0]
                                                    );
                                                    const scanningContestId =
                                                        scanningContestItemModel
                                                            .raw.sys.id;
                                                    const statsResponse =
                                                        await ApiService.getSingleScanningContestStats(
                                                            scanningContestId
                                                        );
                                                    const statsData =
                                                        statsResponse?.data;
                                                    console.log(
                                                        '==statsData==',
                                                        statsData,
                                                        '==schemes===',
                                                        scanningContestItemModel
                                                            .content.schemes
                                                    );
                                                    if (statsData) {
                                                        (
                                                            scanningContestItemModel as any
                                                        ).statsData = statsData;
                                                    }

                                                    // {
                                                    //     "noOfParticipants": 1,
                                                    //     "pointsDisbursed": 0,
                                                    //     "averagePointsDisbursed": 0,
                                                    //     "totalCouponsScanned": 1,
                                                    //     "averageCouponsScanned": 1,
                                                    //     "targetLevelStatistics": [
                                                    //         {
                                                    //             "schemeIndex": 1,
                                                    //             "target": 3,
                                                    //             "pointsToBeWon": 50,
                                                    //             "noOfParticipants": 1,
                                                    //             "achieved": 0,
                                                    //             "percentAchievement": 0
                                                    //         },
                                                    //         {
                                                    //             "schemeIndex": 2,
                                                    //             "target": 5,
                                                    //             "pointsToBeWon": 80,
                                                    //             "noOfParticipants": 0,
                                                    //             "achieved": 0,
                                                    //             "percentAchievement": null
                                                    //         },
                                                    //         {
                                                    //             "schemeIndex": 3,
                                                    //             "target": 10,
                                                    //             "pointsToBeWon": 150,
                                                    //             "noOfParticipants": 0,
                                                    //             "achieved": 0,
                                                    //             "percentAchievement": null
                                                    //         },
                                                    //         {
                                                    //             "schemeIndex": 4,
                                                    //             "target": 5,
                                                    //             "pointsToBeWon": "20 X 1",
                                                    //             "noOfParticipants": 0,
                                                    //             "achieved": 0,
                                                    //             "percentAchievement": null
                                                    //         }
                                                    //     ]
                                                    // }
                                                    // console.log('==scanningContestItemModel==', scanningContestItemModel);

                                                    setCurrent(
                                                        scanningContestItemModel
                                                    );
                                                }}
                                                negative
                                            >
                                                Stats
                                            </TableActionBtn>
                                            <TableActionBtn
                                                onClick={async () => {
                                                    if (loading) {
                                                        return;
                                                    }
                                                    setLoading(true);
                                                    navigate(
                                                        getUrlKeepQuery(
                                                            '/scheme-offers/scanning-contests/create'
                                                        ),
                                                        {
                                                            state: {
                                                                data: await itemToEditModel(
                                                                    scanningContest
                                                                ),
                                                                page,
                                                                viewOnly: true,
                                                            },
                                                        }
                                                    );
                                                }}
                                                negative
                                            >
                                                View
                                            </TableActionBtn>
                                            <TableActionBtn
                                                onClick={async () => {
                                                    if (loading) {
                                                        return;
                                                    }
                                                    setLoading(true);
                                                    navigate(
                                                        getUrlKeepQuery(
                                                            '/scheme-offers/scanning-contests/create'
                                                        ),
                                                        {
                                                            state: {
                                                                data: await itemToEditModel(
                                                                    scanningContest
                                                                ),
                                                                page,
                                                            },
                                                        }
                                                    );
                                                }}
                                                negative
                                            >
                                                Edit
                                            </TableActionBtn>
                                            <TableActionBtn
                                                onClick={async () => {
                                                    const scanningContestItemModel =
                                                        await ScanningContestItemToEditModel(
                                                            scanningContest
                                                        );
                                                    setCurrent(scanningContestItemModel);
                                                    setDeleteModal(true);
                                                }}
                                                negative
                                            >
                                                Remove
                                            </TableActionBtn>
                                        </FlexGrid>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </Table>
                    <Pagination
                        page={page}
                        perPage={perPage}
                        total={total}
                        setPage={(v) => {
                            setPage(v);
                        }}
                    />
                </>
            )}
        </>
    );
};
