import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { FlexGrid } from '../../../components/FlexGrid';
import {
    Table,
    TableCell,
    TableHead,
    // TablePagination,
    TableRow,
} from '../../../components/Table';
import { Text } from '../../../components/Text';
import { COLORS } from '../../../styles/variables';
import { Button } from '../../../components/Button';
import { ArrowDownIcon } from '../../../assets/icons';
import { Modal } from '../../../components/Modal';
// import { sampleQuizValue } from './sampleValue';
import { useQuizHook } from '../hooks/use-quiz-hook';
import { ContentfulTransformService } from '../../../services/ContentfulTransform.service';
import { Pagination } from '../../../components/Pagination';
import { ContentfulApiService } from '../../../services/ContentfulApi.service';
import {
    ArticleItemToEditModel,
    getUrlKeepQuery,
    QuizItemToEditModel,
    VideoItemToEditModel,
} from '../../../utils';
import { IconButton } from '../../../components/IconButton';
import { getAssetIdsParams, sortFunc } from '../../../common';
import { Tag } from '../../../components/Tag';
import { ButtonGroup } from '../../../components/ButtonGroup';
import { ApiService } from '../../../services/Api.service';
import { CreationService } from '../../../services/Creation.service';
import { useDispatch } from 'react-redux';

const TableActionBtn = styled(Button)`
    text-transform: none;
    font-family: 'AR';
    border-radius: 0;
    margin: 0;
    border-left: 1px solid ${COLORS.lightGray};

    &:first-of-type {
        border-left: none;
    }
`;

export const QuizzesTable: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation() as any;
    const [page, setPage] = useState(location.state?.page || 1);
    const [current, setCurrent] = useState(null);
    const [update, setUpdate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [activeLanguage, setActiveLanguage] = useState('');
    const DEFAULT_SORT = {
        name: 0,
        description: 0,
        date: 0,
        createdAt: 0,
        totalPoints: 0,
    };
    const [sort, setSort] = useState<any>(DEFAULT_SORT);
    const perPage = 5;
    const [{ hookData: quizzesObj }] = useQuizHook(
        {
            // perPage: 10,
            // page,
            // sortBy: `${sort.headline ? '' : '-'}fields.headline`,
        },
        [update]
    );

    const [quizzesDashboardStats, setQuizzesDashboardStats] = useState({
        pointsDisbursed: 0,
        quizActive: 0,
        quizExpired: 0,
        totalParticipants: 0,
        totalQuizLaunched: 0,
    });

    useEffect(() => {
        ApiService.getQuizzesDashboardStats()
            .then((response) => {
                // {
                //     pointsDisbursed: 311;
                //     quizActive: 3;
                //     quizExpired: 1;
                //     totalParticipants: 1;
                //     totalQuizLaunched: 4;
                // }
                const data = response.data;
                setQuizzesDashboardStats(data);
                // console.log('==getQuizzesDashboardStats==', data);
            })
            .catch((err) =>
                console.error(
                    '===Failed to fetch quizzes dashboard stats===',
                    err
                )
            );
    }, []);

    // const sortFunc = (items: any[], order) => {
    //     return items.sort((x, y) => {
    //         if (x.headline < y.headline) return !order ? 1 : -1;
    //         if (x.headline > y.headline) return !order ? -1 : 1;
    //         return 0;
    //     });
    // };
    let quizzes = sortFunc(sort, quizzesObj.mapped ?? []);
    const total = quizzesObj.raw?.total || 0;
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    quizzes = quizzes.slice(startIndex, endIndex);

    const [deleteModal, setDeleteModal] = useState(false);
    const [detailsModal, setDetailsModal] = useState(false);

    const itemToEditModel = async (key: number) => {
        return await QuizItemToEditModel(quizzes[key]);
    };

    return (
        <>
            {deleteModal && (
                <Modal
                    open={deleteModal}
                    header="Delete Quiz"
                    onClose={() => setDeleteModal(false)}
                    content={
                        <FlexGrid
                            direction="column"
                            width="100%"
                            gap="2em"
                            p="2em 0em"
                        >
                            <Text color={COLORS.darkGray}>
                                Are you sure you want to delete this quiz?
                            </Text>
                            <Text color={COLORS.darkGray}>
                                <Text
                                    color={COLORS.darkGray}
                                    span
                                    font="AR Bold"
                                >
                                    Note:{' '}
                                </Text>
                                Deleting quiz is a permanent action and cannot
                                be undone.
                            </Text>
                        </FlexGrid>
                    }
                    actions={
                        <FlexGrid alignItems="center">
                            <Button
                                onClick={() => {
                                    CreationService.deleteQuiz(dispatch, current).then(() => {
                                        setUpdate(!update);
                                        if (total % perPage === 1) {
                                            setPage(Math.max(page - 1, 1));
                                        }
                                    });
                                    setDeleteModal(false);
                                    setCurrent(null);
                                }}
                                p="1.3em 2.5em"
                            >
                                DELETE
                            </Button>
                            <Button
                                onClick={() => {
                                    setDeleteModal(false);
                                    setCurrent(null);
                                }}
                                negative
                                p="0.5em 1em"
                            >
                                CANCEL
                            </Button>
                        </FlexGrid>
                    }
                />
            )}
            {detailsModal && current && (
                <Modal
                    open={detailsModal}
                    header={`${current.identifierDefinition?.name} details`}
                    onClose={() => setDetailsModal(false)}
                    content={
                        <FlexGrid direction="column">
                            <Table>
                                <TableRow>
                                    <TableHead
                                        padding="2em 0em 0em 0em"
                                        borderBottomDisabled
                                    >
                                        <FlexGrid
                                            alignItems="center"
                                            gap="0.8em"
                                        >
                                            No. of Respondents
                                        </FlexGrid>
                                    </TableHead>
                                    <TableHead
                                        padding="2em 0em 0em 0em"
                                        borderBottomDisabled
                                    >
                                        <FlexGrid
                                            alignItems="center"
                                            gap="0.8em"
                                        >
                                            Total Points disbursed
                                        </FlexGrid>
                                    </TableHead>
                                    <TableHead
                                        padding="2em 0em 0em 0em"
                                        borderBottomDisabled
                                    >
                                        <FlexGrid
                                            alignItems="center"
                                            gap="0.8em"
                                        >
                                            Average Points disbursed
                                        </FlexGrid>
                                    </TableHead>
                                    <TableHead
                                        padding="2em 0em 0em 0em"
                                        borderBottomDisabled
                                    >
                                        <FlexGrid
                                            alignItems="center"
                                            gap="0.8em"
                                        >
                                            % Effectiveness
                                        </FlexGrid>
                                    </TableHead>
                                    <TableHead
                                        padding="2em 0em 0em 0em"
                                        borderBottomDisabled
                                    >
                                        <FlexGrid
                                            alignItems="center"
                                            gap="0.8em"
                                        >
                                            Total Question
                                        </FlexGrid>
                                    </TableHead>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        padding="0em 0em 0em 0em"
                                        borderBottomDisabled
                                        verticalAlign="top"
                                        width="250px"
                                    >
                                        <Text
                                            font="OS"
                                            fontSize="1.5rem"
                                            color={COLORS.black}
                                        >
                                            {current.statsData
                                                ?.noOfRespondents ?? 0}
                                        </Text>
                                    </TableCell>
                                    <TableCell
                                        padding="0em 0em 0em 0em"
                                        borderBottomDisabled
                                        verticalAlign="top"
                                        width="250px"
                                    >
                                        <Text
                                            font="OS"
                                            fontSize="1.5rem"
                                            color={COLORS.black}
                                        >
                                            {`${
                                                current.statsData
                                                    ?.pointsDisbursed
                                                    ?? 0
                                            } pts`}
                                        </Text>
                                    </TableCell>
                                    <TableCell
                                        padding="0em 0em 0em 0em"
                                        borderBottomDisabled
                                        verticalAlign="top"
                                        width="250px"
                                    >
                                        <Text
                                            font="OS"
                                            fontSize="1.5rem"
                                            color={COLORS.black}
                                        >
                                            {`${
                                                current.statsData
                                                    ?.averagePointsDisbursed
                                                    ? current.statsData?.averagePointsDisbursed.toFixed(
                                                          2
                                                      )
                                                    : 0
                                            } pts`}
                                        </Text>
                                    </TableCell>
                                    <TableCell
                                        padding="0em 0em 0em 0em"
                                        borderBottomDisabled
                                        verticalAlign="top"
                                        width="250px"
                                    >
                                        <Text
                                            font="OS"
                                            fontSize="1.5rem"
                                            color={COLORS.black}
                                        >
                                            {`${
                                                typeof current.statsData
                                                    ?.effectiveness === 'number'
                                                    ? current.statsData?.effectiveness.toFixed(
                                                          2
                                                      )
                                                    : current.statsData
                                                          ?.effectiveness
                                            }`}
                                        </Text>
                                    </TableCell>
                                    <TableCell
                                        padding="0em 0em 0em 0em"
                                        borderBottomDisabled
                                        verticalAlign="top"
                                        width="250px"
                                    >
                                        <Text
                                            font="OS"
                                            fontSize="1.5rem"
                                            color={COLORS.black}
                                        >
                                            {current.content.questions.length}
                                        </Text>
                                    </TableCell>
                                </TableRow>
                            </Table>
                            <ButtonGroup
                                titles={
                                    current.identifierDefinition.appLanguages
                                }
                                active={activeLanguage}
                                onChange={setActiveLanguage}
                                checked={current.identifierDefinition.appLanguages.map(
                                    (language) =>
                                        // Boolean(content.headline[language] && content.bodyText[language])
                                        language === activeLanguage
                                )}
                            />
                            <Table>
                                <TableRow>
                                    <TableHead>
                                        <FlexGrid
                                            alignItems="center"
                                            gap="0.8em"
                                        >
                                            NUMBER
                                        </FlexGrid>
                                    </TableHead>
                                    <TableHead>
                                        <FlexGrid
                                            alignItems="center"
                                            gap="0.8em"
                                        >
                                            QUESTION
                                        </FlexGrid>
                                    </TableHead>
                                    <TableHead>
                                        <FlexGrid
                                            alignItems="center"
                                            gap="0.8em"
                                        >
                                            CORRECT ANSWER
                                        </FlexGrid>
                                    </TableHead>
                                    <TableHead>
                                        <FlexGrid
                                            alignItems="center"
                                            gap="0.8em"
                                        >
                                            NO. OF RESPONDENTS
                                        </FlexGrid>
                                    </TableHead>
                                    <TableHead>
                                        <FlexGrid
                                            alignItems="center"
                                            gap="0.8em"
                                        >
                                            NO. THAT GOT IT CORRECT
                                        </FlexGrid>
                                    </TableHead>
                                    <TableHead>
                                        <FlexGrid
                                            alignItems="center"
                                            gap="0.8em"
                                        >
                                            % CORRECT
                                        </FlexGrid>
                                    </TableHead>
                                </TableRow>
                                {current.content.questions.map(
                                    (question, index) => (
                                        <TableRow
                                            key={`question-detail-${index}`}
                                        >
                                            <TableCell verticalAlign="top">
                                                <Text
                                                    fontSize="0.875rem"
                                                    workBreakAll
                                                >
                                                    {index + 1}
                                                </Text>
                                            </TableCell>
                                            <TableCell verticalAlign="top">
                                                <Text
                                                    fontSize="0.875rem"
                                                    workBreakAll
                                                >
                                                    {
                                                        question.content
                                                            .questionText[
                                                            activeLanguage
                                                        ]
                                                    }
                                                </Text>
                                            </TableCell>
                                            <TableCell verticalAlign="top">
                                                <Text
                                                    fontSize="0.875rem"
                                                    workBreakAll
                                                >
                                                    {
                                                        question.content
                                                            .options[
                                                            question.content
                                                                .correctAnswerIndex
                                                        ][activeLanguage]
                                                    }
                                                </Text>
                                            </TableCell>
                                            <TableCell verticalAlign="top">
                                                <Text
                                                    fontSize="0.875rem"
                                                    workBreakAll
                                                >
                                                    {current.statsData
                                                        ?.questionStatistics
                                                        ? current.statsData
                                                              ?.questionStatistics[
                                                              index
                                                          ].totalRespondents
                                                        : 0}
                                                </Text>
                                            </TableCell>
                                            <TableCell verticalAlign="top">
                                                <Text
                                                    fontSize="0.875rem"
                                                    workBreakAll
                                                >
                                                    {current.statsData
                                                        ?.questionStatistics
                                                        ? current.statsData
                                                              ?.questionStatistics[
                                                              index
                                                          ].correctRespondents
                                                        : 0}
                                                </Text>
                                            </TableCell>
                                            <TableCell verticalAlign="top">
                                                <Text
                                                    fontSize="0.875rem"
                                                    workBreakAll
                                                >
                                                    {`${
                                                        current.statsData
                                                            ?.questionStatistics
                                                            ? current.statsData
                                                                  ?.questionStatistics[
                                                                  index
                                                              ].percentCorrect.toFixed(2)
                                                            : 0
                                                    }`}
                                                </Text>
                                            </TableCell>
                                        </TableRow>
                                    )
                                )}
                            </Table>
                        </FlexGrid>
                    }
                />
            )}
            <Table>
                <TableRow>
                    <TableHead padding="2em 0em 0em 0em" borderBottomDisabled>
                        <FlexGrid alignItems="center" gap="0.8em">
                            Total Quiz launched
                        </FlexGrid>
                    </TableHead>
                    <TableHead padding="2em 0em 0em 0em" borderBottomDisabled>
                        <FlexGrid alignItems="center" gap="0.8em">
                            Quiz Active
                        </FlexGrid>
                    </TableHead>
                    <TableHead padding="2em 0em 0em 0em" borderBottomDisabled>
                        <FlexGrid alignItems="center" gap="0.8em">
                            Quiz Expired
                        </FlexGrid>
                    </TableHead>
                    <TableHead padding="2em 0em 0em 0em" borderBottomDisabled>
                        <FlexGrid alignItems="center" gap="0.8em">
                            Point Disbursed
                        </FlexGrid>
                    </TableHead>
                    <TableHead padding="2em 0em 0em 0em" borderBottomDisabled>
                        <FlexGrid alignItems="center" gap="0.8em">
                            Total Participant
                        </FlexGrid>
                    </TableHead>
                </TableRow>
                <TableRow>
                    <TableCell
                        padding="0em 0em 0em 0em"
                        borderBottomDisabled
                        verticalAlign="top"
                        width="250px"
                    >
                        <Text font="OS" fontSize="1.5rem" color={COLORS.black}>
                            {quizzesDashboardStats.totalQuizLaunched}
                        </Text>
                    </TableCell>
                    <TableCell
                        padding="0em 0em 0em 0em"
                        borderBottomDisabled
                        verticalAlign="top"
                        width="250px"
                    >
                        <Text font="OS" fontSize="1.5rem" color={COLORS.black}>
                            {quizzesDashboardStats.quizActive}
                        </Text>
                    </TableCell>
                    <TableCell
                        padding="0em 0em 0em 0em"
                        borderBottomDisabled
                        verticalAlign="top"
                        width="250px"
                    >
                        <Text font="OS" fontSize="1.5rem" color={COLORS.black}>
                            {quizzesDashboardStats.quizExpired}
                        </Text>
                    </TableCell>
                    <TableCell
                        padding="0em 0em 0em 0em"
                        borderBottomDisabled
                        verticalAlign="top"
                        width="250px"
                    >
                        <Text font="OS" fontSize="1.5rem" color={COLORS.black}>
                            {`${quizzesDashboardStats.pointsDisbursed} pts`}
                        </Text>
                    </TableCell>
                    <TableCell
                        padding="0em 0em 0em 0em"
                        borderBottomDisabled
                        verticalAlign="top"
                        width="250px"
                    >
                        <Text font="OS" fontSize="1.5rem" color={COLORS.black}>
                            {/* {29.4K} */}
                            {quizzesDashboardStats.totalParticipants}
                        </Text>
                    </TableCell>
                </TableRow>
            </Table>
            <Table>
                <TableRow>
                    <TableHead>
                        <FlexGrid alignItems="center" gap="0.8em">
                            Name
                            {sort.name === 0 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    transform="rotate(-90deg)"
                                    onClick={() => {
                                        setSort({ ...DEFAULT_SORT, name: 1 });
                                    }}
                                />
                            )}
                            {sort.name === 1 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    onClick={() => {
                                        setSort({ ...DEFAULT_SORT, name: -1 });
                                    }}
                                />
                            )}
                            {sort.name === -1 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    transform="rotate(-180deg)"
                                    onClick={() => {
                                        setSort({ ...DEFAULT_SORT, name: 0 });
                                    }}
                                />
                            )}
                        </FlexGrid>
                    </TableHead>
                    <TableHead>
                        <FlexGrid alignItems="center" gap="0.8em">
                            Description
                            {sort.description === 0 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    transform="rotate(-90deg)"
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            description: 1,
                                        });
                                    }}
                                />
                            )}
                            {sort.description === 1 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            description: -1,
                                        });
                                    }}
                                />
                            )}
                            {sort.description === -1 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    transform="rotate(-180deg)"
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            description: 0,
                                        });
                                    }}
                                />
                            )}
                        </FlexGrid>
                    </TableHead>
                    <TableHead>
                        <FlexGrid alignItems="center" gap="0.8em">
                            CONTENT IT WAS LINKED TO
                        </FlexGrid>
                    </TableHead>
                    <TableHead>
                        <FlexGrid alignItems="center" gap="0.8em">
                            Date
                            {sort.date === 0 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    transform="rotate(-90deg)"
                                    onClick={() => {
                                        setSort({ ...DEFAULT_SORT, date: 1 });
                                    }}
                                />
                            )}
                            {sort.date === 1 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    onClick={() => {
                                        setSort({ ...DEFAULT_SORT, date: -1 });
                                    }}
                                />
                            )}
                            {sort.date === -1 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    transform="rotate(-180deg)"
                                    onClick={() => {
                                        setSort({ ...DEFAULT_SORT, date: 0 });
                                    }}
                                />
                            )}
                        </FlexGrid>
                    </TableHead>
                    <TableHead>
                        <FlexGrid alignItems="center" gap="0.8em">
                            Points to be won
                            {sort.totalPoints === 0 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    transform="rotate(-90deg)"
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            totalPoints: 1,
                                        });
                                    }}
                                />
                            )}
                            {sort.totalPoints === 1 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            totalPoints: -1,
                                        });
                                    }}
                                />
                            )}
                            {sort.totalPoints === -1 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    transform="rotate(-180deg)"
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            totalPoints: 0,
                                        });
                                    }}
                                />
                            )}
                        </FlexGrid>
                    </TableHead>
                    <TableHead>Actions</TableHead>
                </TableRow>
                {quizzes.map((quiz, key) => (
                    <TableRow key={key}>
                        <TableCell verticalAlign="top">
                            <Text
                                color={COLORS.green}
                                fontSize="0.875rem"
                                workBreakAll
                            >
                                {quiz.name}
                            </Text>
                        </TableCell>
                        <TableCell verticalAlign="top">
                            {quiz.description}
                        </TableCell>
                        <TableCell verticalAlign="top">
                            {quiz.linkedContentObj ? (
                                <FlexGrid alignItems="center" gap="0.5em">
                                    <Text
                                        color={COLORS.green}
                                        fontSize="0.875rem"
                                        workBreakAll
                                    >
                                        {quiz.linkedContentObj.name}
                                    </Text>
                                    <Tag
                                        title={`${quiz.linkedContentObj.contentType}`}
                                    />
                                </FlexGrid>
                            ) : (
                                ''
                            )}
                        </TableCell>
                        <TableCell verticalAlign="top">
                            <FlexGrid direction="column">
                                <Text fontSize="0.875rem">{quiz.date}</Text>
                                <Text fontSize="0.875rem">{quiz.status}</Text>
                            </FlexGrid>
                        </TableCell>
                        <TableCell verticalAlign="top">
                            <Text fontSize="0.875rem">{quiz.totalPoints}</Text>
                        </TableCell>
                        <TableCell verticalAlign="top">
                            <FlexGrid alignItems="center">
                                <TableActionBtn
                                    onClick={async () => {
                                        const quizItemModel =
                                            await QuizItemToEditModel(quiz);
                                        setDetailsModal(true);
                                        setActiveLanguage(
                                            quizItemModel.identifierDefinition
                                                .appLanguages[0]
                                        );
                                        const quizId = quizItemModel.raw.sys.id;
                                        const statsResponse =
                                            await ApiService.getSingleQuizStats(
                                                quizId
                                            );
                                        const statsData = statsResponse?.data;
                                        // console.log('==statsData==', statsData);
                                        if (statsData) {
                                            (quizItemModel as any).statsData =
                                                statsData;
                                        }
                                        // {
                                        //     averagePointsDisbursed: 0,
                                        //     effectiveness: "N/A",
                                        //     noOfRespondents: 0,
                                        //     pointsDisbursed: 0,
                                        //     questionStatistics:
                                        //       [
                                        //         { correctRespondents: 1, percentCorrect: null },

                                        //         { correctRespondents: 1, percentCorrect: null },
                                        //       ],

                                        //     totalQuestions: 1,
                                        //   }

                                        setCurrent(quizItemModel);
                                    }}
                                    negative
                                >
                                    Stats
                                </TableActionBtn>
                                <TableActionBtn
                                    onClick={async () => {
                                        if (loading) {
                                            return;
                                        }
                                        setLoading(true);
                                        if (quiz.linkedContentObj) {
                                            const contentType =
                                                quiz.linkedContentObj
                                                    .contentType;
                                            if (contentType === 'video') {
                                                const playlist =
                                                    await ContentfulApiService.searchContent(
                                                        {
                                                            contentType:
                                                                'videoPlaylist',
                                                        }
                                                    );
                                                const videoItems = [
                                                    quiz.linkedContentObj.raw,
                                                ];
                                                const assetsResponse =
                                                    await ContentfulApiService.getAssets(
                                                        {
                                                            ...getAssetIdsParams(
                                                                ContentfulTransformService.getVideosAssets(
                                                                    videoItems
                                                                )
                                                            ),
                                                        }
                                                    );
                                                const videos =
                                                    ContentfulTransformService.getVideos(
                                                        assetsResponse,
                                                        videoItems,
                                                        playlist?.items
                                                    );
                                                if (
                                                    videos &&
                                                    videos.length > 0
                                                ) {
                                                    const video = videos[0];
                                                    const videoItemModel =
                                                        VideoItemToEditModel(
                                                            video
                                                        );
                                                    const quizItemModel =
                                                        await QuizItemToEditModel(
                                                            quiz
                                                        );
                                                    videoItemModel.quiz = {
                                                        raw: quizItemModel.raw,
                                                        status: quizItemModel.status,
                                                        content:
                                                            quizItemModel.content,
                                                    };
                                                    navigate(
                                                        getUrlKeepQuery(
                                                            '/communication/videos/create'
                                                        ),
                                                        {
                                                            state: {
                                                                data: videoItemModel,
                                                                page,
                                                                viewOnly: true,
                                                            },
                                                        }
                                                    );
                                                }
                                            } else if (
                                                contentType === 'article'
                                            ) {
                                                const articleItems = [
                                                    quiz.linkedContentObj.raw,
                                                ];
                                                const articleFolder =
                                                    await ContentfulApiService.searchContent(
                                                        {
                                                            contentType:
                                                                'articleFolder',
                                                        }
                                                    );
                                                const assetsResponse =
                                                    await ContentfulApiService.getAssets(
                                                        {
                                                            ...getAssetIdsParams(
                                                                ContentfulTransformService.getArticlesAssets(
                                                                    articleItems
                                                                )
                                                            ),
                                                        }
                                                    );
                                                const articles =
                                                    ContentfulTransformService.getArticles(
                                                        assetsResponse,
                                                        articleItems,
                                                        articleFolder?.items ||
                                                            []
                                                    );
                                                if (
                                                    articles &&
                                                    articles.length > 0
                                                ) {
                                                    const article = articles[0];
                                                    const articleItemModel =
                                                        ArticleItemToEditModel(
                                                            article
                                                        );
                                                    const quizItemModel =
                                                        await QuizItemToEditModel(
                                                            quiz
                                                        );
                                                    articleItemModel.quiz = {
                                                        raw: quizItemModel.raw,
                                                        status: quizItemModel.status,
                                                        content:
                                                            quizItemModel.content,
                                                    };
                                                    navigate(
                                                        getUrlKeepQuery(
                                                            '/communication/articles/create'
                                                        ),
                                                        {
                                                            state: {
                                                                data: articleItemModel,
                                                                page,
                                                                viewOnly: true,
                                                            },
                                                        }
                                                    );
                                                }
                                            } else if (
                                                contentType === 'learningCard'
                                            ) {
                                                // const learningCardItems = [
                                                //     quiz.linkedContentObj.raw,
                                                // ];
                                                // // const articleFolder =
                                                // //     await ContentfulApiService.searchContent(
                                                // //         {
                                                // //             contentType:
                                                // //                 'articleFolder',
                                                // //         }
                                                // //     );
                                                // const assetsResponse =
                                                //     await ContentfulApiService.getAssets(
                                                //         {
                                                //             ...getAssetIdsParams(
                                                //                 ContentfulTransformService.getArticlesAssets(
                                                //                     learningCardItems
                                                //                 )
                                                //             ),
                                                //         }
                                                //     );
                                                // const articles =
                                                //     ContentfulTransformService.getArticles(
                                                //         assetsResponse,
                                                //         articleItems,
                                                //         articleFolder?.items ||
                                                //             []
                                                //     );
                                                // if (
                                                //     articles &&
                                                //     articles.length > 0
                                                // ) {
                                                //     const article = articles[0];
                                                //     const articleItemModel =
                                                //         ArticleItemToEditModel(
                                                //             article
                                                //         );
                                                //     const quizItemModel =
                                                //         await QuizItemToEditModel(
                                                //             quiz
                                                //         );
                                                //     articleItemModel.quiz = {
                                                //         raw: quizItemModel.raw,
                                                //         status: quizItemModel.status,
                                                //         content:
                                                //             quizItemModel.content,
                                                //     };
                                                //     navigate(
                                                //         getUrlKeepQuery(
                                                //             '/communication/articles/create'
                                                //         ),
                                                //         {
                                                //             state: {
                                                //                 data: articleItemModel,
                                                //                 page,
                                                //                 viewOnly: true,
                                                //             },
                                                //         }
                                                //     );
                                                // }
                                            }
                                        } else {
                                            navigate(
                                                getUrlKeepQuery(
                                                    '/communication/quizzes/create'
                                                ),
                                                {
                                                    state: {
                                                        data: await itemToEditModel(
                                                            key
                                                        ),
                                                        page,
                                                        viewOnly: true,
                                                    },
                                                }
                                            );
                                        }
                                    }}
                                    negative
                                >
                                    View
                                </TableActionBtn>
                                <TableActionBtn
                                    onClick={async () => {
                                        if (loading) {
                                            return;
                                        }
                                        setLoading(true);
                                        if (quiz.linkedContentObj) {
                                            const contentType =
                                                quiz.linkedContentObj
                                                    .contentType;

                                            if (contentType === 'video') {
                                                const playlist =
                                                    await ContentfulApiService.searchContent(
                                                        {
                                                            contentType:
                                                                'videoPlaylist',
                                                        }
                                                    );
                                                const videoItems = [
                                                    quiz.linkedContentObj.raw,
                                                ];
                                                const assetsResponse =
                                                    await ContentfulApiService.getAssets(
                                                        {
                                                            ...getAssetIdsParams(
                                                                ContentfulTransformService.getVideosAssets(
                                                                    videoItems
                                                                )
                                                            ),
                                                        }
                                                    );
                                                const videos =
                                                    ContentfulTransformService.getVideos(
                                                        assetsResponse,
                                                        videoItems,
                                                        playlist?.items
                                                    );
                                                if (
                                                    videos &&
                                                    videos.length > 0
                                                ) {
                                                    const video = videos[0];
                                                    const videoItemModel =
                                                        VideoItemToEditModel(
                                                            video
                                                        );
                                                    const quizItemModel =
                                                        await QuizItemToEditModel(
                                                            quiz
                                                        );
                                                    videoItemModel.quiz = {
                                                        raw: quizItemModel.raw,
                                                        status: quizItemModel.status,
                                                        content:
                                                            quizItemModel.content,
                                                    };
                                                    navigate(
                                                        getUrlKeepQuery(
                                                            '/communication/videos/create'
                                                        ),
                                                        {
                                                            state: {
                                                                data: videoItemModel,
                                                                page,
                                                                quizEdit: true,
                                                            },
                                                        }
                                                    );
                                                }
                                            } else if (
                                                contentType === 'article'
                                            ) {
                                                const articleItems = [
                                                    quiz.linkedContentObj.raw,
                                                ];
                                                const articleFolder =
                                                    await ContentfulApiService.searchContent(
                                                        {
                                                            contentType:
                                                                'articleFolder',
                                                        }
                                                    );
                                                const assetsResponse =
                                                    await ContentfulApiService.getAssets(
                                                        {
                                                            ...getAssetIdsParams(
                                                                ContentfulTransformService.getArticlesAssets(
                                                                    articleItems
                                                                )
                                                            ),
                                                        }
                                                    );
                                                const articles =
                                                    ContentfulTransformService.getArticles(
                                                        assetsResponse,
                                                        articleItems,
                                                        articleFolder?.items ||
                                                            []
                                                    );
                                                if (
                                                    articles &&
                                                    articles.length > 0
                                                ) {
                                                    const article = articles[0];
                                                    const articleItemModel =
                                                        ArticleItemToEditModel(
                                                            article
                                                        );
                                                    const quizItemModel =
                                                        await QuizItemToEditModel(
                                                            quiz
                                                        );
                                                    articleItemModel.quiz = {
                                                        raw: quizItemModel.raw,
                                                        status: quizItemModel.status,
                                                        content:
                                                            quizItemModel.content,
                                                    };
                                                    navigate(
                                                        getUrlKeepQuery(
                                                            '/communication/articles/create'
                                                        ),
                                                        {
                                                            state: {
                                                                data: articleItemModel,
                                                                page,
                                                                quizEdit: true,
                                                            },
                                                        }
                                                    );
                                                }
                                            } else if (
                                                contentType === 'learningCard'
                                            ) {
                                                // const articleItems = [
                                                //     quiz.linkedContentObj.raw,
                                                // ];
                                                // const articleFolder =
                                                //     await ContentfulApiService.searchContent(
                                                //         {
                                                //             contentType:
                                                //                 'articleFolder',
                                                //         }
                                                //     );
                                                // const assetsResponse =
                                                //     await ContentfulApiService.getAssets(
                                                //         {
                                                //             ...getAssetIdsParams(
                                                //                 ContentfulTransformService.getArticlesAssets(
                                                //                     articleItems
                                                //                 )
                                                //             ),
                                                //         }
                                                //     );
                                                // const articles =
                                                //     ContentfulTransformService.getArticles(
                                                //         assetsResponse,
                                                //         articleItems,
                                                //         articleFolder?.items ||
                                                //             []
                                                //     );
                                                // if (
                                                //     articles &&
                                                //     articles.length > 0
                                                // ) {
                                                //     const article = articles[0];
                                                //     const articleItemModel =
                                                //         ArticleItemToEditModel(
                                                //             article
                                                //         );
                                                //     const quizItemModel =
                                                //         await QuizItemToEditModel(
                                                //             quiz
                                                //         );
                                                //     articleItemModel.quiz = {
                                                //         raw: quizItemModel.raw,
                                                //         status: quizItemModel.status,
                                                //         content:
                                                //             quizItemModel.content,
                                                //     };
                                                //     navigate(
                                                //         getUrlKeepQuery(
                                                //             '/communication/articles/create'
                                                //         ),
                                                //         {
                                                //             state: {
                                                //                 data: articleItemModel,
                                                //                 page,
                                                //                 quizEdit: true,
                                                //             },
                                                //         }
                                                //     );
                                                // }
                                            }
                                        } else {
                                            navigate(
                                                getUrlKeepQuery(
                                                    '/communication/quizzes/create'
                                                ),
                                                {
                                                    state: {
                                                        data: await itemToEditModel(
                                                            key
                                                        ),
                                                        page,
                                                    },
                                                }
                                            );
                                        }
                                    }}
                                    negative
                                >
                                    Edit
                                </TableActionBtn>
                                {!quiz.linkedContent && (
                                    <TableActionBtn
                                        onClick={async () => {
                                            const quizItemModel = await QuizItemToEditModel(quiz);
                                            setCurrent(quizItemModel);
                                            setDeleteModal(true);
                                        }}
                                        negative
                                    >
                                        Delete
                                    </TableActionBtn>
                                )}
                            </FlexGrid>
                        </TableCell>
                    </TableRow>
                ))}
            </Table>
            <Pagination
                page={page}
                perPage={perPage}
                total={total}
                setPage={(v) => {
                    setPage(v);
                }}
            />
        </>
    );
};
