import React, { useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import 'moment-timezone';
import { FlexGrid } from '../../../components/FlexGrid';
import {
    Table,
    TableCell,
    TableHead,
    // TablePagination,
    TableRow,
} from '../../../components/Table';
import { Text } from '../../../components/Text';
import { COLORS } from '../../../styles/variables';
import {
    ThumbsUpIcon,
    ThumbsDownIcon,
    ArrowDownIcon,
} from '../../../assets/icons';
import { Button } from '../../../components/Button';
import { Modal } from '../../../components/Modal';
// import { sampleArticleValue } from './sampleValue';
import { IconButton } from '../../../components/IconButton';
import { useDashboardArticleHook } from '../../Dashboard/hooks/use-article-hook';
import { Pagination } from '../../../components/Pagination';
import { ContentfulApiService } from '../../../services/ContentfulApi.service';
// import { cpuUsage } from 'node:process';
import { ContentfulTransformService } from '../../../services/ContentfulTransform.service';
// import { useDashboardAssetsHook } from '../../Dashboard/hooks/use-assets-hook';
import {
    // DEFAULT_LANGUAGE_CODE,
    // LANGUAGE_CONTENTFUL_MAP_REVERSE,
    PLACEHOLDER_IMAGE,
} from '../../../common/constants';
import {
    ArticleItemToEditModel,
    getDefaultTimezone,
    getUrlKeepQuery,
    QuizItemToEditModel,
} from '../../../utils';
import { sortFunc } from '../../../common';
import { CreationService } from '../../../services/Creation.service';
import { useDispatch } from 'react-redux';

const ImageContainer = styled(FlexGrid)`
    width: 100px;
    height: 64px;
    border-radius: 3px;
    overflow: hidden;
    position: relative;

    img {
        object-fit: cover;
        height: 100%;
    }
`;

const TableActionBtn = styled(Button)`
    text-transform: none;
    font-family: 'AR';
    border-radius: 0;
    margin: 0;
    border-left: 1px solid ${COLORS.lightGray};

    &:first-of-type {
        border-left: none;
    }
`;

const LinkText = styled(Text)`
    max-width: 280px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const ArticlesTable: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [current, setCurrent] = useState(null);
    const [update, setUpdate] = useState(false);
    const [loading, setLoading] = useState(false);
    const perPage = 5;
    const DEFAULT_SORT = {
        name: 0,
        featured: 0,
        startDate: 0,
        views: 0,
        uniqueViews: 0,
    };
    const [sort, setSort] = useState<any>(DEFAULT_SORT);

    const location = useLocation() as any;
    const [page, setPage] = useState(location.state?.page || 1);

    const [{ isLoading, hookData }] = useDashboardArticleHook(
        {
            // page,
            // perPage,
            // sortBy: `${sort.date ? '' : '-'}fields.startDate`,
        },
        [update]
    );

    // const [{ hookData: assetsHookData }] = useDashboardAssetsHook();

    const articles = ContentfulTransformService.getArticles(
        hookData?.assets,
        hookData?.items || [],
        hookData?.articleFolder?.items || []
    );

    // const entries = hookData?.articleFolder?.items || [];

    // If use preview api, the unpublished asset and entry are also included
    // const assets = hookData?.includes?.Asset;
    // const entries = hookData?.includes?.Entry;

    const [deleteModal, setDeleteModal] = useState(false);
    const { total, limit, skip } = hookData || {};

    let sortedItems = sortFunc(sort, articles);

    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    sortedItems = sortedItems.slice(startIndex, endIndex);

    const itemToEditModel = async (item: any) => {
        let quizEditModel;
        if (item.quiz) {
            const quizEntryId = item.quiz.sys.id;
            const contentfulQuiz = await ContentfulApiService.getEntry({
                contentType: 'quiz',
                entryId: quizEntryId,
            });
            const transformedQuiz =
                ContentfulTransformService.transformQuiz(contentfulQuiz);
            quizEditModel = await QuizItemToEditModel(transformedQuiz);
        }

        const toReturn = ArticleItemToEditModel(item);

        if (quizEditModel) {
            toReturn.quiz = {
                raw: quizEditModel.raw,
                status: quizEditModel.status,
                content: quizEditModel.content,
            };
        }

        console.log('edit infomation', item);
        return toReturn;
    };

    return (
        <>
            {!isLoading && (
                <>
                    <Modal
                        open={deleteModal}
                        header="Delete Article"
                        onClose={() => setDeleteModal(false)}
                        content={
                            <FlexGrid
                                direction="column"
                                width="100%"
                                gap="2em"
                                p="2em 0em"
                            >
                                <Text color={COLORS.darkGray}>
                                    Are you sure you want to delete this
                                    article?
                                </Text>
                                <Text color={COLORS.darkGray}>
                                    <Text
                                        color={COLORS.darkGray}
                                        span
                                        font="AR Bold"
                                    >
                                        Note:{' '}
                                    </Text>
                                    Deleting article is a permanent action and
                                    cannot be undone.
                                </Text>
                            </FlexGrid>
                        }
                        actions={
                            <FlexGrid alignItems="center">
                                <Button
                                    onClick={() => {
                                        CreationService.deleteArticle(
                                            dispatch,
                                            current
                                        )
                                            .then(() => {
                                                setUpdate(!update);
                                                if (total % perPage === 1) {
                                                    setPage(
                                                        Math.max(page - 1, 1)
                                                    );
                                                }
                                            })
                                            .catch((e) => {
                                                console.error('====e===', e);
                                                alert(
                                                    'Cannot delete published'
                                                );
                                            });
                                        setDeleteModal(false);
                                    }}
                                    p="1.3em 2.5em"
                                >
                                    DELETE
                                </Button>
                                <Button
                                    onClick={() => setDeleteModal(false)}
                                    negative
                                    p="0.5em 1em"
                                >
                                    CANCEL
                                </Button>
                            </FlexGrid>
                        }
                    />
                    <Table>
                        <TableRow>
                            <TableHead>
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Article
                                    {sort.name === 0 && (
                                        <IconButton
                                            negative
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    name: 1,
                                                });
                                            }}
                                            transform="rotate(-90deg)"
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.name === 1 && (
                                        <IconButton
                                            negative
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    name: -1,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.name === -1 && (
                                        <IconButton
                                            negative
                                            transform="rotate(180deg)"
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    name: 0,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                </FlexGrid>
                            </TableHead>
                            <TableHead>
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Featured
                                    {sort.featured === 0 && (
                                        <IconButton
                                            negative
                                            transform="rotate(-90deg)"
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    featured: 1,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.featured === 1 && (
                                        <IconButton
                                            negative
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    featured: -1,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.featured === -1 && (
                                        <IconButton
                                            negative
                                            transform="rotate(180deg)"
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    featured: 0,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                </FlexGrid>
                            </TableHead>
                            <TableHead>folder</TableHead>
                            <TableHead>Target User</TableHead>
                            <TableHead>
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Date
                                    {sort.startDate === 0 && (
                                        <IconButton
                                            negative
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    startDate: 1,
                                                });
                                            }}
                                            transform="rotate(-90deg)"
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.startDate === 1 && (
                                        <IconButton
                                            negative
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    startDate: -1,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.startDate === -1 && (
                                        <IconButton
                                            negative
                                            transform="rotate(180deg)"
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    startDate: 0,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                </FlexGrid>
                            </TableHead>
                            <TableHead>
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Views
                                    {sort.views === 0 && (
                                        <IconButton
                                            negative
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    views: 1,
                                                });
                                            }}
                                            transform="rotate(-90deg)"
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.views === 1 && (
                                        <IconButton
                                            negative
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    views: -1,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.views === -1 && (
                                        <IconButton
                                            negative
                                            transform="rotate(180deg)"
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    views: 0,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                </FlexGrid>
                            </TableHead>
                            <TableHead>
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Unique Views
                                    {sort.uniqueViews === 0 && (
                                        <IconButton
                                            negative
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    uniqueViews: 1,
                                                });
                                            }}
                                            transform="rotate(-90deg)"
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.uniqueViews === 1 && (
                                        <IconButton
                                            negative
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    uniqueViews: -1,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.uniqueViews === -1 && (
                                        <IconButton
                                            negative
                                            transform="rotate(180deg)"
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    uniqueViews: 0,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                </FlexGrid>
                            </TableHead>
                            <TableHead>Likes (vs Dislikes)</TableHead>
                            <TableHead>Actions</TableHead>
                        </TableRow>
                        {sortedItems.map((article, key) => {
                            // const { fields } = article;
                            // const assetId = fields?.articleImage?.sys?.id;
                            // const asset = _.find(
                            //     assets,
                            //     (o) => o?.sys?.id === assetId
                            // );
                            // const url = asset?.fields?.file?.url;
                            const url = article.image;

                            return (
                                <TableRow key={key}>
                                    <TableCell>
                                        <FlexGrid gap="1em" alignItems="center">
                                            <ImageContainer>
                                                <img
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    src={
                                                        url
                                                            ? `https:${url}`
                                                            : PLACEHOLDER_IMAGE
                                                    }
                                                    alt="article thumbnail"
                                                    onClick={() => {
                                                        navigate(
                                                            getUrlKeepQuery(
                                                                `/article/${article.id}`
                                                            )
                                                        );
                                                    }}
                                                />
                                            </ImageContainer>
                                            <Text
                                                link
                                                fontSize="0.875rem"
                                                color={COLORS.green}
                                                onClick={() => {
                                                    navigate(
                                                        getUrlKeepQuery(
                                                            `/article/${article.id}`
                                                        )
                                                    );
                                                }}
                                            >
                                                {article?.name}
                                            </Text>
                                        </FlexGrid>
                                    </TableCell>
                                    <TableCell>
                                        {article?.featured ? 'Yes' : 'No'}
                                    </TableCell>
                                    <TableCell>
                                        {(article?.folders || []).map(
                                            (folder) => {
                                                const name = folder.name;

                                                return (
                                                    <LinkText
                                                        key={uuidv4()}
                                                        fontSize="0.875rem"
                                                        color={COLORS.green}
                                                    >
                                                        {name}
                                                    </LinkText>
                                                );
                                            }
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <FlexGrid direction="column">
                                            {(article.targetUsers || []).map(
                                                (target) => (
                                                    <Text
                                                        key={uuidv4()}
                                                        fontSize="0.875rem"
                                                    >
                                                        {target}
                                                    </Text>
                                                )
                                            )}
                                        </FlexGrid>
                                    </TableCell>
                                    <TableCell>
                                        <FlexGrid direction="column">
                                            <Text fontSize="0.875rem">
                                                {moment
                                                    .tz(
                                                        article?.startDate,
                                                        'DD MMMM YYYY',
                                                        getDefaultTimezone()
                                                    )
                                                    .format('MMM DD, YYYY')}
                                            </Text>
                                            <Text fontSize="0.875rem">
                                                {article?.status}
                                            </Text>
                                        </FlexGrid>
                                    </TableCell>
                                    <TableCell>{article?.views ?? 0}</TableCell>
                                    <TableCell>
                                        {article?.uniqueViews ?? 0}
                                    </TableCell>
                                    <TableCell>
                                        <FlexGrid gap="0.8em">
                                            <FlexGrid
                                                gap="0.2em"
                                                alignItems="center"
                                            >
                                                <ThumbsUpIcon />
                                                <Text fontSize="0.875rem">
                                                    {article?.likes}
                                                </Text>
                                            </FlexGrid>
                                            <FlexGrid
                                                gap="0.2em"
                                                alignItems="center"
                                            >
                                                <ThumbsDownIcon />
                                                <Text fontSize="0.875rem">
                                                    {article?.dislikes}
                                                </Text>
                                            </FlexGrid>
                                        </FlexGrid>
                                    </TableCell>
                                    <TableCell>
                                        <FlexGrid alignItems="center">
                                            <TableActionBtn
                                                onClick={async () => {
                                                    if (loading) {
                                                        return;
                                                    }
                                                    setLoading(true);
                                                    navigate(
                                                        getUrlKeepQuery(
                                                            '/communication/articles/create'
                                                        ),
                                                        {
                                                            state: {
                                                                data: await itemToEditModel(
                                                                    article
                                                                ),
                                                                page,
                                                                viewOnly: true,
                                                            },
                                                        }
                                                    );
                                                }}
                                                negative
                                            >
                                                View
                                            </TableActionBtn>
                                            <TableActionBtn
                                                onClick={async () => {
                                                    if (loading) {
                                                        return;
                                                    }
                                                    setLoading(true);
                                                    navigate(
                                                        getUrlKeepQuery(
                                                            '/communication/articles/create'
                                                        ),
                                                        {
                                                            state: {
                                                                data: await itemToEditModel(
                                                                    article
                                                                ),
                                                                page,
                                                            },
                                                        }
                                                    );
                                                }}
                                                negative
                                            >
                                                Edit
                                            </TableActionBtn>
                                            <TableActionBtn
                                                onClick={async () => {
                                                    const articleModel =
                                                        await itemToEditModel(
                                                            article
                                                        );
                                                    setCurrent(articleModel);
                                                    setDeleteModal(true);
                                                }}
                                                negative
                                            >
                                                Remove
                                            </TableActionBtn>
                                        </FlexGrid>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </Table>
                    <Pagination
                        page={page}
                        perPage={perPage}
                        total={total}
                        setPage={(v) => {
                            setPage(v);
                        }}
                    />
                </>
            )}
        </>
    );
};
