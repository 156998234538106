import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { Accordion } from '../../../components/Accordion';
import { Button } from '../../../components/Button';
import { FlexGrid } from '../../../components/FlexGrid';
import { PageHeader } from '../../../components/PageHeader';
import { Text } from '../../../components/Text';
import { COLORS } from '../../../styles/variables';
import { DateIcon, TimeIcon, ImageIcon } from '../../../assets/icons';
import { Tag } from '../../../components/Tag';
import { Modal } from '../../../components/Modal';
import { BannerType, LookupValue } from '../../../types';
import { SuccessModal } from '../../../components/SuccessModal';
// import { ContentfulApiService } from '../../../services/ContentfulApi.service';
// import { ContentfulTransformService } from '../../../services/ContentfulTransform.service';
import { getUrlKeepQuery } from '../../../utils';
import { CreationService } from '../../../services/Creation.service';
import { DropDownPopulate } from '../../../services/Api.service';
import { INTERNAL_PAGE_LINK_REVERSED } from '../../../common/constants';
import { useDispatch } from 'react-redux';
import { updateModalAction } from '../../../store/store.actions';

const EditBtn = styled(Button)`
    font-size: 15px;
    font-family: 'AR';
`;

const Label = styled(Text)`
    color: ${COLORS.darkGray};
    font-size: 0.75rem;
`;

const Value = styled(FlexGrid)`
    width: 50%;
    flex-direction: column;
    gap: 0.5em;
    padding: 1em 2em;
`;

const Form = styled(FlexGrid)`
    max-width: 700px;
    margin-top: 0.5em;
`;

const PreviewImg = styled.img`
    width: 100%;
    object-fit: cover;
    margin-bottom: 10px;
`;

const ModalContent = styled(FlexGrid)`
    border-bottom: 1px solid ${COLORS.lightGray};
`;

const Action = styled(FlexGrid)`
    border-top: 1px solid ${COLORS.lightGray};
    width: 100%;
    justify-content: space-between;
    padding: 1em;
    margin-top: 2em;
    align-items: center;
`;

interface Props {
    mode?: 'Create' | 'Edit' | 'View';
    stepNavigator?: any;
    bannerState: [BannerType, React.Dispatch<React.SetStateAction<BannerType>>];
    dropdownPopulate?: DropDownPopulate;
}

export const Review: React.FC<Props> = ({
    mode,
    stepNavigator,
    bannerState,
    dropdownPopulate,
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [previewModalOpen, setPreviewModalOpen] = useState(false);
    const [submitModalOpen, setSubmitModalOpen] = useState(false);
    const [banner] = bannerState;
    const [disableSubmit, setDisableSubmit] = useState(false);
    const { identifierDefinition, content, targetGeography, targetUser } =
        banner;

    const location = useLocation() as any;

    const [sectorMapping, setSectorMapping] = useState(
        new Map<string, LookupValue>()
    );
    const [subSectorsMapping, setSubSectorsMapping] = useState(
        new Map<string, LookupValue>()
    );

    useEffect(() => {
        if (dropdownPopulate.sectors && dropdownPopulate.subSectors) {
            setSectorMapping(
                new Map(dropdownPopulate.sectors.map((elem) => [elem.id, elem]))
            );
            setSubSectorsMapping(
                new Map(
                    // @ts-ignore
                    Object.keys(dropdownPopulate.subSectors)
                        .map((sectorId) => {
                            return dropdownPopulate.subSectors[sectorId].map(
                                (elem) => [elem.id, elem]
                            );
                        })
                        .reduce((pre, cur) => pre.concat(cur), [])
                )
            );
        }
    }, [dropdownPopulate]);

    const createBanner = () => {
        setDisableSubmit(true);
        const isUpdate = mode === 'Edit';
        CreationService.createUpdateBanner(dispatch, isUpdate, true, banner)
            .then((result) => {
                setDisableSubmit(false);
                banner.raw = result;
                setSubmitModalOpen(true);
            })
            .catch((e) => {
                setDisableSubmit(false);
                dispatch(
                    updateModalAction({
                        open: true,
                        title: 'error',
                        content: `Failed to ${isUpdate ? 'update' : 'create'} banner. ${e}. ${e?.config?.url ? `url: ${e?.config?.url}` : ''}`,
                    })
                );
            });
    };

    return (
        <FlexGrid width="100%" direction="column">
            {content.image && (
                <Modal
                    header="Image Preview"
                    open={previewModalOpen}
                    onClose={() => setPreviewModalOpen(false)}
                    actions={
                        <Button
                            onClick={() => setPreviewModalOpen(false)}
                            p="1em 1.8em"
                        >
                            Done
                        </Button>
                    }
                    content={
                        <ModalContent
                            width="700px"
                            direction="column"
                            p="1em 0em"
                        >
                            <PreviewImg
                                src={content.image.preview}
                                alt="preview"
                            />
                            <FlexGrid gap="0.5em" p="0.5em 0em">
                                <ImageIcon />
                                <FlexGrid direction="column">
                                    <Text color={COLORS.green}>
                                        {content.image.name}
                                    </Text>
                                    <Text color={COLORS.darkGray}>
                                        {(content.image.size / 1e3).toFixed(2)}{' '}
                                        kb
                                    </Text>
                                </FlexGrid>
                            </FlexGrid>
                        </ModalContent>
                    }
                />
            )}

            <SuccessModal
                header={mode === 'Edit' ? 'Edit Banner' : 'Create New Banner'}
                open={submitModalOpen}
                onClose={() => {
                    setSubmitModalOpen(false);
                    navigate(getUrlKeepQuery('/communication/banners'), {
                        state: { page: location.state?.page },
                    });
                }}
                body={
                    mode === 'Edit'
                        ? 'Banner has been updated successfully.'
                        : 'New banners have been submitted successfully.'
                }
            />
            <PageHeader
                title={
                    <Text font="OS" fontSize="1.375rem" uppercase>
                        Review
                    </Text>
                }
            />
            <Form direction="column" p="0.5em" width="100%">
                <Accordion
                    info={{
                        title: 'Show or Hide Details',
                        content:
                            'Click to show or hide in each section. And you can edit each section by clicking edit. Submit if all data is correct.',
                    }}
                    title={
                        <FlexGrid
                            flex="1"
                            justifyContent="space-between"
                            alignItems="center"
                            m="0 0.5em 0 0"
                        >
                            <Text font="OS Bold" uppercase>
                                Identifier Definition
                            </Text>
                            {mode !== 'View' && (
                                <EditBtn
                                    m="0"
                                    p="0"
                                    negative
                                    onClick={() => {
                                        stepNavigator(0);
                                    }}
                                >
                                    Edit
                                </EditBtn>
                            )}
                        </FlexGrid>
                    }
                    content={
                        <FlexGrid width="100%" wrap="wrap">
                            <Value>
                                <Label>Banner Name</Label>
                                <Text>{identifierDefinition.name}</Text>
                            </Value>
                            <Value>
                                <Label>App Language</Label>
                                <Text>
                                    {identifierDefinition.appLanguages.join(
                                        ', '
                                    )}
                                </Text>
                            </Value>
                            <Value>
                                <Label>Start Date</Label>
                                <FlexGrid
                                    alignItems="center"
                                    width="100%"
                                    gap="0.5em"
                                >
                                    <DateIcon />
                                    <Text>
                                        {identifierDefinition.startDate}
                                    </Text>
                                </FlexGrid>
                            </Value>
                            <Value>
                                <Label>Start Time</Label>
                                <FlexGrid
                                    alignItems="center"
                                    width="100%"
                                    gap="0.5em"
                                >
                                    <TimeIcon />
                                    <Text>
                                        {identifierDefinition.startTime}
                                    </Text>
                                </FlexGrid>
                            </Value>
                            <Value>
                                <Label>End Date</Label>
                                <FlexGrid
                                    alignItems="center"
                                    width="100%"
                                    gap="0.5em"
                                >
                                    <DateIcon />
                                    <Text>{identifierDefinition.endDate}</Text>
                                </FlexGrid>
                            </Value>
                            <Value>
                                <Label>End Time</Label>
                                <FlexGrid
                                    alignItems="center"
                                    width="100%"
                                    gap="0.5em"
                                >
                                    <TimeIcon />
                                    <Text>{identifierDefinition.endTime}</Text>
                                </FlexGrid>
                            </Value>
                        </FlexGrid>
                    }
                />
                <Accordion
                    defaultOpen={false}
                    info={{
                        title: 'Show or Hide Details',
                        content:
                            'Click to show or hide in each section. And you can edit each section by clicking edit. Submit if all data is correct.',
                    }}
                    title={
                        <FlexGrid
                            flex="1"
                            justifyContent="space-between"
                            alignItems="center"
                            m="0 0.5em 0 0"
                        >
                            <Text font="OS Bold" uppercase>
                                Content
                            </Text>
                            {mode !== 'View' && (
                                <EditBtn
                                    m="0"
                                    p="0"
                                    negative
                                    onClick={() => {
                                        stepNavigator(1);
                                    }}
                                >
                                    Edit
                                </EditBtn>
                            )}
                        </FlexGrid>
                    }
                    content={
                        <>
                            <FlexGrid width="100%" wrap="wrap">
                                <Value>
                                    <Label>Image</Label>
                                    {content.image && (
                                        <>
                                            <Text>
                                                {content.image.name}{' '}
                                                <Text
                                                    onClick={() =>
                                                        setPreviewModalOpen(
                                                            true
                                                        )
                                                    }
                                                    underline
                                                    link
                                                >
                                                    View
                                                </Text>
                                            </Text>
                                            <Label>
                                                {(
                                                    content.image.size / 1e3
                                                ).toFixed(2)}{' '}
                                                kb
                                            </Label>
                                        </>
                                    )}
                                </Value>
                                <Value>
                                    <Label>Image Tags</Label>
                                    <FlexGrid gap="0.5em" wrap="wrap">
                                        {content.tags.map((tag, index) => (
                                            <Tag
                                                key={`bannerTag-${index}`}
                                                title={tag}
                                            />
                                        ))}
                                    </FlexGrid>
                                </Value>
                                <Value>
                                    <Label>Link</Label>
                                    <Text>
                                        {content.isLinkInternal
                                            ? 'INTERNAL'
                                            : content.isLinkInternal === false
                                            ? 'EXTERNAL'
                                            : ''}
                                    </Text>
                                </Value>
                            </FlexGrid>
                            {identifierDefinition.appLanguages.map(
                                (language) => (
                                    <>
                                        <div
                                            style={{
                                                width: '100%',
                                                borderBottom: `1px solid ${COLORS.gray}`,
                                            }}
                                        >
                                            <Text
                                                key={`video-text-${language}`}
                                                m="0.5em 0 0 1.1em"
                                                font="OS"
                                                fontSize="1.1em"
                                                fontWeight="10"
                                            >
                                                {language}
                                            </Text>
                                        </div>
                                        <FlexGrid
                                            key={`banner-flexgrid-${language}`}
                                            width="100%"
                                            wrap="wrap"
                                        >
                                            <Value>
                                                <Label>Headline</Label>
                                                <Text>
                                                    {
                                                        content.headline[
                                                            language ||
                                                                'English'
                                                        ]
                                                    }
                                                </Text>
                                            </Value>
                                            <Value>
                                                <Label>Description</Label>
                                                <Text>
                                                    {
                                                        content.description[
                                                            language ||
                                                                'English'
                                                        ]
                                                    }
                                                </Text>
                                            </Value>
                                            <Value>
                                                <Label>Text to Display</Label>
                                                <Text>
                                                    {
                                                        content.actionText[
                                                            language ||
                                                                'English'
                                                        ]
                                                    }
                                                </Text>
                                            </Value>
                                            {content.linkSource && (
                                                <Value>
                                                    <Label>Link Source</Label>
                                                    <Text color={COLORS.green}>
                                                        {content.isLinkInternal
                                                            ? INTERNAL_PAGE_LINK_REVERSED[
                                                                  content
                                                                      .linkSource[
                                                                      language ||
                                                                          'English'
                                                                  ]
                                                              ] ||
                                                              content
                                                                  .linkSource[
                                                                  language ||
                                                                      'English'
                                                              ]
                                                            : content
                                                                  .linkSource[
                                                                  language ||
                                                                      'English'
                                                              ]}
                                                    </Text>
                                                </Value>
                                            )}
                                        </FlexGrid>
                                    </>
                                )
                            )}
                        </>
                    }
                />
                <Accordion
                    defaultOpen={false}
                    info={{
                        title: 'Show or Hide Details',
                        content:
                            'Click to show or hide in each section. And you can edit each section by clicking edit. Submit if all data is correct.',
                    }}
                    title={
                        <FlexGrid
                            flex="1"
                            justifyContent="space-between"
                            alignItems="center"
                            m="0 0.5em 0 0"
                        >
                            <Text font="OS Bold" uppercase>
                                Target Geography
                            </Text>
                            {mode !== 'View' && (
                                <EditBtn
                                    m="0"
                                    p="0"
                                    negative
                                    onClick={() => {
                                        stepNavigator(2);
                                    }}
                                >
                                    Edit
                                </EditBtn>
                            )}
                        </FlexGrid>
                    }
                    content={
                        <FlexGrid width="100%" wrap="wrap">
                            <Value>
                                {targetGeography.regions.map(
                                    (region, index) => (
                                        <Text
                                            key={`bannerTargetGeography-${index}`}
                                        >
                                            {region}
                                        </Text>
                                    )
                                )}
                            </Value>
                            <Value>
                                {targetGeography.states.map((state, index) => (
                                    <Text
                                        key={`bannerTargetGeography-${index}`}
                                    >
                                        {state}
                                    </Text>
                                ))}
                            </Value>
                            <Value>
                                <Label>
                                    Distributors (
                                    {targetGeography.distributors.length})
                                </Label>
                                {targetGeography.distributors.map(
                                    (distributor, index) => (
                                        <Text
                                            key={`bannerTargetDistributor-${index}`}
                                        >
                                            {distributor}
                                        </Text>
                                    )
                                )}
                            </Value>
                            <Value>
                                <Label>District</Label>
                                {targetGeography.districts.map(
                                    (district, index) => (
                                        <Text
                                            key={`bannerTargetDistrict-${index}`}
                                        >
                                            {district}
                                        </Text>
                                    )
                                )}
                            </Value>
                            <Value>
                                <Label>Pincode</Label>
                                <Text>{targetGeography.pincode}</Text>
                            </Value>
                            <Value>
                                <Label>RM Area</Label>
                                {targetGeography.rmAreas.map(
                                    (rmArea, index) => (
                                        <Text key={`bannerRmAreas-${index}`}>
                                            {rmArea}
                                        </Text>
                                    )
                                )}
                            </Value>
                        </FlexGrid>
                    }
                />
                <Accordion
                    defaultOpen={false}
                    info={{
                        title: 'Show or Hide Details',
                        content:
                            'Click to show or hide in each section. And you can edit each section by clicking edit. Submit if all data is correct.',
                    }}
                    title={
                        <FlexGrid
                            flex="1"
                            justifyContent="space-between"
                            alignItems="center"
                            m="0 0.5em 0 0"
                        >
                            <Text font="OS Bold" uppercase>
                                Target User
                            </Text>
                            {mode !== 'View' && (
                                <EditBtn
                                    m="0"
                                    p="0"
                                    negative
                                    onClick={() => {
                                        stepNavigator(3);
                                    }}
                                >
                                    Edit
                                </EditBtn>
                            )}
                        </FlexGrid>
                    }
                    content={
                        <FlexGrid width="100%" wrap="wrap">
                            <Value>
                                <Label>User Type</Label>
                                <Text>{targetUser.userTypes.join(', ')}</Text>
                            </Value>
                            {targetUser.userTypes.includes('Mechanics') && (
                                <>
                                    <Value>
                                        <Label>Space of Mechanic</Label>
                                        <Text>
                                            {targetUser.spaceOfMechanics.join(
                                                ', '
                                            )}
                                        </Text>
                                    </Value>
                                    <Value>
                                        <Label>Connekt</Label>
                                        <Text>
                                            {targetUser.connekt ? 'Yes' : 'No'}
                                        </Text>
                                    </Value>
                                </>
                            )}
                            {/* <Value>
                                <Label>Upload Mobile</Label>
                                <Text color={COLORS.green}>
                                    {targetUser.mobile}
                                </Text>
                            </Value>
                            <Value>
                                <Label>Upload PID</Label>
                                <Text color={COLORS.green}>
                                    {targetUser.PID}
                                </Text>
                            </Value> */}
                            <Value>
                                <Label>Dealer/WS Segment</Label>
                                {targetUser.dealerSegments.map(
                                    (segmentId, index) => (
                                        <Text
                                            key={`bannerDealerSegments-${index}`}
                                        >
                                            {sectorMapping.get(segmentId)
                                                ?.name ?? segmentId}
                                        </Text>
                                    )
                                )}
                            </Value>
                            <Value>
                                <Label>
                                    Dealer/WS Sub Segment (
                                    {targetUser.dealerSubSegments.length})
                                </Label>
                                {targetUser.dealerSubSegments.map(
                                    (segmentId, index) => (
                                        <Text
                                            key={`bannerDealerSubSegments-${index}`}
                                        >
                                            {subSectorsMapping.get(segmentId)
                                                ?.name ?? segmentId}
                                        </Text>
                                    )
                                )}
                            </Value>
                            <Value>
                                <Label>Mechanics Segment</Label>
                                {targetUser.mechanicsSegments.map(
                                    (segmentId, index) => (
                                        <Text
                                            key={`articleMechanicsSegments-${index}`}
                                        >
                                            {sectorMapping.get(segmentId)
                                                ?.name ?? segmentId}
                                        </Text>
                                    )
                                )}
                            </Value>
                            <Value>
                                <Label>
                                    Mechanics Sub Segment (
                                    {targetUser.mechanicsSubSegments.length})
                                </Label>
                                {targetUser.mechanicsSubSegments.map(
                                    (segmentId, index) => (
                                        <Text
                                            key={`articleMechanicsSubSegments-${index}`}
                                        >
                                            {subSectorsMapping.get(segmentId)
                                                ?.name ?? segmentId}
                                        </Text>
                                    )
                                )}
                            </Value>
                        </FlexGrid>
                    }
                />
                <Action>
                    <Button
                        negative
                        icon="back"
                        title="Back"
                        onClick={() => {
                            if (mode === 'View') {
                                navigate(-1);
                            } else {
                                stepNavigator((prev: number) => prev - 1);
                            }
                        }}
                    />
                    {mode !== 'View' && (
                        <Button
                            onClick={() => createBanner()}
                            p="1em 2em"
                            title="Submit"
                            disabled={disableSubmit}
                        />
                    )}
                </Action>
            </Form>
        </FlexGrid>
    );
};
