/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { useLocation, useNavigate } from 'react-router-dom';
import { Accordion } from '../../../components/Accordion';
import { Button } from '../../../components/Button';
import { FlexGrid } from '../../../components/FlexGrid';
import { PageHeader } from '../../../components/PageHeader';
import { Text } from '../../../components/Text';
import { COLORS } from '../../../styles/variables';
import {
    DateIcon,
    TimeIcon,
    CopyIcon,
    PlaySquareIcon,
    PointsIcon,
    QuizCorrectOptionIcon,
    BonusPointsIcon,
} from '../../../assets/icons';
import { Tag } from '../../../components/Tag';
import { Modal } from '../../../components/Modal';
import { IconButton } from '../../../components/IconButton';
import { LookupValue, VideoType } from '../../../types';
import { notificationIcons } from './SendNotification';
import { SuccessModal } from '../../../components/SuccessModal';
import { HorizontalLine } from '../../../components/HorizontalLine';
// import { ContentfulApiService } from '../../../services/ContentfulApi.service';
// import { useVideoPlaylistsHook } from '../hooks/use-videoPlaylist-hook';
// import { DEFAULT_LANGUAGE_CODE } from '../../../common/constants';
import _ from 'lodash';
// import moment from 'moment';
import { getUrlKeepQuery, readMediaBuffer } from '../../../utils';
// import { ContentfulTransformService } from '../../../services/ContentfulTransform.service';
import * as uuid from 'uuid';
import { CreationService } from '../../../services/Creation.service';
import { DropDownPopulate } from '../../../services/Api.service';
import { SectionHeader } from '../../../components/SectionHeader';
import { useDispatch } from 'react-redux';
import { updateModalAction } from '../../../store/store.actions';

const EditBtn = styled(Button)`
    font-size: 15px;
    font-family: 'AR';
`;

const Label = styled(Text)`
    color: ${COLORS.darkGray};
    font-size: 0.75rem;
`;

const Value = styled(FlexGrid)`
    width: 50%;
    flex-direction: column;
    gap: 0.5em;
    padding: 1em 2em;
    min-width: 0;
`;

const Form = styled(FlexGrid)`
    max-width: 700px;
    margin-top: 0.5em;
`;

const VideoPlayer = styled(FlexGrid)`
    position: relative;
    width: 100%;
    overflow: hidden;
`;

const ModalContent = styled(FlexGrid)`
    gap: 1em;
`;

const Action = styled(FlexGrid)`
    border-top: 1px solid ${COLORS.lightGray};
    width: 100%;
    justify-content: space-between;
    padding: 1em;
    margin-top: 2em;
    align-items: center;
`;

const URL = styled(Text)`
    word-break: break-all;
    max-width: 70%;
`;

interface Props {
    mode?: 'Create' | 'Edit' | 'View';
    stepNavigator: any;
    videoState: [VideoType, React.Dispatch<React.SetStateAction<VideoType>>];
    dropdownPopulate?: DropDownPopulate;
}

export const Review: React.FC<Props> = ({
    mode,
    stepNavigator,
    videoState,
    dropdownPopulate,
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // const [{ hookData: plalists }] = useVideoPlaylistsHook();

    const [previewModalOpen, setPreviewModalOpen] = useState(false);
    const [submitModalOpen, setSubmitModalOpen] = useState(false);
    // const [videoDuration, setVideoDuration] = useState(null);
    // const [firstFrame, setFirstFrame] = useState(null);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [video] = videoState;
    const {
        identifierDefinition,
        content,
        targetGeography,
        targetUser,
        sendNotification,
        quiz,
    } = video;

    const location = useLocation() as any;

    const [sectorMapping, setSectorMapping] = useState(
        new Map<string, LookupValue>()
    );
    const [subSectorsMapping, setSubSectorsMapping] = useState(
        new Map<string, LookupValue>()
    );

    const isInAppNotification = sendNotification.notificationType.includes(
        'In-App Notifications'
    );

    const isPushNotification =
        sendNotification.notificationType.includes('Push notifications');

    useEffect(() => {
        if (dropdownPopulate.sectors && dropdownPopulate.subSectors) {
            setSectorMapping(
                new Map(dropdownPopulate.sectors.map((elem) => [elem.id, elem]))
            );
            setSubSectorsMapping(
                new Map(
                    // @ts-ignore
                    Object.keys(dropdownPopulate.subSectors)
                        .map((sectorId) => {
                            return dropdownPopulate.subSectors[sectorId].map(
                                (elem) => [elem.id, elem]
                            );
                        })
                        .reduce((pre, cur) => pre.concat(cur), [])
                )
            );
        }
    }, [dropdownPopulate]);

    const createVideo = () => {
        if (content.videoDuration === null) {
            console.log('fetch video duration  error, please try again');
            return;
        }
        // if (content.firstFrame === null) {
        //     console.log('fetch video first frame  error');
        //     if (!content.isFromURL) {
        //         return;
        //     }
        // }
        setDisableSubmit(true);
        const isUpdate = mode === 'Edit';
        console.log('video to create', isUpdate, video);

        CreationService.createUpdateVideo(dispatch, isUpdate, true, video)
            .then((result) => {
                video.raw = result;
                setSubmitModalOpen(true);
                setDisableSubmit(false);
            })
            .catch((e) => {
                console.error('===Failed to create update video===', e);
                setDisableSubmit(false);
                dispatch(
                    updateModalAction({
                        open: true,
                        title: 'error',
                        content: `Failed to ${isUpdate ? 'update' : 'create'} video. ${e}. ${e?.config?.url ? `url: ${e?.config?.url}` : ''}`,
                    })
                );
            });
    };

    useEffect(() => {
        const videoEl = document.createElement('video');
        let videoDuration = 0;
        let firstFrame = null;

        // document.body.appendChild(videoEl)
        videoEl.setAttribute(
            'src',
            video.content.isFromURL
                ? video.content.source
                : video.content.source.preview
        );
        videoEl.setAttribute('preload', 'auto');
        videoEl.setAttribute('autoplay', 'true');
        videoEl.setAttribute('controls', 'true');
        videoEl.oncanplay = (event) => {
            console.log('canplay', videoEl.duration);
            videoDuration = videoEl.duration;
            videoState[1]({
                ...video,
                content: {
                    ...video.content,
                    firstFrame,
                    videoDuration,
                },
            });
        };
        videoEl.addEventListener('seeked', () => {
            console.log('onseeked');
            const canvas = document.createElement('canvas');
            const canvasCtx = canvas.getContext('2d');
            canvas.width = videoEl.videoWidth;
            canvas.height = videoEl.videoHeight;
            // document.body.appendChild(canvas)
            canvasCtx.drawImage(
                videoEl,
                0,
                0,
                videoEl.videoWidth,
                videoEl.videoHeight
            );

            canvas.toBlob((b) => {
                const fl = new File([b], uuid.v4(), {
                    type: 'image/png',
                });
                console.log('image file', fl);
                firstFrame = fl;
                videoState[1]({
                    ...video,
                    content: {
                        ...video.content,
                        firstFrame,
                        videoDuration,
                    },
                });
            }, 'image/png');

            // download test
            // const a = document.createElement("a");
            // a.setAttribute("download", "test.image");
            // a.setAttribute("href", canvas.toDataURL());
            // console.log("canvas.toDataURL()", canvas.width, canvas.height, canvas.toDataURL())
            // document.body.appendChild(a);
            // a.click();
            // document.body.removeChild(a);
        });
        videoEl.ontimeupdate = () => {
            console.log('timeupdate');
            videoEl.pause();
            videoEl.ontimeupdate = () => {};
            videoEl.currentTime = 0;
        };
    }, [video.content.source]);

    console.log('video duration', video.content);

    return (
        <FlexGrid width="100%" direction="column">
            {content.source && (
                <Modal
                    header="Video Preview"
                    open={previewModalOpen}
                    onClose={() => setPreviewModalOpen(false)}
                    actions={
                        <Button
                            onClick={() => setPreviewModalOpen(false)}
                            p="1em 2.5em"
                        >
                            Done
                        </Button>
                    }
                    content={
                        <ModalContent
                            width="770px"
                            direction="column"
                            p="1em 0em 0.5em 0em"
                        >
                            <VideoPlayer>
                                <ReactPlayer
                                    controls
                                    width="100%"
                                    height="400px"
                                    url={
                                        content.source?.preview ||
                                        content.source
                                    }
                                />
                            </VideoPlayer>
                            {!content.isFromURL && (
                                <FlexGrid gap="0.5em" p="0.5em 0em">
                                    <PlaySquareIcon />
                                    <FlexGrid direction="column">
                                        <Text color={COLORS.green}>
                                            {content.source.name}
                                        </Text>
                                        <Text color={COLORS.darkGray}>
                                            {(
                                                content.source.size / 1e6
                                            ).toFixed(2)}{' '}
                                            mb
                                        </Text>
                                    </FlexGrid>
                                </FlexGrid>
                            )}
                            <HorizontalLine
                                height="1px"
                                backgroundColor={COLORS.lightGray}
                                m="0"
                            />
                        </ModalContent>
                    }
                />
            )}

            <SuccessModal
                header={mode === 'Edit' ? 'Edit Video' : 'Add New Video'}
                open={submitModalOpen}
                onClose={() => {
                    setSubmitModalOpen(false);
                    navigate(getUrlKeepQuery('/communication/videos'), {
                        state: { page: location.state?.page },
                    });
                }}
                body={
                    mode === 'Edit'
                        ? 'Video has been updated successfully.'
                        : 'New videos have been added successfully.'
                }
            />
            <PageHeader
                title={
                    <Text font="OS" fontSize="1.375rem" uppercase>
                        Review
                    </Text>
                }
            />
            <Form direction="column" p="0.5em" width="100%">
                <Accordion
                    info={{
                        title: 'Show or Hide Details',
                        content:
                            'Click to show or hide in each section. And you can edit each section by clicking edit. Submit if all data is correct.',
                    }}
                    title={
                        <FlexGrid
                            flex="1"
                            justifyContent="space-between"
                            alignItems="center"
                            m="0 0.5em 0 0"
                        >
                            <Text font="OS Bold" uppercase>
                                Identifier Definition
                            </Text>
                            {mode !== 'View' && (
                                <EditBtn
                                    onClick={() => stepNavigator(0)}
                                    m="0"
                                    p="0"
                                    negative
                                >
                                    Edit
                                </EditBtn>
                            )}
                        </FlexGrid>
                    }
                    content={
                        <FlexGrid width="100%" wrap="wrap">
                            <Value>
                                <Label>Video Name</Label>
                                <Text>{identifierDefinition.name}</Text>
                            </Value>
                            <Value>
                                <Label>App Language</Label>
                                <Text>
                                    {identifierDefinition.appLanguages.join(
                                        ', '
                                    )}
                                </Text>
                            </Value>
                            <Value>
                                <Label>Start Date</Label>
                                <FlexGrid
                                    alignItems="center"
                                    width="100%"
                                    gap="0.5em"
                                >
                                    <DateIcon />
                                    <Text>
                                        {identifierDefinition.startDate}
                                    </Text>
                                </FlexGrid>
                            </Value>
                            <Value>
                                <Label>Start Time</Label>
                                <FlexGrid
                                    alignItems="center"
                                    width="100%"
                                    gap="0.5em"
                                >
                                    <TimeIcon />
                                    <Text>
                                        {identifierDefinition.startTime}
                                    </Text>
                                </FlexGrid>
                            </Value>
                            <Value>
                                <Label>End Date</Label>
                                <FlexGrid
                                    alignItems="center"
                                    width="100%"
                                    gap="0.5em"
                                >
                                    <DateIcon />
                                    <Text>{identifierDefinition.endDate}</Text>
                                </FlexGrid>
                            </Value>
                            <Value>
                                <Label>End Time</Label>
                                <FlexGrid
                                    alignItems="center"
                                    width="100%"
                                    gap="0.5em"
                                >
                                    <TimeIcon />
                                    <Text>{identifierDefinition.endTime}</Text>
                                </FlexGrid>
                            </Value>
                        </FlexGrid>
                    }
                />
                <Accordion
                    defaultOpen={false}
                    info={{
                        title: 'Show or Hide Details',
                        content:
                            'Click to show or hide in each section. And you can edit each section by clicking edit. Submit if all data is correct.',
                    }}
                    title={
                        <FlexGrid
                            flex="1"
                            justifyContent="space-between"
                            alignItems="center"
                            m="0 0.5em 0 0"
                        >
                            <Text font="OS Bold" uppercase>
                                Content
                            </Text>
                            {mode !== 'View' && (
                                <EditBtn
                                    onClick={() => stepNavigator(1)}
                                    m="0"
                                    p="0"
                                    negative
                                >
                                    Edit
                                </EditBtn>
                            )}
                        </FlexGrid>
                    }
                    content={
                        <>
                            <FlexGrid width="100%" wrap="wrap">
                                <Value>
                                    <Label>App Language</Label>
                                    <Text>
                                        {identifierDefinition.appLanguages.join(
                                            ', '
                                        )}
                                    </Text>
                                </Value>
                                <Value>
                                    <Label>Featured</Label>
                                    <Text>
                                        {content.isFeatured ? 'Yes' : 'No'}
                                    </Text>
                                </Value>
                                {content.isFromURL ? (
                                    <Value>
                                        <Label>Video</Label>
                                        {content.source && (
                                            <Text
                                                color={COLORS.green}
                                                onClick={() =>
                                                    setPreviewModalOpen(true)
                                                }
                                                underline
                                                link
                                            >
                                                View
                                            </Text>
                                        )}
                                    </Value>
                                ) : (
                                    <Value>
                                        <Label>File Name</Label>
                                        {content.source && (
                                            <Text color={COLORS.green}>
                                                {content.source.name}{' '}
                                                <Text
                                                    onClick={() =>
                                                        setPreviewModalOpen(
                                                            true
                                                        )
                                                    }
                                                    underline
                                                    link
                                                >
                                                    View
                                                </Text>
                                            </Text>
                                        )}
                                    </Value>
                                )}

                                <Value>
                                    <Label>Playlists</Label>
                                    <Text>
                                        {content.playlists
                                            .map((p) => p.title)
                                            .join(', ')}
                                    </Text>
                                </Value>
                                <Value>
                                    <Label>Tags</Label>
                                    <FlexGrid gap="0.5em" wrap="wrap">
                                        {content.tags.map((tag, index) => (
                                            <Tag
                                                key={`videoTags-${index}`}
                                                title={tag}
                                            />
                                        ))}
                                    </FlexGrid>
                                </Value>
                                {content.isFromURL && (
                                    <Value>
                                        <Label>Link Video</Label>
                                        <FlexGrid gap="1em" alignItems="center">
                                            <URL color={COLORS.green}>
                                                {content.source}
                                            </URL>
                                            <IconButton
                                                negative
                                                icon={<CopyIcon height="24" />}
                                                onClick={() => {
                                                    navigator.clipboard.writeText(
                                                        content.source
                                                    );
                                                }}
                                            />
                                        </FlexGrid>
                                    </Value>
                                )}
                            </FlexGrid>
                            {identifierDefinition.appLanguages.map(
                                (language) => (
                                    <>
                                        <div
                                            style={{
                                                width: '100%',
                                                borderBottom: `1px solid ${COLORS.gray}`,
                                            }}
                                        >
                                            <Text
                                                key={`video-text-${language}`}
                                                m="0.5em 0 0 1.1em"
                                                font="OS"
                                                fontSize="1.1em"
                                                fontWeight="10"
                                            >
                                                {language}
                                            </Text>
                                        </div>
                                        <FlexGrid
                                            key={`video-flexgrid-${language}`}
                                            width="100%"
                                            wrap="wrap"
                                        >
                                            <Value>
                                                <Label>Video Title</Label>
                                                <Text>
                                                    {
                                                        content.title[
                                                            language ||
                                                                'English'
                                                        ]
                                                    }
                                                </Text>
                                            </Value>
                                            <Value>
                                                <Label>Video Summary</Label>
                                                <Text>
                                                    {
                                                        content.summary[
                                                            language ||
                                                                'English'
                                                        ]
                                                    }
                                                </Text>
                                            </Value>
                                            <Value>
                                                <Label>Description</Label>
                                                <Text>
                                                    {
                                                        content.description[
                                                            language ||
                                                                'English'
                                                        ]
                                                    }
                                                </Text>
                                            </Value>
                                        </FlexGrid>
                                    </>
                                )
                            )}
                        </>
                    }
                />
                <Accordion
                    defaultOpen={false}
                    info={{
                        title: 'Show or Hide Details',
                        content:
                            'Click to show or hide in each section. And you can edit each section by clicking edit. Submit if all data is correct.',
                    }}
                    title={
                        <FlexGrid
                            flex="1"
                            justifyContent="space-between"
                            alignItems="center"
                            m="0 0.5em 0 0"
                        >
                            <Text font="OS Bold" uppercase>
                                Target Geography
                            </Text>
                            {mode !== 'View' && (
                                <EditBtn
                                    onClick={() => stepNavigator(2)}
                                    m="0"
                                    p="0"
                                    negative
                                >
                                    Edit
                                </EditBtn>
                            )}
                        </FlexGrid>
                    }
                    content={
                        <FlexGrid width="100%" wrap="wrap">
                            <Value>
                                <Label>Region</Label>
                                {targetGeography.regions.map(
                                    (region, index) => (
                                        <Text
                                            key={`videoTargetGeography-${index}`}
                                        >
                                            {region}
                                        </Text>
                                    )
                                )}
                            </Value>
                            <Value>
                                <Label>State/Provinces</Label>
                                {targetGeography.states.map((state, index) => (
                                    <Text key={`videoTargetStates-${index}`}>
                                        {state}
                                    </Text>
                                ))}
                            </Value>
                            <Value>
                                <Label>
                                    Distributors (
                                    {targetGeography.distributors.length})
                                </Label>
                                {targetGeography.distributors.map(
                                    (distributor, index) => (
                                        <Text
                                            key={`videoTargetStates-${index}`}
                                        >
                                            {distributor}
                                        </Text>
                                    )
                                )}
                            </Value>
                            <Value>
                                <Label>District</Label>
                                {targetGeography.districts.map(
                                    (district, index) => (
                                        <Text key={`videoDistricts-${index}`}>
                                            {district}
                                        </Text>
                                    )
                                )}
                            </Value>
                            <Value>
                                <Label>Pincode</Label>
                                <Text>{targetGeography.pincode}</Text>
                            </Value>
                            <Value>
                                <Label>
                                    RM Area ({targetGeography.rmAreas.length})
                                </Label>
                                {targetGeography.rmAreas.map(
                                    (rmArea, index) => (
                                        <Text key={`videoRmAreas-${index}`}>
                                            {rmArea}
                                        </Text>
                                    )
                                )}
                            </Value>
                        </FlexGrid>
                    }
                />
                <Accordion
                    defaultOpen={false}
                    info={{
                        title: 'Show or Hide Details',
                        content:
                            'Click to show or hide in each section. And you can edit each section by clicking edit. Submit if all data is correct.',
                    }}
                    title={
                        <FlexGrid
                            flex="1"
                            justifyContent="space-between"
                            alignItems="center"
                            m="0 0.5em 0 0"
                        >
                            <Text font="OS Bold" uppercase>
                                Target User
                            </Text>
                            {mode !== 'View' && (
                                <EditBtn
                                    onClick={() => stepNavigator(3)}
                                    m="0"
                                    p="0"
                                    negative
                                >
                                    Edit
                                </EditBtn>
                            )}
                        </FlexGrid>
                    }
                    content={
                        <FlexGrid width="100%" wrap="wrap">
                            <Value>
                                <Label>User Type</Label>
                                <Text>{targetUser.userTypes.join(', ')}</Text>
                            </Value>
                            {targetUser.userTypes.includes('Mechanics') && (
                                <>
                                    <Value>
                                        <Label>Space of Mechanic</Label>
                                        <Text>
                                            {targetUser.spaceOfMechanics.join(
                                                ', '
                                            )}
                                        </Text>
                                    </Value>
                                    {/* <Value>
                                        <Label>Connekt</Label>
                                        <Text>
                                            {targetUser.connekt ? 'Yes' : 'No'}
                                        </Text>
                                    </Value> */}
                                </>
                            )}
                            {/* <Value>
                                <Label>Upload Mobile</Label>
                                <Text color={COLORS.green}>
                                    {targetUser.mobile}
                                </Text>
                            </Value>
                            <Value>
                                <Label>Upload PID</Label>
                                <Text color={COLORS.green}>
                                    {targetUser.PID}
                                </Text>
                            </Value> */}
                            <Value>
                                <Label>Dealer/WS Segment</Label>
                                {targetUser.dealerSegments.map(
                                    (segmentId, index) => (
                                        <Text
                                            key={`videoDealerSegments-${index}`}
                                        >
                                            {sectorMapping.get(segmentId)
                                                ?.name ?? segmentId}
                                        </Text>
                                    )
                                )}
                            </Value>
                            <Value>
                                <Label>
                                    Dealer/WS Sub Segment (
                                    {targetUser.dealerSubSegments.length})
                                </Label>
                                {targetUser.dealerSubSegments.map(
                                    (segmentId, index) => (
                                        <Text
                                            key={`videoDealerSubSegments-${index}`}
                                        >
                                            {subSectorsMapping.get(segmentId)
                                                ?.name ?? segmentId}
                                        </Text>
                                    )
                                )}
                            </Value>
                            <Value>
                                <Label>Mechanics Segment</Label>
                                {targetUser.mechanicsSegments.map(
                                    (segmentId, index) => (
                                        <Text
                                            key={`videoMechanicsSegments-${index}`}
                                        >
                                            {sectorMapping.get(segmentId)
                                                ?.name ?? segmentId}
                                        </Text>
                                    )
                                )}
                            </Value>
                            <Value>
                                <Label>
                                    Mechanics Sub Segment (
                                    {targetUser.mechanicsSubSegments.length})
                                </Label>
                                {targetUser.mechanicsSubSegments.map(
                                    (segmentId, index) => (
                                        <Text
                                            key={`videoMechanicsSubSegments-${index}`}
                                        >
                                            {subSectorsMapping.get(segmentId)
                                                ?.name ?? segmentId}
                                        </Text>
                                    )
                                )}
                            </Value>
                        </FlexGrid>
                    }
                />
                {!!quiz && !quiz.markDeleted && (
                    <Accordion
                        defaultOpen={false}
                        info={{
                            title: 'Show or Hide Details',
                            content:
                                'Click to show or hide in each section. And you can edit each section by clicking edit. Submit if all data is correct.',
                        }}
                        title={
                            <FlexGrid
                                flex="1"
                                justifyContent="space-between"
                                alignItems="center"
                                m="0 0.5em 0 0"
                            >
                                <Text font="OS Bold" uppercase>
                                    Add Quiz
                                </Text>
                                {mode !== 'View' && (
                                    <EditBtn
                                        m="0"
                                        p="0"
                                        negative
                                        onClick={() => {
                                            stepNavigator(4);
                                        }}
                                    >
                                        Edit
                                    </EditBtn>
                                )}
                            </FlexGrid>
                        }
                        content={identifierDefinition.appLanguages.map(
                            (language) => (
                                <>
                                    <div
                                        style={{
                                            width: '100%',
                                            borderBottom: `1px solid ${COLORS.gray}`,
                                        }}
                                    >
                                        <Text
                                            key={`video-text-${language}`}
                                            m="0.5em 0 0 1.1em"
                                            font="OS"
                                            fontSize="1.1em"
                                            fontWeight="10"
                                        >
                                            {language}
                                        </Text>
                                    </div>
                                    <FlexGrid
                                        key={`inapp-noti-flexgrid-${language}`}
                                        width="100%"
                                        wrap="wrap"
                                        direction="column"
                                    >
                                        <div
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'row',
                                            }}
                                        >
                                            <Value>
                                                <Label>Title</Label>
                                                <Text>
                                                    {quiz.content.title[
                                                        language
                                                    ] ||
                                                        quiz.content.title[
                                                            'English'
                                                        ]}
                                                </Text>
                                            </Value>
                                            <Value>
                                                <Label>Bonus Points</Label>
                                                <FlexGrid
                                                    alignItems="center"
                                                    width="100%"
                                                    gap="0.5em"
                                                >
                                                    <BonusPointsIcon />
                                                    <Text>
                                                        {quiz.content
                                                            .bonusPoints || 0}
                                                    </Text>
                                                </FlexGrid>
                                            </Value>
                                        </div>
                                        <Value>
                                            <Label>Description</Label>
                                            <Text>
                                                {content.description[
                                                    language
                                                ] ||
                                                    content.description[
                                                        'English'
                                                    ]}
                                            </Text>
                                        </Value>
                                        {quiz.content.questions.map(
                                            (question, index) => (
                                                <Accordion
                                                    key={`quiz-question-${index}`}
                                                    defaultOpen={false}
                                                    info={{
                                                        title: 'Show or Hide Details',
                                                        content:
                                                            'Click to show or hide in each section. And you can edit each section by clicking edit. Submit if all data is correct.',
                                                    }}
                                                    title={
                                                        <SectionHeader
                                                            title={`QUESTION #${
                                                                index + 1
                                                            }`}
                                                            bg="#F5F5F5"
                                                            fontFamily="OS"
                                                            height="40px"
                                                            titleMargin="0 0 0 1em"
                                                        />
                                                    }
                                                    content={
                                                        <>
                                                            <Value>
                                                                <Label>
                                                                    Question
                                                                </Label>
                                                                <Text>
                                                                    {question
                                                                        .content
                                                                        .questionText[
                                                                        language
                                                                    ] ||
                                                                        question
                                                                            .content
                                                                            .questionText[
                                                                            'English'
                                                                        ]}
                                                                </Text>
                                                            </Value>
                                                            <Value>
                                                                <Label>
                                                                    Points
                                                                </Label>
                                                                <FlexGrid
                                                                    alignItems="center"
                                                                    width="100%"
                                                                    gap="0.5em"
                                                                >
                                                                    <PointsIcon />
                                                                    <Text>
                                                                        {question
                                                                            .content
                                                                            .points ||
                                                                            0}
                                                                    </Text>
                                                                </FlexGrid>
                                                            </Value>
                                                            {question.content.options.map(
                                                                (
                                                                    option,
                                                                    index
                                                                ) => (
                                                                    <Value>
                                                                        <div
                                                                            style={{
                                                                                width: '100%',
                                                                                display:
                                                                                    'flex',
                                                                                flexDirection:
                                                                                    'row',
                                                                                alignItems:
                                                                                    'center',
                                                                            }}
                                                                        >
                                                                            <Label>
                                                                                {`Answer ${
                                                                                    index +
                                                                                    1
                                                                                }`}
                                                                            </Label>
                                                                            {question
                                                                                .content
                                                                                .correctAnswerIndex ===
                                                                                index && (
                                                                                <QuizCorrectOptionIcon width="20" />
                                                                            )}
                                                                        </div>
                                                                        <Text>
                                                                            {option[
                                                                                language
                                                                            ] ||
                                                                                option[
                                                                                    'English'
                                                                                ]}
                                                                        </Text>
                                                                    </Value>
                                                                )
                                                            )}
                                                            <Value>
                                                                <Label>
                                                                    Reason
                                                                </Label>
                                                                <Text>
                                                                    {question
                                                                        .content
                                                                        .reason[
                                                                        language
                                                                    ] ||
                                                                        question
                                                                            .content
                                                                            .reason[
                                                                            'English'
                                                                        ]}
                                                                </Text>
                                                            </Value>
                                                        </>
                                                    }
                                                />
                                            )
                                        )}
                                    </FlexGrid>
                                </>
                            )
                        )}
                    />
                )}
                <Accordion
                    defaultOpen={false}
                    info={{
                        title: 'Show or Hide Details',
                        content:
                            'Click to show or hide in each section. And you can edit each section by clicking edit. Submit if all data is correct.',
                    }}
                    title={
                        <FlexGrid
                            flex="1"
                            justifyContent="space-between"
                            alignItems="center"
                            m="0 0.5em 0 0"
                        >
                            <Text font="OS Bold" uppercase>
                                Send Notification
                            </Text>
                            {mode !== 'View' && (
                                <EditBtn
                                    onClick={() => stepNavigator(5)}
                                    m="0"
                                    p="0"
                                    negative
                                >
                                    Edit
                                </EditBtn>
                            )}
                        </FlexGrid>
                    }
                    content={identifierDefinition.appLanguages.map(
                        (language) => (
                            <>
                                <Text
                                    key={`video-text2-${language}`}
                                    m="0.5em 0 0 1.6em"
                                >
                                    {language}
                                </Text>
                                <FlexGrid
                                    key={`video-flexgrid2-${language}`}
                                    width="100%"
                                    wrap="wrap"
                                    direction="column"
                                >
                                    {isInAppNotification && (
                                        <>
                                            <Value>
                                                <Label>
                                                    Send notification to
                                                </Label>
                                                <Text>In App Notification</Text>
                                            </Value>
                                            <Value>
                                                <Label>Icon</Label>
                                                <FlexGrid
                                                    alignItems="center"
                                                    gap="0.5em"
                                                >
                                                    {
                                                        notificationIcons[
                                                            sendNotification
                                                                .icon
                                                        ]
                                                    }
                                                    {sendNotification.icon}
                                                </FlexGrid>
                                            </Value>

                                            <Value>
                                                <Label>Body Text</Label>
                                                <Text>
                                                    <div
                                                        style={{
                                                            margin: 0,
                                                            padding: 0,
                                                        }}
                                                        dangerouslySetInnerHTML={{
                                                            __html: sendNotification
                                                                .inAppNotificationContent
                                                                .bodyText[
                                                                language ||
                                                                    'English'
                                                            ],
                                                        }}
                                                    />
                                                </Text>
                                            </Value>
                                        </>
                                    )}
                                    {isPushNotification && (
                                        <>
                                            <Value>
                                                <Label>
                                                    Send notification to
                                                </Label>
                                                <Text>Push Notification</Text>
                                            </Value>

                                            <Value>
                                                <Label>Preview Text</Label>
                                                <Text>
                                                    {
                                                        sendNotification
                                                            .pushNotificationContent
                                                            .headline[
                                                            language ||
                                                                'English'
                                                        ]
                                                    }
                                                </Text>
                                            </Value>

                                            <Value>
                                                <Label>Notification Text</Label>
                                                <Text>
                                                    <div
                                                        style={{
                                                            margin: 0,
                                                            padding: 0,
                                                        }}
                                                        dangerouslySetInnerHTML={{
                                                            __html: sendNotification
                                                                .inAppNotificationContent
                                                                .bodyText[
                                                                language ||
                                                                    'English'
                                                            ],
                                                        }}
                                                    />
                                                </Text>
                                            </Value>
                                        </>
                                    )}
                                </FlexGrid>
                            </>
                        )
                    )}
                />

                <Action>
                    <Button
                        negative
                        icon="back"
                        title="Back"
                        onClick={() => {
                            if (mode === 'View') {
                                navigate(-1);
                            } else {
                                stepNavigator((prev: number) => prev - 1);
                            }
                        }}
                    />
                    {mode !== 'View' && (
                        <Button
                            onClick={createVideo}
                            p="1em 2em"
                            title="Submit"
                            disabled={disableSubmit}
                        />
                    )}
                </Action>
            </Form>
        </FlexGrid>
    );
};
