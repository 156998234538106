import moment from 'moment';
import 'moment-timezone';
import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import {
    API_LANGUAGE_CODE_MAPPING,
    CONTENTFUL_TO_ROLE_TYPE_MAPPING,
    INTERNAL_PAGE_LINK,
} from '../common/constants';
import {
    ArticleType,
    BannerType,
    IdentifierDefinition,
    InAppNotificationType,
    LearningCardSectionType,
    LearningCardSlide,
    LearningCardThemeType,
    LearningCardType,
    PushNotificationContent,
    PushNotificationType,
    QuizQuestion,
    QuizType,
    ScanningContestScheme,
    ScanningContestType,
    RegistrationContestType,
    SendNotificationType,
    TargetGeographyType,
    TargetUserType,
    VideoType,
} from '../types';
import { getCountryTag, getDefaultTimezone, readMediaBuffer } from '../utils';
import { ApiService, PushNotificationApiPayload } from './Api.service';
import { ContentfulApiService } from './ContentfulApi.service';
import { ContentfulTransformService } from './ContentfulTransform.service';
import { updateLoadingModalAction } from '../store/store.actions';

export class CreationService {
    static async createUpdatePushNotfication(
        dispatch: Dispatch<AnyAction> | undefined,
        isUpdate: boolean,
        publish: boolean,
        uiContent: PushNotificationType
    ) {
        const { content, identifierDefinition, targetGeography, targetUser } =
            uiContent;

        const getDate = ContentfulTransformService.putDate;

        const startDate = getDate(
            identifierDefinition.startDate,
            identifierDefinition.startTime
        );
        const endDate = getDate(
            identifierDefinition.endDate,
            identifierDefinition.endTime
        );

        const { appLanguages } = identifierDefinition;

        const fields: any = {
            name: identifierDefinition.name,
            language: appLanguages,
            startDate,
            // type: content.notificationType,
            linkType: content.isLinkInternal,
            // todo: upload asset and integrate it
            region: targetGeography.regions,
            stateprovince: targetGeography.states.length
                ? targetGeography.states
                : ['UNKNOWN'],
            distributors: targetGeography.distributors.length
                ? targetGeography.distributors
                : ['UNKNOWN'],
            district: targetGeography.districts.length
                ? targetGeography.districts
                : ['UNKNOWN'],
            pincode: `${targetGeography.pincode}`,
            rmArea: targetGeography.rmAreas.length
                ? targetGeography.rmAreas
                : ['UNKNOWN'],
            userType: targetUser.userTypes,
            spaceOfMechanic: targetUser.spaceOfMechanics,
            connekt: targetUser.connekt,
            uploadMobile: targetUser.mobile,
            uploadPid: targetUser.PID,
            dealerWsSegment: targetUser.dealerSegments,
            dealerWsSubSegment: targetUser.dealerSubSegments,
            mechanicsSegment: targetUser.mechanicsSegments,
            mechanicsSubSegment: targetUser.mechanicsSubSegments,
        };

        if (identifierDefinition.endDate) {
            fields.endDate = endDate;
        } else {
            fields.endDate = undefined;
        }

        const lanFields = {
            description: content.notificationText,
            headline: content.previewText,
            TargetLink: content.targetLink,
        };

        // Open the loading modal
        const loadingModalTitle = 'Creating push notification';
        let currentProgress = 0;

        // Calculate total steps for progress
        const totalSteps = 4;
        let currentStep = 0;

        let response;
        if (isUpdate) {
            currentStep++;
            currentProgress = (currentStep / totalSteps) * 100;
            dispatch && dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: 'Updating content fields of the push notification...',
                    progress: currentProgress,
                })
            );
            response = await ContentfulApiService.updateContentFields(
                uiContent.raw.sys.id,
                uiContent.raw,
                fields,
                lanFields
            );
        } else {
            currentStep++;
            currentProgress = (currentStep / totalSteps) * 100;
            dispatch && dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: 'Creating content fields of the push notification...',
                    progress: currentProgress,
                })
            );
            response = await ContentfulApiService.createContent(
                'pushNotification',
                fields,
                lanFields
            );
        }
        if (publish) {
            try {
                currentStep++;
                currentProgress = (currentStep / totalSteps) * 100;
                dispatch && dispatch(
                    updateLoadingModalAction({
                        open: true,
                        title: loadingModalTitle,
                        content: 'Publishing the push notification...',
                        progress: currentProgress,
                    })
                );
                await ContentfulApiService.publish(response.sys.id);
                console.log('push notification published');
            } catch (e) {
                console.log('published error', e);
            }
        }

        try {
            const userTypes = targetUser.userTypes.map(
                (userType) => CONTENTFUL_TO_ROLE_TYPE_MAPPING[userType]
            );

            const dealerSegments =
                targetUser.dealerSegments &&
                targetUser.dealerSegments.length > 0
                    ? targetUser.dealerSegments
                    : null;
            const dealerSubSegments =
                targetUser.dealerSubSegments &&
                targetUser.dealerSubSegments.length > 0
                    ? targetUser.dealerSubSegments
                    : null;

            const mechanicsSegments =
                targetUser.mechanicsSegments &&
                targetUser.mechanicsSegments.length > 0
                    ? targetUser.mechanicsSegments
                    : null;
            const mechanicsSubSegments =
                targetUser.mechanicsSubSegments &&
                targetUser.mechanicsSubSegments.length > 0
                    ? targetUser.mechanicsSubSegments
                    : null;
            const spaceOfMechanics =
                targetUser.spaceOfMechanics &&
                targetUser.spaceOfMechanics.length > 0
                    ? targetUser.spaceOfMechanics
                    : null;

            const entryId = response.sys.id;
            const spaceId = response.sys.space.sys.id;
            const envId = response.sys.environment.sys.id;
            const contentType = response.sys.contentType.sys.id;
            for (let i = 0; i < appLanguages.length; ++i) {
                const language = appLanguages[i];
                const targetLink = content.targetLink[language || 'English'];

                const title = content.previewText[language || 'English'];
                const body = content.notificationText[language || 'English'];

                const pushNotificationPayload: PushNotificationApiPayload = {
                    country: getCountryTag(),
                    sectorId: dealerSegments,
                    subSectorId: dealerSubSegments,
                    mechanicSectorId: mechanicsSegments,
                    mechanicSubSectorId: mechanicsSubSegments,
                    mechanicSpace: spaceOfMechanics,
                    userType: userTypes,
                    language: API_LANGUAGE_CODE_MAPPING[language || 'English'],
                    notificationContent: {
                        title,
                        body,
                        data: {
                            entryId,
                            spaceId,
                            envId,
                            contentType,
                            targetLink,
                        },
                        android: {
                            priority: 'high',
                            notification: {
                                priority: 'high',
                                title,
                                body,
                                sound: 'default',
                                channelId: 'fastscan',
                            },
                        },
                    },
                    startDate: startDate.toISOString(),
                };

                dispatch && dispatch(
                    updateLoadingModalAction({
                        open: true,
                        title: loadingModalTitle,
                        content: `Triggering the push notification for language ${language}...`,
                        progress: currentProgress,
                    })
                );
                await ApiService.pushNotifications(pushNotificationPayload);
            }
            console.log('Send push notification successfully');
        } catch (e) {
            console.log('Send push notification failed', e);
        }

        dispatch && dispatch(
            updateLoadingModalAction({
                open: false,
            })
        );

        return response;
    }

    static async createUpdateInAppNotfication(
        dispatch: Dispatch<AnyAction> | undefined,
        isUpdate: boolean,
        publish: boolean,
        uiContent: InAppNotificationType
    ) {
        const { content, identifierDefinition, targetGeography, targetUser } =
            uiContent;

        const getDate = ContentfulTransformService.putDate;
        const startDate = getDate(
            identifierDefinition.startDate,
            identifierDefinition.startTime
        );
        const endDate = getDate(
            identifierDefinition.endDate,
            identifierDefinition.endTime
        );

        const fields: any = {
            name: identifierDefinition.name,
            language: identifierDefinition.appLanguages,
            startDate,
            icon: content.icon,
            linkType: content.isLinkInternal,

            region: targetGeography.regions,
            stateprovince: targetGeography.states.length
                ? targetGeography.states
                : ['UNKNOWN'],
            distributors: targetGeography.distributors.length
                ? targetGeography.distributors
                : ['UNKNOWN'],
            district: targetGeography.districts.length
                ? targetGeography.districts
                : ['UNKNOWN'],
            pincode: `${targetGeography.pincode}`,
            rmArea: targetGeography.rmAreas.length
                ? targetGeography.rmAreas
                : ['UNKNOWN'],
            userType: targetUser.userTypes,
            spaceOfMechanic: targetUser.spaceOfMechanics,
            connekt: targetUser.connekt,
            uploadMobile: targetUser.mobile,
            uploadPid: targetUser.PID,
            dealerWsSegment: targetUser.dealerSegments,
            dealerWsSubSegment: targetUser.dealerSubSegments,
            mechanicsSegment: targetUser.mechanicsSegments,
            mechanicsSubSegment: targetUser.mechanicsSubSegments,
        };

        if (identifierDefinition.endDate) {
            fields.endDate = endDate;
        } else {
            fields.endDate = undefined;
        }

        const lanFields = {
            description: content.bodyText,
            TargetLink: content.targetLink,
            // headline: content.headline,
        };

        // Open the loading modal
        const loadingModalTitle = 'Creating in-app notification';
        let currentProgress = 0;

        // Calculate total steps for progress
        const totalSteps = 3;
        let currentStep = 0;

        let response;

        if (isUpdate) {
            currentStep++;
            currentProgress = (currentStep / totalSteps) * 100;
            dispatch && dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: 'Updating content fields of the in-app notification...',
                    progress: currentProgress,
                })
            );
            response = await ContentfulApiService.updateContentFields(
                uiContent.raw.sys.id,
                uiContent.raw,
                fields,
                lanFields
            );
        } else {
            currentStep++;
            currentProgress = (currentStep / totalSteps) * 100;
            dispatch && dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: 'Creating content fields of the in-app notification...',
                    progress: currentProgress,
                })
            );
            response = await ContentfulApiService.createContent(
                'inAppNotification',
                fields,
                lanFields
            );
        }

        if (publish) {
            try {
                currentStep++;
                currentProgress = (currentStep / totalSteps) * 100;
                dispatch && dispatch(
                    updateLoadingModalAction({
                        open: true,
                        title: loadingModalTitle,
                        content: 'Publishing the in-app notification...',
                        progress: currentProgress,
                    })
                );
                await ContentfulApiService.publish(response.sys.id);
                console.log('inApp notification published');
            } catch (e) {
                console.log('published error', e);
            }
        }

        dispatch && dispatch(
            updateLoadingModalAction({
                open: false,
            })
        );

        return response;
    }

    static async createUpdateQuizQuestion(
        isUpdate: boolean,
        uiContent: QuizQuestion
    ) {
        const { content } = uiContent;

        const fields: any = {
            points: content.points,
            correctAnswerIndex: content.correctAnswerIndex,
        };

        const lanFields = {
            questionText: content.questionText,
            reason: content.reason,
            options: content.options,
        };

        let response;

        // Sometimes, we might add new question to an existing quiz
        // So some questions are update, some questions are newly created
        // For the newly created questions, the uiContent.raw is undefined.
        if (isUpdate && uiContent.raw) {
            response = await ContentfulApiService.updateContentFields(
                uiContent.raw.sys.id,
                uiContent.raw,
                fields,
                lanFields
            );
        } else {
            response = await ContentfulApiService.createContent(
                'quizQuestion',
                fields,
                lanFields
            );
        }
        return response;
    }

    static async createUpdateQuiz(
        dispatch: Dispatch<AnyAction> | undefined,
        isUpdate: boolean,
        publish: boolean,
        uiContent: QuizType
    ) {
        const {
            content,
            identifierDefinition,
            targetGeography,
            targetUser,
            linkedContentEntryId,
        } = uiContent;

        const getDate = ContentfulTransformService.putDate;
        // console.log('==identifierDefinition.startDate==', identifierDefinition.startDate);
        // console.log('==identifierDefinition.startTime==', identifierDefinition.startTime);
        const startDate = getDate(
            identifierDefinition.startDate,
            identifierDefinition.startTime
        );
        // console.log('==startDate==', startDate);
        const endDate = getDate(
            identifierDefinition.endDate,
            identifierDefinition.endTime
        );

        const fields: any = {
            name: identifierDefinition.name,
            language: identifierDefinition.appLanguages,
            startDate,
            bonusPoints: content.bonusPoints,
            questions: [],

            region: targetGeography.regions,
            stateprovince: targetGeography.states.length
                ? targetGeography.states
                : ['UNKNOWN'],
            distributors: targetGeography.distributors.length
                ? targetGeography.distributors
                : ['UNKNOWN'],
            district: targetGeography.districts.length
                ? targetGeography.districts
                : ['UNKNOWN'],
            pincode: `${targetGeography.pincode}`,
            rmArea: targetGeography.rmAreas.length
                ? targetGeography.rmAreas
                : ['UNKNOWN'],
            userType: targetUser.userTypes,
            spaceOfMechanic: targetUser.spaceOfMechanics,
            connekt: targetUser.connekt,
            uploadMobile: targetUser.mobile,
            uploadPid: targetUser.PID,
            dealerWsSegment: targetUser.dealerSegments,
            dealerWsSubSegment: targetUser.dealerSubSegments,
            mechanicsSegment: targetUser.mechanicsSegments,
            mechanicsSubSegment: targetUser.mechanicsSubSegments,
            totalPoints:
                content.questions.reduce(
                    (prev, cur) => prev + cur.content.points,
                    0
                ) + content.bonusPoints,
        };

        if (identifierDefinition.endDate) {
            fields.endDate = endDate;
        } else {
            fields.endDate = undefined;
        }

        if (linkedContentEntryId) {
            fields.linkedContent = {
                sys: {
                    id: linkedContentEntryId,
                    linkType: 'Entry',
                    type: 'Link',
                },
            };
        }

        const lanFields = {
            title: content.title,
            description: content.description,
        };

        // Open the loading modal
        const loadingModalTitle = 'Creating quiz';
        let currentProgress = 0;

        // Calculate total steps for progress
        const totalSteps = 4;
        let currentStep = 0;

        // Create questions
        dispatch && dispatch(
            updateLoadingModalAction({
                open: true,
                title: loadingModalTitle,
                content: 'Creating questions...',
                progress: currentProgress,
            })
        );

        // Create questions
        const { questions } = content;
        for (let i = 0; i < questions.length; ++i) {
            dispatch && dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: `Creating question ${i + 1}...`,
                    progress: currentProgress,
                })
            );
            const question = questions[i];
            const quizQuestion = await this.createUpdateQuizQuestion(
                isUpdate,
                question
            );
            if (quizQuestion) {
                question.raw = quizQuestion;
                // Created linked entry
                fields.questions.push({
                    sys: {
                        id: quizQuestion.sys.id,
                        linkType: 'Entry',
                        type: 'Link',
                    },
                });
            }
        }

        let response;

        if (isUpdate) {
            currentStep++;
            currentProgress = (currentStep / totalSteps) * 100;
            dispatch && dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: 'Updating content fields of the quiz...',
                    progress: currentProgress,
                })
            );
            response = await ContentfulApiService.updateContentFields(
                uiContent.raw.sys.id,
                uiContent.raw,
                fields,
                lanFields
            );
        } else {
            currentStep++;
            currentProgress = (currentStep / totalSteps) * 100;
            dispatch && dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: 'Creating content fields of the quiz...',
                    progress: currentProgress,
                })
            );
            response = await ContentfulApiService.createContent(
                'quiz',
                fields,
                lanFields
            );
            uiContent.raw = response;
        }

        currentStep++;
        currentProgress = (currentStep / totalSteps) * 100;
        // Update the questions associated quiz
        for (let i = 0; i < questions.length; ++i) {
            dispatch && dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: `Update question ${i + 1} associated quiz...`,
                    progress: currentProgress,
                })
            );
            const question = questions[i];
            await ContentfulApiService.updateContentFields(
                question.raw.sys.id,
                question.raw,
                {
                    quiz: {
                        sys: {
                            id: response.sys.id,
                            linkType: 'Entry',
                            type: 'Link',
                        },
                    },
                }
            );
        }

        if (publish) {
            try {
                // Publish quiz question first
                for (let i = 0; i < fields.questions.length; ++i) {
                    dispatch && dispatch(
                        updateLoadingModalAction({
                            open: true,
                            title: loadingModalTitle,
                            content: `Publishing the quiz question ${i + 1}...`,
                            progress: currentProgress,
                        })
                    );
                    await ContentfulApiService.publish(
                        fields.questions[i].sys.id
                    );
                }
                dispatch && dispatch(
                    updateLoadingModalAction({
                        open: true,
                        title: loadingModalTitle,
                        content: 'Publishing the quiz',
                        progress: currentProgress,
                    })
                );
                // Publish quiz
                await ContentfulApiService.publish(response.sys.id);
                console.log('quiz published');
            } catch (e) {
                console.log('published error', e);
            }
        }

        dispatch && dispatch(
            updateLoadingModalAction({
                open: false,
            })
        );

        return { response, questions: fields.questions };
    }

    static async deleteQuiz(
        dispatch: Dispatch<AnyAction> | undefined,
        uiContent: QuizType
    ) {
        const { content } = uiContent;

        // Open the loading modal
        const loadingModalTitle = 'Deleting quiz';
        let currentProgress = 0;

        // Remove all questions first
        const { questions } = content;

        // Calculate total steps for progress
        const totalSteps = questions.length + 1; // Questions + self-deletion
        let currentStep = 0;

        for (let i = 0; i < questions.length; ++i) {
            const question = questions[i];
            const content = `Deleting question #${i + 1}...`;
            currentStep++;
            currentProgress = (currentStep / totalSteps) * 100;
            dispatch && dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content,
                    progress: currentProgress,
                })
            );
            await ContentfulApiService.deleteContent(
                question.raw.sys.id
            );
        }

        // Delete quiz itself
        const sysId = uiContent.raw.sys.id;

        currentStep++;
        currentProgress = (currentStep / totalSteps) * 100;
        // Remove itself
        dispatch && dispatch(
            updateLoadingModalAction({
                open: true,
                title: loadingModalTitle,
                content: 'Deleting the current quiz...',
                progress: currentProgress,
            })
        );
        await ContentfulApiService.deleteContent(sysId);

        // We don't allow the quiz to be deleted if the quiz has linked content, then we don't need to update
        // the linked content reference.
        
        // Close the loading modal
        dispatch && dispatch(
            updateLoadingModalAction({
                open: false,
            })
        );
    }

    static async createUpdateScanningContestScheme(
        isUpdate: boolean,
        uiContent: ScanningContestScheme
    ) {
        const { content } = uiContent;

        const fields: any = {
            schemeType: content.schemeType,
            targetScans: content.targetScans,
            awardedPoints: content.awardedPoints,
        };

        let response;

        // Sometimes, we might add new schemes to an existing scanning contest
        // So some schemes are update, some scanning contests are newly created
        // For the newly created schemes, the uiContent.raw is undefined.
        if (isUpdate && uiContent.raw) {
            response = await ContentfulApiService.updateContentFields(
                uiContent.raw.sys.id,
                uiContent.raw,
                fields
            );
        } else {
            response = await ContentfulApiService.createContent(
                'scanningContestScheme',
                fields
            );
        }
        return response;
    }

    static async createUpdateScanningContest(
        dispatch: Dispatch<AnyAction>,
        isUpdate: boolean,
        publish: boolean,
        uiContent: ScanningContestType
    ) {
        const { content, identifierDefinition, targetGeography, targetUser } =
            uiContent;

        const getDate = ContentfulTransformService.putDate;
        // console.log('==identifierDefinition.startDate==', identifierDefinition.startDate);
        // console.log('==identifierDefinition.startTime==', identifierDefinition.startTime);
        const startDate = getDate(
            identifierDefinition.startDate,
            identifierDefinition.startTime
        );
        // console.log('==startDate==', startDate);
        const endDate = getDate(
            identifierDefinition.endDate,
            identifierDefinition.endTime
        );

        const fields: any = {
            name: identifierDefinition.name,
            language: identifierDefinition.appLanguages,
            startDate,
            applicableBrand: content.applicableBrands,
            applicableSKU: content.applicableSKUs,
            totalPoints: content.schemes
                .filter((elem) => elem?.content?.schemeType === 'Slab')
                .reduce((prev, cur) => prev + cur.content.awardedPoints, 0),
            multiplierPoints: content.schemes
                .filter((elem) => elem?.content?.schemeType === 'Multiplier')
                .reduce((prev, cur) => prev + cur.content.awardedPoints, 0),

            schemes: [],

            region: targetGeography.regions,
            stateprovince: targetGeography.states.length
                ? targetGeography.states
                : ['UNKNOWN'],
            distributors: targetGeography.distributors.length
                ? targetGeography.distributors
                : ['UNKNOWN'],
            district: targetGeography.districts.length
                ? targetGeography.districts
                : ['UNKNOWN'],
            pincode: `${targetGeography.pincode}`,
            rmArea: targetGeography.rmAreas.length
                ? targetGeography.rmAreas
                : ['UNKNOWN'],
            userType: targetUser.userTypes,
            spaceOfMechanic: targetUser.spaceOfMechanics,
            connekt: targetUser.connekt,
            uploadMobile: targetUser.mobile,
            uploadPid: targetUser.PID,
            dealerWsSegment: targetUser.dealerSegments,
            dealerWsSubSegment: targetUser.dealerSubSegments,
            mechanicsSegment: targetUser.mechanicsSegments,
            mechanicsSubSegment: targetUser.mechanicsSubSegments,
            // totalPoints:
            //     content.questions.reduce(
            //         (prev, cur) => prev + cur.content.points,
            //         0
            //     ) + content.bonusPoints,
        };

        if (identifierDefinition.endDate) {
            fields.endDate = endDate;
        } else {
            fields.endDate = undefined;
        }

        const lanFields = {
            headline: content.headline,
            description: content.description,
        };

        // Open the loading modal
        const loadingModalTitle = 'Creating scanning contest';
        let currentProgress = 0;

        // Calculate total steps for progress
        const totalSteps = 4;
        let currentStep = 0;

        // Create schemes
        dispatch(
            updateLoadingModalAction({
                open: true,
                title: loadingModalTitle,
                content: 'Creating schemes...',
                progress: currentProgress,
            })
        );

        const { schemes } = content;
        for (let i = 0; i < schemes.length; ++i) {
            dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: `Creating scheme ${i + 1}...`,
                    progress: currentProgress,
                })
            );
            const scheme = schemes[i];
            const scanningContestScheme =
                await this.createUpdateScanningContestScheme(isUpdate, scheme);
            if (scanningContestScheme) {
                scheme.raw = scanningContestScheme;
                // Created linked entry
                fields.schemes.push({
                    sys: {
                        id: scanningContestScheme.sys.id,
                        linkType: 'Entry',
                        type: 'Link',
                    },
                });
            }
        }

        let response;

        if (isUpdate) {
            currentStep++;
            currentProgress = (currentStep / totalSteps) * 100;
            dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: 'Updating content fields of the scanning contest...',
                    progress: currentProgress,
                })
            );
            response = await ContentfulApiService.updateContentFields(
                uiContent.raw.sys.id,
                uiContent.raw,
                fields,
                lanFields
            );
        } else {
            currentStep++;
            currentProgress = (currentStep / totalSteps) * 100;
            dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: 'Creating content fields of the scanning contest...',
                    progress: currentProgress,
                })
            );
            response = await ContentfulApiService.createContent(
                'scanningContest',
                fields,
                lanFields
            );
            uiContent.raw = response;
        }

        // Construct the target link of the notification
        // Points to the home screen
        uiContent.sendNotification.isLinkInternal = true;
        fields.language.forEach((lang) => {
            uiContent.sendNotification.targetLink[lang] =
                INTERNAL_PAGE_LINK['Home Page'];
        });

        let customizedPushNotificationContent:
            | PushNotificationContent
            | undefined;

        const isPushNotification =
            uiContent.sendNotification.notificationType.includes(
                'Push notifications'
            );

        if (isPushNotification) {
            const previewText =
                uiContent.sendNotification.pushNotificationContent?.headline;
            const notificationText =
                uiContent.sendNotification.pushNotificationContent?.bodyText;

            customizedPushNotificationContent = {
                // notificationType: 'New Release',
                previewText,
                notificationText,
                isLinkInternal: uiContent.sendNotification.isLinkInternal,
                targetLink: uiContent.sendNotification.targetLink,
            };
        }

        currentStep++;
        currentProgress = (currentStep / totalSteps) * 100;
        dispatch(
            updateLoadingModalAction({
                open: true,
                title: loadingModalTitle,
                content: 'Creating notification...',
                progress: currentProgress,
            })
        );
        await this.createNotificationContent(
            identifierDefinition,
            uiContent.sendNotification,
            targetGeography,
            targetUser,
            customizedPushNotificationContent
        );

        // Update the schemes associated scanninig contest
        for (let i = 0; i < schemes.length; ++i) {
            dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: `Update scheme ${i + 1} associated scanning contest...`,
                    progress: currentProgress,
                })
            );
            const scheme = schemes[i];
            await ContentfulApiService.updateContentFields(
                scheme.raw.sys.id,
                scheme.raw,
                {
                    scanningContest: {
                        sys: {
                            id: response.sys.id,
                            linkType: 'Entry',
                            type: 'Link',
                        },
                    },
                }
            );
        }

        if (publish) {
            currentStep++;
            currentProgress = (currentStep / totalSteps) * 100;
            try {
                // Publish scanning contest schemes first
                for (let i = 0; i < fields.schemes.length; ++i) {
                    dispatch(
                        updateLoadingModalAction({
                            open: true,
                            title: loadingModalTitle,
                            content: `Publishing scheme ${i + 1}...`,
                            progress: currentProgress,
                        })
                    );
                    await ContentfulApiService.publish(
                        fields.schemes[i].sys.id
                    );
                }
                // Publish scanning contest
                dispatch(
                    updateLoadingModalAction({
                        open: true,
                        title: loadingModalTitle,
                        content: `Publishing the scanning contest...`,
                        progress: currentProgress,
                    })
                );
                await ContentfulApiService.publish(response.sys.id);
                console.log('scanning contest published');
            } catch (e) {
                console.log('published error', e);
            }
        }

        // Close the loading modal
        dispatch(
            updateLoadingModalAction({
                open: false,
            })
        );
        return { response, schemes: fields.schemes };
    }

    static async deleteScanningContest(
        dispatch: Dispatch<AnyAction>,
        uiContent: ScanningContestType
    ) {
        const { content } = uiContent;

        // Open the loading modal
        const loadingModalTitle = 'Deleting scanning contest';
        let currentProgress = 0;

        // Remove all schemes first
        const { schemes } = content;

        // Calculate total steps for progress
        const totalSteps = schemes.length + 1; // Schemes + self-deletion
        let currentStep = 0;

        for (let i = 0; i < schemes.length; ++i) {
            const scheme = schemes[i];
            const content = `Deleting scheme #${i + 1}...`;
            currentStep++;
            currentProgress = (currentStep / totalSteps) * 100;
            dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content,
                    progress: currentProgress,
                })
            );
            await ContentfulApiService.deleteContent(
                scheme.raw.sys.id
            );
        }

        // Delete the scanning contest itself
        const sysId = uiContent.raw.sys.id;

        currentStep++;
        currentProgress = (currentStep / totalSteps) * 100;
        // Remove itself
        dispatch(
            updateLoadingModalAction({
                open: true,
                title: loadingModalTitle,
                content: 'Deleting the current scanning contest...',
                progress: currentProgress,
            })
        );
        await ContentfulApiService.deleteContent(sysId);
        
        // Close the loading modal
        dispatch(
            updateLoadingModalAction({
                open: false,
            })
        );
    }

    static async createUpdateRegisterContest(
        dispatch: Dispatch<AnyAction>,
        isUpdate: boolean,
        publish: boolean,
        uiContent: RegistrationContestType
    ) {
        const { content, identifierDefinition, targetGeography, targetUser } =
            uiContent;

        const getDate = ContentfulTransformService.putDate;
        // console.log('==identifierDefinition.startDate==', identifierDefinition.startDate);
        // console.log('==identifierDefinition.startTime==', identifierDefinition.startTime);
        const startDate = getDate(
            identifierDefinition.startDate,
            identifierDefinition.startTime
        );
        // console.log('==startDate==', startDate);
        const endDate = getDate(
            identifierDefinition.endDate,
            identifierDefinition.endTime
        );

        const fields: any = {
            name: identifierDefinition.name,
            language: identifierDefinition.appLanguages,
            startDate,

            region: targetGeography.regions,
            stateprovince: targetGeography.states.length
                ? targetGeography.states
                : ['UNKNOWN'],
            distributors: targetGeography.distributors.length
                ? targetGeography.distributors
                : ['UNKNOWN'],
            district: targetGeography.districts.length
                ? targetGeography.districts
                : ['UNKNOWN'],
            pincode: `${targetGeography.pincode}`,
            rmArea: targetGeography.rmAreas.length
                ? targetGeography.rmAreas
                : ['UNKNOWN'],
            userType: targetUser.userTypes,
            spaceOfMechanic: targetUser.spaceOfMechanics,
            connekt: targetUser.connekt,
            uploadMobile: targetUser.mobile,
            uploadPid: targetUser.PID,
            dealerWsSegment: targetUser.dealerSegments,
            dealerWsSubSegment: targetUser.dealerSubSegments,
            mechanicsSegment: targetUser.mechanicsSegments,
            mechanicsSubSegment: targetUser.mechanicsSubSegments,
            targetedUsers: content.targetedUsers,
            reachedUsers: content.reachedUsers,
            pointsForAchievement: content.pointsForAchievement,
            // totalPoints:
            //     content.questions.reduce(
            //         (prev, cur) => prev + cur.content.points,
            //         0
            //     ) + content.bonusPoints,
        };

        if (identifierDefinition.endDate) {
            fields.endDate = endDate;
        } else {
            fields.endDate = undefined;
        }

        const lanFields = {
            headline: content.headline,
        };

        let response;
        let options: any = {};
        const regionsToCheck = ['uk', 'ireland'];
        if (
            targetGeography?.regions.some((region) =>
                regionsToCheck.includes(region)
            )
        ) {
            options = {
                tags: targetGeography?.regions.map((region: any) => ({
                    sys: {
                        type: 'Link',
                        linkType: 'Tag',
                        id: region,
                    },
                })),
            };
        }

        // Open the loading modal
        const loadingModalTitle = 'Creating register contest';
        let currentProgress = 0;

        // Calculate total steps for progress
        const totalSteps = 2;
        let currentStep = 0;

        if (isUpdate) {
            currentStep++;
            currentProgress = (currentStep / totalSteps) * 100;
            dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: 'Updating content fields of the register contest...',
                    progress: currentProgress,
                })
            );
            response = await ContentfulApiService.updateContentFields(
                uiContent.raw.sys.id,
                uiContent.raw,
                fields,
                lanFields,
                [],
                options
            );
        } else {
            currentStep++;
            currentProgress = (currentStep / totalSteps) * 100;
            dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: 'Creating content fields of the register contest...',
                    progress: currentProgress,
                })
            );
            response = await ContentfulApiService.createContent(
                'registrationContest',
                fields,
                lanFields,
                options
            );
            uiContent.raw = response;
        }

        // Construct the target link of the notification
        // Points to the home screen

        // Update the schemes associated scanninig contest
        if (publish) {
            try {
                // Publish scanning contest
                currentStep++;
                currentProgress = (currentStep / totalSteps) * 100;
                dispatch(
                    updateLoadingModalAction({
                        open: true,
                        title: loadingModalTitle,
                        content: 'Publishing the register contest...',
                        progress: currentProgress,
                    })
                );
                await ContentfulApiService.publish(response.sys.id);
                console.log('scanning contest published');
            } catch (e) {
                console.log('published error', e);
            }
        }

        // Close the loading modal
        dispatch(
            updateLoadingModalAction({
                open: false,
            })
        );

        return { response, schemes: fields.schemes };
    }

    static async createNotificationContent(
        identifierDefinition: IdentifierDefinition,
        sendNotification: SendNotificationType,
        targetGeography: TargetGeographyType,
        targetUser: TargetUserType,
        customizedPushNotificationContent: PushNotificationContent
    ) {
        // let newNotificationContent = null;

        if (sendNotification.notificationType.length > 0) {
            // todo: if update, need find related notifcation first, then update it .
            for (const type of sendNotification.notificationType) {
                if (type === 'In-App Notifications') {
                    await CreationService.createUpdateInAppNotfication(
                        undefined,
                        false,
                        true,
                        {
                            content: {
                                bodyText:
                                    sendNotification.inAppNotificationContent!
                                        .bodyText,
                                // headline: sendNotification.headline,
                                icon: sendNotification.icon,
                                targetLink: sendNotification.targetLink,
                                isLinkInternal: sendNotification.isLinkInternal,
                            },
                            identifierDefinition,
                            targetGeography,
                            targetUser,
                        }
                    );
                } else {
                    const notificationText: any = {};
                    const headline: any = {};
                    for (const language of identifierDefinition.appLanguages) {
                        notificationText[
                            language
                        ] = `${identifierDefinition.name} is released`;
                        headline[
                            language
                        ] = `${identifierDefinition.name} Push notification`;
                    }
                    await CreationService.createUpdatePushNotfication(
                        undefined,
                        false,
                        true,
                        {
                            content: customizedPushNotificationContent,
                            identifierDefinition,
                            targetGeography,
                            targetUser,
                        }
                    );
                }
            }
        }
    }

    static async createUpdateVideo(
        dispatch: Dispatch<AnyAction>,
        isUpdate: boolean,
        publish: boolean,
        uiContent: VideoType
    ) {
        const video = uiContent;
        const {
            content,
            identifierDefinition,
            targetGeography,
            targetUser,
            quiz,
        } = uiContent;
        const { videoDuration, firstFrame } = video.content;

        const getDate = ContentfulTransformService.putDate;

        const startDate = getDate(
            identifierDefinition.startDate,
            identifierDefinition.startTime
        );
        const endDate = getDate(
            identifierDefinition.endDate,
            identifierDefinition.endTime
        );

        const commonFields = {
            language: identifierDefinition.appLanguages,
            region: targetGeography.regions,
            stateprovince: targetGeography.states,
            distributors: targetGeography.distributors,
            district: targetGeography.districts,
            pincode: `${targetGeography.pincode}`,
            rmArea: targetGeography.rmAreas,
            userType: targetUser.userTypes,
            spaceOfMechanic: targetUser.spaceOfMechanics,
            connekt: targetUser.connekt,
            uploadMobile: targetUser.mobile,
            uploadPid: targetUser.PID,
            dealerWsSegment: targetUser.dealerSegments,
            dealerWsSubSegment: targetUser.dealerSubSegments,
            mechanicsSegment: targetUser.mechanicsSegments,
            mechanicsSubSegment: targetUser.mechanicsSubSegments,
        };

        const fields: any = {
            name: identifierDefinition.name,
            featured: content.isFeatured,
            playlists: content.playlists.map((pl) => ({
                sys: {
                    linkType: 'Entry',
                    type: 'Link',
                    id: pl.id,
                },
            })),
            startDate,
            tags: content.tags,

            ...commonFields,
        };

        if (identifierDefinition.endDate) {
            fields.endDate = endDate;
        }

        if (content.isFromURL) {
            fields.videoFromUrl = content.source;
        } else {
            delete fields.videoFromUrl;
        }

        const lanFields = {
            videoTitle: content.title,
            videoSummary: content.summary,
            description: content.description,
        };

        fields.videoDuration = moment
            .tz(getDefaultTimezone())
            .startOf('day')
            .seconds(videoDuration || 0)
            .format('HH:mm:ss');
        if (fields.videoDuration.startsWith('00:')) {
            fields.videoDuration = fields.videoDuration.slice(3);
        }

        console.log('video source', video.content.source);
        console.log(
            'video.sendNotification.notificationType',
            video.sendNotification.notificationType
        );

        // Open the loading modal
        const loadingModalTitle = 'Creating video';
        let currentProgress = 0;

        // Calculate total steps for progress
        const totalSteps = 7;
        let currentStep = 0;

        // todo: process update for video source and thunmbnail
        if (
            !video.content.isFromURL &&
            video.content.source &&
            !video.content.oldSource
        ) {
            // Upload video
            dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: 'Uploading video source...',
                    progress: currentProgress,
                })
            );
            const asset = await ContentfulApiService.createUpload(
                video.content.source,
                await readMediaBuffer(video.content.source)
            );

            if (asset) {
                fields.videoMedia = ContentfulTransformService.putLink(
                    asset.sys.id
                );
            }
        }

        if (!video.content.isFromURL && firstFrame && !video.content.oldImage) {
            // Upload video
            dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: 'Uploading video source...',
                    progress: currentProgress,
                })
            );
            const asset2 = await ContentfulApiService.createUpload(
                firstFrame,
                await readMediaBuffer(firstFrame)
            );

            if (asset2) {
                fields.videoThumbnail = ContentfulTransformService.putLink(
                    asset2.sys.id
                );
            }
        }

        const omitFields = [];
        // for now, if fromURL(assume youtube), delete original video source
        if (video.content.isFromURL) {
            omitFields.push('videoThumbnail');
            omitFields.push('videoMedia');
        }

        let response;
        if (isUpdate) {
            currentStep++;
            currentProgress = (currentStep / totalSteps) * 100;
            dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: 'Updating content fields of the video...',
                    progress: currentProgress,
                })
            );
            response = await ContentfulApiService.updateContentFields(
                video.raw.sys.id,
                video.raw,
                fields,
                lanFields,
                omitFields
            );
        } else {
            currentStep++;
            currentProgress = (currentStep / totalSteps) * 100;
            dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: 'Creating content fields of the video...',
                    progress: currentProgress,
                })
            );
            response = await ContentfulApiService.createContent(
                'video',
                fields,
                lanFields
            );
        }

        // Construct the target link of the notification
        // fastscan://video/<sys.id>
        uiContent.sendNotification.isLinkInternal = true;
        commonFields.language.forEach((lang) => {
            uiContent.sendNotification.targetLink[
                lang
            ] = `fastscan://video/${response.sys.id}`;
        });

        let customizedPushNotificationContent:
            | PushNotificationContent
            | undefined;

        const isPushNotification =
            uiContent.sendNotification.notificationType.includes(
                'Push notifications'
            );

        if (isPushNotification) {
            const previewText =
                uiContent.sendNotification.pushNotificationContent?.headline;
            const notificationText =
                uiContent.sendNotification.pushNotificationContent?.bodyText;

            customizedPushNotificationContent = {
                // notificationType: 'New Release',
                previewText,
                notificationText,
                isLinkInternal: uiContent.sendNotification.isLinkInternal,
                targetLink: uiContent.sendNotification.targetLink,
            };
        }

        currentStep++;
        currentProgress = (currentStep / totalSteps) * 100;
        dispatch(
            updateLoadingModalAction({
                open: true,
                title: loadingModalTitle,
                content: 'Creating the notification...',
                progress: currentProgress,
            })
        );
        await this.createNotificationContent(
            identifierDefinition,
            uiContent.sendNotification,
            targetGeography,
            targetUser,
            customizedPushNotificationContent
        );

        // Quiz
        let quizResponse;
        let quizQuestions;
        if (quiz) {
            if (quiz.markDeleted) {
                const quizId = quiz.raw?.sys?.id;
                if (quizId) {
                    currentStep++;
                    currentProgress = (currentStep / totalSteps) * 100;
                    dispatch(
                        updateLoadingModalAction({
                            open: true,
                            title: loadingModalTitle,
                            content: 'Deleting the existing linked quiz...',
                            progress: currentProgress,
                        })
                    );
                    // Remove the video linked content
                    await ContentfulApiService.updateContentFields(
                        response.sys.id,
                        response,
                        {
                            quiz: undefined,
                        }
                    );
                    // Delete the linked quiz
                    await ContentfulApiService.deleteContent(quizId);
                }
            } else {
                const quizTypeContent: QuizType = {
                    raw: quiz.raw,
                    identifierDefinition,
                    content: quiz.content,
                    targetGeography,
                    targetUser,
                    linkedContentEntryId: response.sys.id,
                };
                currentStep++;
                currentProgress = (currentStep / totalSteps) * 100;
                dispatch(
                    updateLoadingModalAction({
                        open: true,
                        title: loadingModalTitle,
                        content: 'Creating the linked quiz...',
                        progress: currentProgress,
                    })
                );
                const result = await this.createUpdateQuiz(
                    undefined,
                    !!quiz.raw,
                    false,
                    quizTypeContent
                );
                quizResponse = result.response;
                quizQuestions = result.questions;
                // Update the video associated quiz
                await ContentfulApiService.updateContentFields(
                    response.sys.id,
                    response,
                    {
                        quiz: {
                            sys: {
                                id: quizResponse.sys.id,
                                linkType: 'Entry',
                                type: 'Link',
                            },
                        },
                    }
                );
            }
        }

        console.log(`${isUpdate ? 'updated' : 'created'} video`, response);

        if (publish) {
            try {
                // Publish quiz question first
                if (quizQuestions) {
                    for (let i = 0; i < quizQuestions.length; ++i) {
                        dispatch(
                            updateLoadingModalAction({
                                open: true,
                                title: loadingModalTitle,
                                content: `Publishing the quiz question ${i + 1}...`,
                                progress: currentProgress,
                            })
                        );
                        await ContentfulApiService.publish(
                            quizQuestions[i].sys.id
                        );
                    }
                }
                if (quizResponse) {
                    dispatch(
                        updateLoadingModalAction({
                            open: true,
                            title: loadingModalTitle,
                            content: 'Publishing the linked quiz...',
                            progress: currentProgress,
                        })
                    );
                    await ContentfulApiService.publish(quizResponse.sys.id);
                }
                dispatch(
                    updateLoadingModalAction({
                        open: true,
                        title: loadingModalTitle,
                        content: 'Publishing the video...',
                        progress: currentProgress,
                    })
                );
                await ContentfulApiService.publish(response.sys.id);
                console.log('video published');
            } catch (e) {
                console.log('published error', e);
            }
        } else {
            try {
                if (quizResponse) {
                    dispatch(
                        updateLoadingModalAction({
                            open: true,
                            title: loadingModalTitle,
                            content: 'Unpublish the linked quiz...',
                            progress: currentProgress,
                        })
                    );
                    await ContentfulApiService.unPublish(quizResponse.sys.id);
                }
                dispatch(
                    updateLoadingModalAction({
                        open: true,
                        title: loadingModalTitle,
                        content: 'Unpublish the video...',
                        progress: currentProgress,
                    })
                );
                await ContentfulApiService.unPublish(response.sys.id);
                console.log('video unpublished');
            } catch (e) {
                console.log('unpublished error', e);
            }
        }

        // Close the loading modal
        dispatch(
            updateLoadingModalAction({
                open: false,
            })
        );

        return response;
    }

    static async deleteVideo(
        dispatch: Dispatch<AnyAction>,
        uiContent: VideoType
    ) {
        // Open the loading modal
        const loadingModalTitle = 'Deleting video';
        let currentProgress = 0;

        // Calculate total steps for progress
        const totalSteps = 2; //  quiz + self-deletion
        let currentStep = 0;

        // If it contains linked quiz, delete the quiz
        if (uiContent.quiz) {
            dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: 'Deleting the linked quiz...',
                    progress: currentProgress,
                })
            );
            // await CreationService.deleteQuiz(
            //     undefined,
            //     uiContent.quiz.raw.sys.id
            // );
            await ContentfulApiService.deleteContent(uiContent.quiz.raw.sys.id);
        }

        const sysId = uiContent.raw.sys.id;

        currentStep++;
        currentProgress = (currentStep / totalSteps) * 100;
        // Remove itself
        dispatch(
            updateLoadingModalAction({
                open: true,
                title: loadingModalTitle,
                content: 'Deleting the current video...',
                progress: currentProgress,
            })
        );
        await ContentfulApiService.deleteContent(sysId);
        // Close the loading modal
        dispatch(
            updateLoadingModalAction({
                open: false,
            })
        );
    }

    static async createUpdateBanner(
        dispatch: Dispatch<AnyAction>,
        isUpdate: boolean,
        publish: boolean,
        uiContent: BannerType
    ) {
        const { content, identifierDefinition, targetGeography, targetUser } =
            uiContent;
        const getDate = ContentfulTransformService.putDate;

        const startDate = getDate(
            identifierDefinition.startDate,
            identifierDefinition.startTime
        );
        const endDate = getDate(
            identifierDefinition.endDate,
            identifierDefinition.endTime
        );

        const fields: any = {
            name: identifierDefinition.name,
            language: identifierDefinition.appLanguages,
            linkType: content.isLinkInternal,
            tags: content.tags,

            startDate,
            region: targetGeography.regions,
            stateprovince: targetGeography.states.length
                ? targetGeography.states
                : ['UNKNOWN'],
            distributors: targetGeography.distributors.length
                ? targetGeography.distributors
                : ['UNKNOWN'],
            district: targetGeography.districts.length
                ? targetGeography.districts
                : ['UNKNOWN'],
            pincode: `${targetGeography.pincode}`,
            rmArea: targetGeography.rmAreas.length
                ? targetGeography.rmAreas
                : ['UNKNOWN'],
            userType: targetUser.userTypes,
            spaceOfMechanic: targetUser.spaceOfMechanics,
            connekt: targetUser.connekt,
            uploadMobile: targetUser.mobile,
            uploadPid: targetUser.PID,
            dealerWsSegment: targetUser.dealerSegments,
            dealerWsSubSegment: targetUser.dealerSubSegments,
            mechanicsSegment: targetUser.mechanicsSegments,
            mechanicsSubSegment: targetUser.mechanicsSubSegments,
        };

        if (identifierDefinition.endDate) {
            fields.endDate = endDate;
        } else {
            fields.endDate = undefined;
        }

        const lanFields: any = {
            description: content.description,
            headline: content.headline,
            callToActionText: content.actionText,
        };
        const omitFields = [];
        if (content.linkSource) {
            lanFields.linkSource = content.linkSource;
        } else {
            omitFields.push('linkSource');
        }

        console.log('image source', content.image);

        // Open the loading modal
        const loadingModalTitle = 'Creating banner';
        let currentProgress = 0;

        // Calculate total steps for progress
        const totalSteps = 2;
        let currentStep = 0;

        if (content.image && !content.oldImage) {
            // Upload image
            dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: 'Uploading image source...',
                    progress: currentProgress,
                })
            );
            const asset = await ContentfulApiService.createUpload(
                content.image,
                await readMediaBuffer(content.image)
            );

            if (asset) {
                fields.bannerImage = ContentfulTransformService.putLink(
                    asset.sys.id
                );
            }
        }

        let response;

        if (isUpdate) {
            currentStep++;
            currentProgress = (currentStep / totalSteps) * 100;
            dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: 'Updating content fields of the banner...',
                    progress: currentProgress,
                })
            );
            response = await ContentfulApiService.updateContentFields(
                uiContent?.raw?.sys?.id || '',
                uiContent?.raw || {},
                fields,
                lanFields,
                omitFields
            );
        } else {
            currentStep++;
            currentProgress = (currentStep / totalSteps) * 100;
            dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: 'Creating content fields of the banner...',
                    progress: currentProgress,
                })
            );
            response = await ContentfulApiService.createContent(
                'banner',
                fields,
                lanFields
            );
        }

        if (publish) {
            try {
                currentStep++;
                currentProgress = (currentStep / totalSteps) * 100;
                dispatch(
                    updateLoadingModalAction({
                        open: true,
                        title: loadingModalTitle,
                        content: 'Publishing the banner...',
                        progress: currentProgress,
                    })
                );
                await ContentfulApiService.publish(response.sys.id);
                console.log('banner published');
            } catch (e) {
                console.log('published error', e);
            }
        }

        // Close the loading modal
        dispatch(
            updateLoadingModalAction({
                open: false,
            })
        );

        return response;
    }

    static async createUpdateArticle(
        dispatch: Dispatch<AnyAction>,
        isUpdate: boolean,
        publish: boolean,
        uiContent: ArticleType
    ) {
        const {
            content,
            identifierDefinition,
            targetGeography,
            targetUser,
            quiz,
        } = uiContent;

        const getDate = ContentfulTransformService.putDate;

        const startDate = getDate(
            identifierDefinition.startDate,
            identifierDefinition.startTime
        );
        const endDate = getDate(
            identifierDefinition.endDate,
            identifierDefinition.endTime
        );

        const fields: any = {
            name: identifierDefinition.name,
            language: identifierDefinition.appLanguages,

            featured: content.isFeatured,
            tags: content.tags,

            folders: content.folders.map((pl) => ({
                sys: {
                    linkType: 'Entry',
                    type: 'Link',
                    id: pl.id,
                },
            })),

            startDate,
            region: targetGeography.regions,
            stateprovince: targetGeography.states,
            distributors: targetGeography.distributors,
            district: targetGeography.districts,
            pincode: `${targetGeography.pincode}`,
            rmArea: targetGeography.rmAreas,
            userType: targetUser.userTypes,
            spaceOfMechanic: targetUser.spaceOfMechanics,
            connekt: targetUser.connekt,
            uploadMobile: targetUser.mobile,
            uploadPid: targetUser.PID,
            dealerWsSegment: targetUser.dealerSegments,
            dealerWsSubSegment: targetUser.dealerSubSegments,
            mechanicsSegment: targetUser.mechanicsSegments,
            mechanicsSubSegment: targetUser.mechanicsSubSegments,
        };

        if (identifierDefinition.endDate) {
            fields.endDate = endDate;
        } else {
            fields.endDate = undefined;
        }

        const lanFields = {
            description: content.description,
            headline: content.headline,
            callToActionText: content.actionText,
            linkSource: content.linkSource,
        };

        console.log('image source', content.image);

        // Open the loading modal
        const loadingModalTitle = 'Creating article';
        let currentProgress = 0;

        // Calculate total steps for progress
        const totalSteps = 7;
        let currentStep = 0;

        if (content.image && !content.oldImage) {
            // Upload image
            dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: 'Uploading image source...',
                    progress: currentProgress,
                })
            );
            const asset = await ContentfulApiService.createUpload(
                content.image,
                await readMediaBuffer(content.image)
            );

            if (asset) {
                fields.articleImage = ContentfulTransformService.putLink(
                    asset.sys.id
                );
            }
        }

        let response;

        if (isUpdate) {
            currentStep++;
            currentProgress = (currentStep / totalSteps) * 100;
            dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: 'Updating content fields of the article...',
                    progress: currentProgress,
                })
            );
            response = await ContentfulApiService.updateContentFields(
                uiContent?.raw?.sys?.id || '',
                uiContent?.raw || {},
                fields,
                lanFields
            );
        } else {
            currentStep++;
            currentProgress = (currentStep / totalSteps) * 100;
            dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: 'Creating content fields of the article...',
                    progress: currentProgress,
                })
            );
            response = await ContentfulApiService.createContent(
                'article',
                fields,
                lanFields
            );
        }

        // Construct the target link of the notification
        // fastscan://article/<sys.id>
        uiContent.sendNotification.isLinkInternal = true;
        fields.language.forEach((lang) => {
            uiContent.sendNotification.targetLink[
                lang
            ] = `fastscan://article/${response.sys.id}`;
        });

        let customizedPushNotificationContent:
            | PushNotificationContent
            | undefined;

        const isPushNotification =
            uiContent.sendNotification.notificationType.includes(
                'Push notifications'
            );

        if (isPushNotification) {
            const previewText =
                uiContent.sendNotification.pushNotificationContent?.headline;
            const notificationText =
                uiContent.sendNotification.pushNotificationContent?.bodyText;

            customizedPushNotificationContent = {
                // notificationType: 'New Release',
                previewText,
                notificationText,
                isLinkInternal: uiContent.sendNotification.isLinkInternal,
                targetLink: uiContent.sendNotification.targetLink,
            };
        }

        currentStep++;
        currentProgress = (currentStep / totalSteps) * 100;
        dispatch(
            updateLoadingModalAction({
                open: true,
                title: loadingModalTitle,
                content: 'Creating the notification...',
                progress: currentProgress,
            })
        );
        await this.createNotificationContent(
            identifierDefinition,
            uiContent.sendNotification,
            targetGeography,
            targetUser,
            customizedPushNotificationContent
        );

        // Quiz
        let quizResponse;
        let quizQuestions;
        if (quiz) {
            if (quiz.markDeleted) {
                const quizId = quiz.raw?.sys?.id;
                if (quizId) {
                    currentStep++;
                    currentProgress = (currentStep / totalSteps) * 100;
                    dispatch(
                        updateLoadingModalAction({
                            open: true,
                            title: loadingModalTitle,
                            content: 'Deleting the existing linked quiz...',
                            progress: currentProgress,
                        })
                    );
                    // Remove the article linked content
                    await ContentfulApiService.updateContentFields(
                        response.sys.id,
                        response,
                        {
                            quiz: undefined,
                        }
                    );
                    // Delete the linked quiz
                    await ContentfulApiService.deleteContent(quizId);
                }
            } else {
                const quizTypeContent: QuizType = {
                    raw: quiz.raw,
                    identifierDefinition,
                    content: quiz.content,
                    targetGeography,
                    targetUser,
                    linkedContentEntryId: response.sys.id,
                };
                currentStep++;
                currentProgress = (currentStep / totalSteps) * 100;
                dispatch(
                    updateLoadingModalAction({
                        open: true,
                        title: loadingModalTitle,
                        content: 'Creating the linked quiz...',
                        progress: currentProgress,
                    })
                );
                const result = await this.createUpdateQuiz(
                    undefined,
                    !!quiz.raw,
                    false,
                    quizTypeContent
                );
                quizResponse = result.response;
                quizQuestions = result.questions;
                // Update the video associated quiz
                await ContentfulApiService.updateContentFields(
                    response.sys.id,
                    response,
                    {
                        quiz: {
                            sys: {
                                id: quizResponse.sys.id,
                                linkType: 'Entry',
                                type: 'Link',
                            },
                        },
                    }
                );
            }
        }

        if (publish) {
            try {
                if (quizQuestions) {
                    for (let i = 0; i < quizQuestions.length; ++i) {
                        dispatch(
                            updateLoadingModalAction({
                                open: true,
                                title: loadingModalTitle,
                                content: `Publishing the quiz question ${i + 1}...`,
                                progress: currentProgress,
                            })
                        );
                        await ContentfulApiService.publish(
                            quizQuestions[i].sys.id
                        );
                    }
                }
                if (quizResponse) {
                    dispatch(
                        updateLoadingModalAction({
                            open: true,
                            title: loadingModalTitle,
                            content: 'Publishing the linked quiz...',
                            progress: currentProgress,
                        })
                    );
                    await ContentfulApiService.publish(quizResponse.sys.id);
                }
                dispatch(
                    updateLoadingModalAction({
                        open: true,
                        title: loadingModalTitle,
                        content: 'Publishing the article...',
                        progress: currentProgress,
                    })
                );
                await ContentfulApiService.publish(response.sys.id);
                console.log('article published');
            } catch (e) {
                console.log('published error', e);
            }
        }

        // Close the loading modal
        dispatch(
            updateLoadingModalAction({
                open: false,
            })
        );

        return response;
    }

    static async deleteArticle(
        dispatch: Dispatch<AnyAction>,
        uiContent: ArticleType
    ) {
        // Open the loading modal
        const loadingModalTitle = 'Deleting article';
        let currentProgress = 0;

        // Calculate total steps for progress
        const totalSteps = 2; //  quiz + self-deletion
        let currentStep = 0;

        // If it contains linked quiz, delete the quiz
        if (uiContent.quiz) {
            dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: 'Deleting the linked quiz...',
                    progress: currentProgress,
                })
            );
            // await CreationService.deleteQuiz(
            //     undefined,
            //     uiContent.quiz.raw.sys.id
            // );
            await ContentfulApiService.deleteContent(uiContent.quiz.raw.sys.id);
        }

        const sysId = uiContent.raw.sys.id;

        currentStep++;
        currentProgress = (currentStep / totalSteps) * 100;
        // Remove itself
        dispatch(
            updateLoadingModalAction({
                open: true,
                title: loadingModalTitle,
                content: 'Deleting the current article...',
                progress: currentProgress,
            })
        );
        await ContentfulApiService.deleteContent(sysId);
        // Close the loading modal
        dispatch(
            updateLoadingModalAction({
                open: false,
            })
        );
    }

    // Learning card

    static async createUpdateLearningCardTheme(
        dispatch: Dispatch<AnyAction>,
        isUpdate: boolean,
        publish: boolean,
        uiContent: LearningCardThemeType
    ) {
        const { content } = uiContent;

        const fields: any = {
            name: uiContent.name,
            language: uiContent.appLanguages,
            tags: content.tags,
        };

        const lanFields = {
            description: content.description,
            headline: content.headline,
        };

        // Open the loading modal
        const loadingModalTitle = `${
            isUpdate ? 'Updating' : 'Creating'
        } learning card module`;
        let currentProgress = 0;

        // Calculate total steps for progress
        const totalSteps = 3;
        let currentStep = 1;
        currentProgress = (currentStep / totalSteps) * 100;

        console.log('image source', content.image);

        if (content.image && !content.oldImage) {
            dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: 'Uploading images',
                    progress: currentProgress,
                })
            );

            const asset = await ContentfulApiService.createUpload(
                content.image,
                await readMediaBuffer(content.image)
            );

            if (asset) {
                fields.learningCardThemeImage =
                    ContentfulTransformService.putLink(asset.sys.id);
            }
        }

        let response;

        if (isUpdate && uiContent.raw) {
            currentStep++;
            currentProgress = (currentStep / totalSteps) * 100;
            dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: 'Updating content of the module',
                    progress: currentProgress,
                })
            );
            response = await ContentfulApiService.updateContentFields(
                uiContent.raw.sys.id,
                uiContent.raw,
                fields,
                lanFields
            );
        } else {
            currentStep++;
            currentProgress = (currentStep / totalSteps) * 100;
            dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: 'Creating content of the module',
                    progress: currentProgress,
                })
            );
            response = await ContentfulApiService.createContent(
                'learningCardTheme',
                fields,
                lanFields
            );
        }

        if (publish) {
            try {
                currentStep++;
                currentProgress = (currentStep / totalSteps) * 100;
                dispatch(
                    updateLoadingModalAction({
                        open: true,
                        title: loadingModalTitle,
                        content: 'Creating content of the module',
                        progress: currentProgress,
                    })
                );
                await ContentfulApiService.publish(response.sys.id);
                console.log('learning card theme published');
            } catch (e) {
                console.log('published error', e);
            }
        }
        dispatch(
            updateLoadingModalAction({
                open: false,
            })
        );
        return response;
    }

    static async createUpdateLearningCardSection(
        dispatch: Dispatch<AnyAction>,
        isUpdate: boolean,
        publish: boolean,
        uiContent: LearningCardSectionType
    ) {
        const { content } = uiContent;

        const fields: any = {
            name: uiContent.name,
            language: uiContent.appLanguages,
            tags: content.tags,
        };

        const lanFields = {
            description: content.description,
            headline: content.headline,
        };

        console.log('image source', content.image);

        // Open the loading modal
        const loadingModalTitle = `${
            isUpdate ? 'Updating' : 'Creating'
        } learning card section`;
        let currentProgress = 0;

        // Calculate total steps for progress
        const totalSteps = 3;
        let currentStep = 1;
        currentProgress = (currentStep / totalSteps) * 100;

        if (content.image && !content.oldImage) {
            dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: 'Uploading images',
                    progress: currentProgress,
                })
            );

            const asset = await ContentfulApiService.createUpload(
                content.image,
                await readMediaBuffer(content.image)
            );

            if (asset) {
                fields.learningCardSectionImage =
                    ContentfulTransformService.putLink(asset.sys.id);
            }
        }

        if (content.referencedTheme) {
            fields.referencedTheme = {
                sys: {
                    id: content.referencedTheme.id,
                    linkType: 'Entry',
                    type: 'Link',
                },
            };
        }

        let response;

        if (isUpdate && uiContent.raw) {
            currentStep++;
            currentProgress = (currentStep / totalSteps) * 100;
            dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: 'Updating content of the section',
                    progress: currentProgress,
                })
            );
            response = await ContentfulApiService.updateContentFields(
                uiContent.raw.sys.id,
                uiContent.raw,
                fields,
                lanFields
            );
        } else {
            currentStep++;
            currentProgress = (currentStep / totalSteps) * 100;
            dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: 'Creating content of the section',
                    progress: currentProgress,
                })
            );
            response = await ContentfulApiService.createContent(
                'learningCardSection',
                fields,
                lanFields
            );
        }

        // Update the prev referenced theme;
        // console.log('==prevReferencedTheme==', content.prevReferencedTheme);
        // console.log('==referencedTheme==', content.prevReferencedTheme);
        // Theme changed
        const themeChanged = !(
            content.prevReferencedTheme &&
            content.referencedTheme &&
            content.prevReferencedTheme.id === content.referencedTheme.id
        );

        let prevReferecedThemeResponse;
        let referecedThemeResponse;
        console.log(
            '=createUpdateLearningCardSection=themeChanged==',
            themeChanged
        );
        console.log(
            '==content.prevReferencedTheme==',
            content.prevReferencedTheme
        );
        console.log('==content.referencedTheme==', content.referencedTheme);
        if (themeChanged) {
            if (content.prevReferencedTheme) {
                const prevReferecedThemeContentfulRawData =
                    content.prevReferencedTheme.contentfulRawData;
                let prevThemeSectionsField =
                    content.prevReferencedTheme.sections ?? [];
                if (prevThemeSectionsField.length > 0) {
                    let toRemoveSectionIndex;
                    for (let i = 0; i < prevThemeSectionsField.length; ++i) {
                        const sectionField = prevThemeSectionsField[i];
                        if (sectionField.sys.id === response.sys.id) {
                            toRemoveSectionIndex = i;
                            break;
                        }
                    }
                    if (toRemoveSectionIndex !== undefined) {
                        prevThemeSectionsField.splice(toRemoveSectionIndex, 1);
                        if (prevThemeSectionsField.length === 0) {
                            prevThemeSectionsField = undefined;
                        }
                        // console.log(
                        //     '==prevThemeSectionsField==',
                        //     prevThemeSectionsField
                        // );
                        currentStep++;
                        currentProgress = (currentStep / totalSteps) * 100;
                        dispatch(
                            updateLoadingModalAction({
                                open: true,
                                title: loadingModalTitle,
                                content:
                                    'Updating the referenced content of previously linked module',
                                progress: currentProgress,
                            })
                        );
                        prevReferecedThemeResponse =
                            await ContentfulApiService.updateContentFields(
                                content.prevReferencedTheme.id,
                                prevReferecedThemeContentfulRawData,
                                {
                                    sections: prevThemeSectionsField,
                                }
                            );
                    }
                }
            }
            // Update the new referenced theme
            const referecedThemeContentfulRawData =
                content.referencedTheme.contentfulRawData;
            const sectionsField = content.referencedTheme.sections ?? [];
            // Check if the ID already exists
            let sectionAlreadyExists = false;
            for (let i = 0; i < sectionsField.length; ++i) {
                const sectionField = sectionsField[i];
                if (sectionField.sys.id === response.sys.id) {
                    sectionAlreadyExists = true;
                    break;
                }
            }
            if (!sectionAlreadyExists) {
                sectionsField.push({
                    sys: {
                        id: response.sys.id,
                        linkType: 'Entry',
                        type: 'Link',
                    },
                });
            }
            currentStep++;
            currentProgress = (currentStep / totalSteps) * 100;
            dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content:
                        'Updating the referenced content of currently linked module',
                    progress: currentProgress,
                })
            );
            // console.log('==sectionsField==', sectionsField);
            referecedThemeResponse =
                await ContentfulApiService.updateContentFields(
                    content.referencedTheme.id,
                    referecedThemeContentfulRawData,
                    {
                        sections: sectionsField,
                    }
                );
        }

        if (publish) {
            try {
                if (prevReferecedThemeResponse) {
                    currentStep++;
                    currentProgress = (currentStep / totalSteps) * 100;
                    dispatch(
                        updateLoadingModalAction({
                            open: true,
                            title: loadingModalTitle,
                            content: 'Publishing the previously linked module',
                            progress: currentProgress,
                        })
                    );
                    await ContentfulApiService.publish(
                        prevReferecedThemeResponse.sys.id
                    );
                    console.log(
                        'referenced prev learning card theme published'
                    );
                }
                if (referecedThemeResponse) {
                    currentStep++;
                    currentProgress = (currentStep / totalSteps) * 100;
                    dispatch(
                        updateLoadingModalAction({
                            open: true,
                            title: loadingModalTitle,
                            content: 'Publishing the currenlty linked module',
                            progress: currentProgress,
                        })
                    );
                    await ContentfulApiService.publish(
                        referecedThemeResponse.sys.id
                    );
                    console.log('referenced learning card theme published');
                }
                currentStep++;
                currentProgress = (currentStep / totalSteps) * 100;
                dispatch(
                    updateLoadingModalAction({
                        open: true,
                        title: loadingModalTitle,
                        content: 'Publishing the current section',
                        progress: currentProgress,
                    })
                );
                await ContentfulApiService.publish(response.sys.id);
                console.log('learning card section published');
            } catch (e) {
                console.log('published error', e);
            }
        }
        dispatch(
            updateLoadingModalAction({
                open: false,
            })
        );
        return response;
    }

    // {
    //     contentfulRawData: p,
    //     id: p.sys.id,
    //     name: cfDefaultGet(p, 'fields.name') || '',
    //     language: cfDefaultGet(p, 'fields.language') || [],
    //     tags: cfDefaultGet(p.fields, 'tags') || [],
    //     headline: cfLanGet(p, 'fields.headline'),
    //     description: cfLanGet(p, 'fields.description'),
    //     lanHeadline: p.fields.headline,
    //     lanDescription: p.fields.description,
    //     cards: cfDefaultGet(p, 'fields.cards') || [],
    //     referencedTheme: cfDefaultGet(p, 'fields.referencedTheme'),
    //     image,
    //     imageAsset,
    //     status:
    //         (p.fields.status && p.fields.status[lan]) ||
    //         (p.sys.publishedVersion ? 'Published' : 'Unpublish'),
    // }
    static async deleteLearningCardSection(
        dispatch: Dispatch<AnyAction>,
        uiContent: any
    ) {
        // If the section contains cards, don't allow it to be removed.

        const sysId = uiContent.id;

        // Open the loading modal
        const loadingModalTitle = 'Deleting learning card section';
        let currentProgress = 0;

        // Calculate total steps for progress
        const totalSteps = 2; // self-deletion + theme update
        let currentStep = 1;
        currentProgress = (currentStep / totalSteps) * 100;

        dispatch(
            updateLoadingModalAction({
                open: true,
                title: loadingModalTitle,
                content: 'Removing the current section',
                progress: currentProgress,
            })
        );

        // Remove itself
        await ContentfulApiService.deleteContent(sysId);

        currentStep++;
        currentProgress = (currentStep / totalSteps) * 100;
        dispatch(
            updateLoadingModalAction({
                open: true,
                title: loadingModalTitle,
                content: 'Removing the current section',
                progress: currentProgress,
            })
        );

        // Remove links in the referenced section
        let sectionsField = uiContent.referencedTheme.sections ?? [];
        let toRemoveSectionIndex;
        for (let i = 0; i < sectionsField.length; ++i) {
            const sectionField = sectionsField[i];
            if (sectionField.sys.id === sysId) {
                toRemoveSectionIndex = i;
                break;
            }
        }

        let referecedThemeResponse;
        if (toRemoveSectionIndex !== undefined) {
            sectionsField.splice(toRemoveSectionIndex, 1);
            if (sectionsField.length === 0) {
                sectionsField = undefined;
            }
            // console.log(
            //     '==prevSectionCardsField==',
            //     prevSectionCardsField
            // );
            dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: 'Removing the referenced module',
                    progress: currentProgress,
                })
            );
            referecedThemeResponse =
                await ContentfulApiService.updateContentFields(
                    uiContent.referencedTheme.id,
                    uiContent.referencedTheme.contentfulRawData,
                    {
                        sections: sectionsField,
                    }
                );
        }

        if (referecedThemeResponse) {
            currentStep++;
            currentProgress = (currentStep / totalSteps) * 100;
            dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: 'Publishing the current section',
                    progress: currentProgress,
                })
            );
            await ContentfulApiService.publish(referecedThemeResponse.sys.id);
            console.log('referenced learning card theme published');
        }

        dispatch(
            updateLoadingModalAction({
                open: false,
            })
        );
    }

    static async createUpdateLearningCardSlide(
        isUpdate: boolean,
        uiContent: LearningCardSlide
    ) {
        const { content } = uiContent;

        const fields: any = {};

        const lanFields = {
            headline: content.headline,
            description: content.description,
        };

        if (content.isFromVideo) {
            if (content.videoUrl) {
                fields.videoFromUrl = content.videoUrl;
            }
        } else if (content.image && !content.oldImage) {
            let asset;
            try {
                asset = await ContentfulApiService.createUpload(
                    content.image,
                    await readMediaBuffer(content.image)
                );
            } catch (err) {
                console.error(
                    '==createUpdateLearningCardSlide with image==',
                    err
                );
            }

            if (asset) {
                fields.learningCardSlideImage =
                    ContentfulTransformService.putLink(asset.sys.id);
            }
        }

        let response;

        console.log(
            '==createUpdateLearningCardSlide=isUpdate=',
            isUpdate,
            '==uiContent=',
            uiContent
        );

        if (isUpdate && uiContent.contentfulRawData) {
            response = await ContentfulApiService.updateContentFields(
                uiContent.contentfulRawData.sys.id,
                uiContent.contentfulRawData,
                fields,
                lanFields
            );
            console.log(
                '==createUpdateLearningCardSlide=2=response=',
                response
            );
        } else {
            response = await ContentfulApiService.createContent(
                'learningCardSlide',
                fields,
                lanFields
            );
        }
        return response;
    }

    static async createUpdateLearningCard(
        dispatch: Dispatch<AnyAction>,
        isUpdate: boolean,
        publish: boolean,
        uiContent: LearningCardType
    ) {
        const {
            content,
            identifierDefinition,
            targetGeography,
            targetUser,
            quiz,
        } = uiContent;

        // console.log('==uiContent==', uiContent);

        const getDate = ContentfulTransformService.putDate;
        // console.log('==identifierDefinition.startDate==', identifierDefinition.startDate);
        // console.log('==identifierDefinition.startTime==', identifierDefinition.startTime);
        const startDate = getDate(
            identifierDefinition.startDate,
            identifierDefinition.startTime
        );
        // console.log('==startDate==', startDate);
        const endDate = getDate(
            identifierDefinition.endDate,
            identifierDefinition.endTime
        );

        const fields: any = {
            name: identifierDefinition.name,
            language: identifierDefinition.appLanguages,
            startDate,
            tags: content.tags,
            region: targetGeography.regions,

            slides: [],

            stateprovince: targetGeography.states.length
                ? targetGeography.states
                : ['UNKNOWN'],
            distributors: targetGeography.distributors.length
                ? targetGeography.distributors
                : ['UNKNOWN'],
            district: targetGeography.districts.length
                ? targetGeography.districts
                : ['UNKNOWN'],
            pincode: `${targetGeography.pincode}`,
            rmArea: targetGeography.rmAreas.length
                ? targetGeography.rmAreas
                : ['UNKNOWN'],
            userType: targetUser.userTypes,
            spaceOfMechanic: targetUser.spaceOfMechanics,
            connekt: targetUser.connekt,
            uploadMobile: targetUser.mobile,
            uploadPid: targetUser.PID,
            dealerWsSegment: targetUser.dealerSegments,
            dealerWsSubSegment: targetUser.dealerSubSegments,
            mechanicsSegment: targetUser.mechanicsSegments,
            mechanicsSubSegment: targetUser.mechanicsSubSegments,
        };

        if (identifierDefinition.endDate) {
            fields.endDate = endDate;
        } else {
            fields.endDate = undefined;
        }

        const lanFields = {
            headline: content.headline,
            description: content.description,
        };

        // Open the loading modal
        const loadingModalTitle = 'Creating learning card';
        let currentProgress = 0;

        // Calculate total steps for progress
        const totalSteps = 10; //
        let currentStep = 0;

        // Create slides
        dispatch(
            updateLoadingModalAction({
                open: true,
                title: loadingModalTitle,
                content: 'Creating slides...',
                progress: currentProgress,
            })
        );
        const { slides } = content;
        for (let i = 0; i < slides.length; ++i) {
            dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: `Creating slide ${i + 1}...`,
                    progress: currentProgress,
                })
            );
            const slide = slides[i];
            const learningCardSlide = await this.createUpdateLearningCardSlide(
                isUpdate,
                slide
            );
            if (learningCardSlide) {
                slide.contentfulRawData = learningCardSlide;
                // Created linked entry
                fields.slides.push({
                    sys: {
                        id: learningCardSlide.sys.id,
                        linkType: 'Entry',
                        type: 'Link',
                    },
                });
            }
        }

        if (content.referencedSection) {
            fields.referencedSection = {
                sys: {
                    id: content.referencedSection.id,
                    linkType: 'Entry',
                    type: 'Link',
                },
            };
        }

        let response;

        if (isUpdate) {
            currentStep++;
            currentProgress = (currentStep / totalSteps) * 100;
            dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: 'Updating content fields of the learning card...',
                    progress: currentProgress,
                })
            );
            response = await ContentfulApiService.updateContentFields(
                uiContent.raw.sys.id,
                uiContent.raw,
                fields,
                lanFields
            );
        } else {
            currentStep++;
            currentProgress = (currentStep / totalSteps) * 100;
            dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: 'Creating content fields of the learning card...',
                    progress: currentProgress,
                })
            );
            response = await ContentfulApiService.createContent(
                'learningCard',
                fields,
                lanFields
            );
            uiContent.raw = response;
        }

        // Construct the target link of the notification
        // Points to the specific learning card parent's section
        uiContent.sendNotification.isLinkInternal = true;
        const learningCardSectionId = content.referencedSection?.id;
        fields.language.forEach((lang) => {
            if (learningCardSectionId) {
                uiContent.sendNotification.targetLink[
                    lang
                ] = `fastscan://learningCardSection/${learningCardSectionId}`;
            } else {
                uiContent.sendNotification.targetLink[lang] =
                    INTERNAL_PAGE_LINK['Home Page'];
            }
        });

        let customizedPushNotificationContent:
            | PushNotificationContent
            | undefined;

        const isPushNotification =
            uiContent.sendNotification.notificationType.includes(
                'Push notifications'
            );

        if (isPushNotification) {
            const previewText =
                uiContent.sendNotification.pushNotificationContent?.headline;
            const notificationText =
                uiContent.sendNotification.pushNotificationContent?.bodyText;

            customizedPushNotificationContent = {
                // notificationType: 'New Release',
                previewText,
                notificationText,
                isLinkInternal: uiContent.sendNotification.isLinkInternal,
                targetLink: uiContent.sendNotification.targetLink,
            };
        }

        currentStep++;
        currentProgress = (currentStep / totalSteps) * 100;
        dispatch(
            updateLoadingModalAction({
                open: true,
                title: loadingModalTitle,
                content: 'Creating notification...',
                progress: currentProgress,
            })
        );

        await this.createNotificationContent(
            identifierDefinition,
            uiContent.sendNotification,
            targetGeography,
            targetUser,
            customizedPushNotificationContent
        );

        // Quiz
        let quizResponse;
        let quizQuestions;
        if (quiz) {
            if (quiz.markDeleted) {
                const quizId = quiz.raw?.sys?.id;
                if (quizId) {
                    currentStep++;
                    currentProgress = (currentStep / totalSteps) * 100;
                    dispatch(
                        updateLoadingModalAction({
                            open: true,
                            title: loadingModalTitle,
                            content: 'Deleting the existing linked quiz...',
                            progress: currentProgress,
                        })
                    );
                    // Remove the learning card linked content
                    await ContentfulApiService.updateContentFields(
                        response.sys.id,
                        response,
                        {
                            quiz: undefined,
                        }
                    );
                    // Delete the linked quiz
                    await ContentfulApiService.deleteContent(quizId);
                }
            } else {
                const quizTypeContent: QuizType = {
                    raw: quiz.raw,
                    identifierDefinition,
                    content: quiz.content,
                    targetGeography,
                    targetUser,
                    linkedContentEntryId: response.sys.id,
                };
                currentStep++;
                currentProgress = (currentStep / totalSteps) * 100;
                dispatch(
                    updateLoadingModalAction({
                        open: true,
                        title: loadingModalTitle,
                        content: 'Creating the linked quiz...',
                        progress: currentProgress,
                    })
                );
                const result = await this.createUpdateQuiz(
                    undefined,
                    !!quiz.raw,
                    false,
                    quizTypeContent
                );
                quizResponse = result.response;
                quizQuestions = result.questions;
                // Update the video associated quiz
                await ContentfulApiService.updateContentFields(
                    response.sys.id,
                    response,
                    {
                        quiz: {
                            sys: {
                                id: quizResponse.sys.id,
                                linkType: 'Entry',
                                type: 'Link',
                            },
                        },
                    }
                );
            }
        }

        currentStep++;
        currentProgress = (currentStep / totalSteps) * 100;
        // Update the slides associated learning card
        for (let i = 0; i < slides.length; ++i) {
            dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: `Linking learning card to slide ${i + 1}...`,
                    progress: currentProgress,
                })
            );
            const slide = slides[i];
            await ContentfulApiService.updateContentFields(
                slide.contentfulRawData.sys.id,
                slide.contentfulRawData,
                {
                    learningCard: {
                        sys: {
                            id: response.sys.id,
                            linkType: 'Entry',
                            type: 'Link',
                        },
                    },
                }
            );
        }

        const sectionChanged = !(
            content.prevReferencedSection &&
            content.referencedSection &&
            content.prevReferencedSection.id === content.referencedSection.id
        );
        console.log(
            '=createUpdateLearningCard=sectionChanged==',
            sectionChanged
        );
        console.log('==content.referencedSection==', content.referencedSection);
        console.log(
            '==content.prevReferencedSection==',
            content.prevReferencedSection
        );
        // Update the prev referenced section;
        // console.log('==prevReferencedSection==', content.prevReferencedSection);
        // console.log('==referencedSection==', content.referencedSection);
        let prevReferecedSectionResponse;
        let referecedSectionResponse;

        if (sectionChanged) {
            if (content.prevReferencedSection) {
                const prevReferecedSectionContentfulRawData =
                    content.prevReferencedSection.contentfulRawData;
                let prevSectionCardsField =
                    content.prevReferencedSection.cards ?? [];
                if (prevSectionCardsField.length > 0) {
                    let toRemoveCardIndex;
                    for (let i = 0; i < prevSectionCardsField.length; ++i) {
                        const cardField = prevSectionCardsField[i];
                        if (cardField.sys.id === response.sys.id) {
                            toRemoveCardIndex = i;
                            break;
                        }
                    }
                    if (toRemoveCardIndex !== undefined) {
                        prevSectionCardsField.splice(toRemoveCardIndex, 1);
                        if (prevSectionCardsField.length === 0) {
                            prevSectionCardsField = undefined;
                        }
                        // console.log(
                        //     '==prevSectionCardsField==',
                        //     prevSectionCardsField
                        // );
                        currentStep++;
                        currentProgress = (currentStep / totalSteps) * 100;
                        dispatch(
                            updateLoadingModalAction({
                                open: true,
                                title: loadingModalTitle,
                                content: 'Removing the previously linked section...',
                                progress: currentProgress,
                            })
                        );
                        prevReferecedSectionResponse =
                            await ContentfulApiService.updateContentFields(
                                content.prevReferencedSection.id,
                                prevReferecedSectionContentfulRawData,
                                {
                                    cards: prevSectionCardsField,
                                }
                            );
                    }
                }
            }
            // Update the new referenced section
            const referecedSectionContentfulRawData =
                content.referencedSection.contentfulRawData;
            const cardsField = content.referencedSection.cards ?? [];
            // Check if the ID already exists
            let cardAlreadyExists = false;
            for (let i = 0; i < cardsField.length; ++i) {
                const cardField = cardsField[i];
                if (cardField.sys.id === response.sys.id) {
                    cardAlreadyExists = true;
                    break;
                }
            }
            if (!cardAlreadyExists) {
                cardsField.push({
                    sys: {
                        id: response.sys.id,
                        linkType: 'Entry',
                        type: 'Link',
                    },
                });
            }
            currentStep++;
            currentProgress = (currentStep / totalSteps) * 100;
            dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: 'Linking the referenced section...',
                    progress: currentProgress,
                })
            );
            // console.log('==cardsField==', cardsField);
            referecedSectionResponse =
                await ContentfulApiService.updateContentFields(
                    content.referencedSection.id,
                    referecedSectionContentfulRawData,
                    {
                        cards: cardsField,
                    }
                );
        }

        currentProgress = 100;

        if (publish) {
            try {
                if (prevReferecedSectionResponse) {
                    dispatch(
                        updateLoadingModalAction({
                            open: true,
                            title: loadingModalTitle,
                            content: 'Publishing the changes of previously linked section...',
                            progress: currentProgress,
                        })
                    );
                    await ContentfulApiService.publish(
                        prevReferecedSectionResponse.sys.id
                    );
                    console.log(
                        'referenced prev learning card section published'
                    );
                }
                if (referecedSectionResponse) {
                    dispatch(
                        updateLoadingModalAction({
                            open: true,
                            title: loadingModalTitle,
                            content: 'Publishing the linked section...',
                            progress: currentProgress,
                        })
                    );
                    await ContentfulApiService.publish(
                        referecedSectionResponse.sys.id
                    );
                    console.log('referenced learning card section published');
                }
                if (quizQuestions) {
                    for (let i = 0; i < quizQuestions.length; ++i) {
                        dispatch(
                            updateLoadingModalAction({
                                open: true,
                                title: loadingModalTitle,
                                content: `Publishing the quiz question ${i + 1}...`,
                                progress: currentProgress,
                            })
                        );
                        await ContentfulApiService.publish(
                            quizQuestions[i].sys.id
                        );
                    }
                }
                if (quizResponse) {
                    dispatch(
                        updateLoadingModalAction({
                            open: true,
                            title: loadingModalTitle,
                            content: 'Publishing the linked quiz...',
                            progress: currentProgress,
                        })
                    );
                    await ContentfulApiService.publish(quizResponse.sys.id);
                }
                console.log('learning card quiz published');
                // Publish learning card schemes first
                for (let i = 0; i < fields.slides.length; ++i) {
                    dispatch(
                        updateLoadingModalAction({
                            open: true,
                            title: loadingModalTitle,
                            content: `Publishing the slide ${i + 1}...`,
                            progress: currentProgress,
                        })
                    );
                    await ContentfulApiService.publish(fields.slides[i].sys.id);
                }
                console.log('learning card slide published');
                dispatch(
                    updateLoadingModalAction({
                        open: true,
                        title: loadingModalTitle,
                        content: `Publishing the learning card...`,
                        progress: currentProgress,
                    })
                );
                // Publish learning card
                await ContentfulApiService.publish(response.sys.id);
                console.log('learning card published');
            } catch (e) {
                console.log('published error', e);
            }
        }

        dispatch(
            updateLoadingModalAction({
                open: false,
            })
        );

        return { response, slides: fields.slides };
    }

    static async deleteLearningCard(
        dispatch: Dispatch<AnyAction>,
        uiContent: LearningCardType
    ) {
        const { content } = uiContent;

        // Open the loading modal
        const loadingModalTitle = 'Deleting learning card';
        let currentProgress = 0;

        // Remove all learning cards slides first
        const { slides } = content;

        // Calculate total steps for progress
        const totalSteps = slides.length + 3; // Slides + quiz + self-deletion + section update
        let currentStep = 0;

        for (let i = 0; i < slides.length; ++i) {
            const slide = slides[i];
            const content = `Deleting slide #${i + 1}...`;
            currentStep++;
            currentProgress = (currentStep / totalSteps) * 100;
            dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content,
                    progress: currentProgress,
                })
            );
            await ContentfulApiService.deleteContent(
                slide.contentfulRawData.sys.id
            );
        }

        // If it contains linked quiz, delete the quiz
        if (uiContent.quiz) {
            currentStep++;
            currentProgress = (currentStep / totalSteps) * 100;
            dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: 'Deleting the linked quiz...',
                    progress: currentProgress,
                })
            );
            // await CreationService.deleteQuiz(
            //     undefined,
            //     uiContent.quiz.raw.sys.id
            // );
            await ContentfulApiService.deleteContent(uiContent.quiz.raw.sys.id);
        }

        const sysId = uiContent.raw.sys.id;

        currentStep++;
        currentProgress = (currentStep / totalSteps) * 100;
        // Remove itself
        dispatch(
            updateLoadingModalAction({
                open: true,
                title: loadingModalTitle,
                content: 'Deleting the current learning card...',
                progress: currentProgress,
            })
        );
        await ContentfulApiService.deleteContent(sysId);

        currentStep++;
        currentProgress = (currentStep / totalSteps) * 100;
        dispatch(
            updateLoadingModalAction({
                open: true,
                title: loadingModalTitle,
                content: 'Deleting the current learning card...',
                progress: currentProgress,
            })
        );

        // Remove links in the referenced section
        let cardsField = content.referencedSection.cards ?? [];
        let toRemoveCardIndex;
        for (let i = 0; i < cardsField.length; ++i) {
            const cardField = cardsField[i];
            if (cardField.sys.id === sysId) {
                toRemoveCardIndex = i;
                break;
            }
        }

        let referecedSectionResponse;
        if (toRemoveCardIndex !== undefined) {
            cardsField.splice(toRemoveCardIndex, 1);
            if (cardsField.length === 0) {
                cardsField = undefined;
            }
            dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: 'Updating referenced section...',
                    progress: currentProgress,
                })
            );
            referecedSectionResponse =
                await ContentfulApiService.updateContentFields(
                    content.referencedSection.id,
                    content.referencedSection.contentfulRawData,
                    {
                        cards: cardsField,
                    }
                );
        }

        if (referecedSectionResponse) {
            currentStep++;
            currentProgress = (currentStep / totalSteps) * 100;
            dispatch(
                updateLoadingModalAction({
                    open: true,
                    title: loadingModalTitle,
                    content: 'Publishing referenced section...',
                    progress: currentProgress,
                })
            );
            await ContentfulApiService.publish(referecedSectionResponse.sys.id);
            console.log('referenced learning card section published');
        }
        // Close the loading modal
        dispatch(
            updateLoadingModalAction({
                open: false,
            })
        );
    }
}
