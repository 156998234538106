import axios from 'axios';
import moment from 'moment';
import 'moment-timezone';
import { GLOBAL_CONFIG } from '../config/config';
import { LookupValue } from '../types';
import {
    getCountry,
    getCountryTag,
    getDefaultTimezone,
    getMetaTag,
} from '../utils';
import { COUNTRY_TAG_MAP } from '../common/constants';

const api = axios.create({
    baseURL: GLOBAL_CONFIG.APP_API_BASE,
});

api.interceptors.request.use(
    (config) => {
        // config.headers.Authorization = `Bearer ${idToken}`;
        config.headers.Authorization = `Bearer ${sessionStorage.getItem(
            'apiToken'
        )}`;
        // config.headers['x-fastscan-deviceid'] = '2f180b6a7105bb79';
        config.headers['x-fastscan-lookup-client-key'] =
            '3K4M6P7Q8SATBUDWEXFZH2J3K5N6P7R9SATCVDWEYGZH2K4M5N7Q8R9TBU';
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export interface DropDownPopulate {
    distributors: LookupValue[];
    regions: LookupValue[];
    provinces: LookupValue[];
    districts: LookupValue[];
    sectors: LookupValue[];
    subSectors: { [key: string]: LookupValue[] };
}

interface AndroidNotification {
    title: string;
    body: string;
    sound?: string;
    channelId?: string;
    priority?: 'min' | 'low' | 'default' | 'high' | 'max';
}

interface AndroidConfig {
    priority: 'high' | 'normal';
    notification: AndroidNotification;
}
interface PushNotificationContent {
    title: string;
    body: string;
    data?: any;
    android?: AndroidConfig;
}

type ValidUserTypes = 'Workshop Owner' | 'Mechanic';

export interface PushNotificationApiPayload {
    country: string;
    sectorId: string[] | null;
    subSectorId: string[] | null;
    mechanicSectorId: string[] | null;
    mechanicSubSectorId: string[] | null;
    mechanicSpace: string[] | null;
    userType: ValidUserTypes[];
    language: string;
    notificationContent: PushNotificationContent;
    startDate: string;
}

export interface OilSelectorConfig {
    country: string;
    oilSelectorEnabled: boolean;
}

export interface ScanningContestProductsQueryParams {
    productName?: string;
    brandName?: string;
    skuCode?: string;
    page?: number;
    perPage?: number;
}

export interface ScanningContestProductPayload {
    productName: string;
    brandName: string;
    skuCode: string;
}

class ApiCache {
    static sectors: any[];
    static subSectors = {};
}

// Application backend api service
export class ApiService {
    static resolveComments(data: any, resolve: any) {
        const result = data.result;
        const newData = result.map((r) => {
            const updateDate = moment.tz(r.updatedAt, getDefaultTimezone());
            const minDiff = moment
                .tz(getDefaultTimezone())
                .diff(updateDate, 'minutes');
            let datePass = `${minDiff} mins ago`;
            if (minDiff >= 60) {
                const hourDiff = minDiff / 60;
                datePass = `${Math.floor(hourDiff)} hours ago`;
                if (hourDiff >= 24) {
                    datePass = `${Math.floor(minDiff / (60 * 24))} days ago`;
                }
            }

            // todo: backend should return image, likes, dislikes
            return {
                id: r.id,
                author: {
                    name: `${r.firstName} ${r.lastName}`,
                    image: 'https://picsum.photos/80/80',
                },
                content: r.comment,
                date: datePass,
                dateRaw: r.updatedAt,
                likes: 8,
                dislikes: 2,
            };
        });

        resolve(newData);
    }

    // static async likeContent(entryId: string, likeType: 'LIKE' | 'DISLIKE') {
    //     const spaceId = 'spaceIdPost1';
    //     const envId = 'envIdPost1';
    //     const contentType = 'contentType1';

    //     // return true;
    //     return api.post(
    //         `/content/like`,
    //         {
    //             spaceId: 'spaceIdPost1',
    //             envId: 'envIdPost1',
    //             contentType: 'contentType1',
    //             entryId,
    //             likeType: likeType,
    //         },
    //         {
    //             headers: {
    //                 'x-fastscan-deviceid': 'web-app-admin',
    //                 'Authorization': `Bearer ${sessionStorage.getItem('apiIdToken')}`
    //             },
    //         }
    //     );
    // }

    // first login to contentful, then use the returned token to login to fastscan api
    static async login(contentfulToken) {
        // todo: replace mock login

        const {
            data: { token },
        } = await api.post('/content/adminToken', {
            contentfulToken,
        });
        return token;
    }

    // static async profile() {
    //     const {
    //         data,
    //     } = await api.get('/auth/user');

    //     console.log("api profile", data)

    //     return data;
    // }

    // first login to contentful, then use the returned token to login to fastscan api
    static async createComment(parentId: string, comment: string) {
        // todo: replace mock login

        const { data } = await api.post(
            '/content/adminComments',
            {
                parentId,
                comment,
            },
            {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem(
                        'contentfulToken'
                    )}`,
                },
            }
        );

        console.log('created comment', data);
        return data;
    }

    // todo: remove mocke   d entryId
    static getComments(
        entryId: string,
        spaceId: string,
        envId: string,
        contentType: string
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            // const spaceId = 'spaceIdPost1';
            // const envId = 'envIdPost1';
            // const contentType = 'contentType1';

            // response example
            // {
            //     "result": [
            //         {
            //             "id": "fc4caf6a-166c-4c86-a6a2-8b8c64a95cbb",
            //             "firstName": "feyverly dede",
            //             "lastName": "tester",
            //             "comment": "weo",
            //             "parentId": null,
            //             "admin": null,
            //             "createdAt": "2022-03-09T07:27:44.951Z",
            //             "updatedAt": "2022-03-09T07:27:44.951Z"
            //         } ,
            //         ]
            // }
            api.get(`/content/comments`, {
                headers: {
                    // 'x-fastscan-lookup-client-key': '3K4M6P7Q8SATBUDWEXFZH2J3K5N6P7R9SATCVDWEYGZH2K4M5N7Q8R9TBU',
                },
                params: {
                    spaceId,
                    envId,
                    contentType,
                    entryId,
                },
            })
                .then((res) => {
                    ApiService.resolveComments(res.data, resolve);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    // todo: remove mocked entryId
    static async getViews(
        entryId: string,
        spaceId: string,
        envId: string,
        contentType: string
    ): Promise<any> {
        // const spaceId = 'spaceIdPost1';
        // const envId = 'envIdPost1';
        // const contentType = 'contentType1';

        const response = await api.get('/content/views', {
            params: {
                spaceId,
                envId,
                contentType,
                entryId,
            },
        });

        // example response
        // {
        //     "spaceId": "spaceIdPost1",
        //     "envId": "envIdPost1",
        //     "contentType": "contentType1",
        //     "entryId": "entryIdPost1",
        //     "views": 3,
        //     "uniqueViews": 3
        // }

        return response.data;
    }

    // FIXME The api is incorrect
    static async getDistributors(): Promise<any> {
        const response = await api.get('/lookup/distributors', {
            params: {
                'metadata.tags.sys.id[in]': getMetaTag(),
            },
        });
        return response.data;
    }

    // FIXME The api is incorrect
    static async getRegions(): Promise<any> {
        const response = await api.get('/lookup/regions', {
            params: {
                'metadata.tags.sys.id[in]': getMetaTag(),
            },
        });
        return response.data;
    }

    // FIXME The api is incorrect
    static async getProvinces(): Promise<any> {
        const response = await api.get('/lookup/provinces', {
            params: {
                'metadata.tags.sys.id[in]': getMetaTag(),
            },
        });
        return response.data;
    }

    // FIXME The api is incorrect
    static async getDistricts(): Promise<any> {
        const response = await api.get('/lookup/districts', {
            params: {
                'metadata.tags.sys.id[in]': getMetaTag(),
            },
        });
        return response.data;
    }

    static async getSectors(): Promise<any> {
        if (!ApiCache.sectors) {
            const response = await api.get('/lookup/sectors', {
                params: {
                    country: getCountryTag(),
                },
            });
            ApiCache.sectors = response.data;
        }
        return ApiCache.sectors;
    }

    static async getSubsectors(sectorId: string): Promise<any> {
        if (!ApiCache.subSectors[sectorId]) {
            const response = await api.get('/lookup/subSectors', {
                params: {
                    country: getCountryTag(),
                    sectorId,
                },
            });
            ApiCache.subSectors[sectorId] = response.data;
        }
        return ApiCache.subSectors[sectorId];
    }

    // todo: where to get rmArea options
    static async getDropdown(): Promise<DropDownPopulate> {
        const distributors = []; // await this.getDistributors();
        const regions = []; // await this.getRegions();
        const provinces = []; // await this.getProvinces();
        const districts = []; // await this.getDistricts();
        const sectors = await this.getSectors();
        const subSectors = {};
        for (const sector of sectors) {
            const sectorId = sector.id;
            subSectors[sectorId] = await this.getSubsectors(sectorId);
        }

        return {
            distributors,
            regions,
            provinces,
            districts,
            sectors,
            subSectors,
        };
    }

    static async pushNotifications(
        payload: PushNotificationApiPayload
    ): Promise<any> {
        return api.post('/content/pushNotifications', payload, {
            headers: {
                'x-fastscan-deviceid': 'web-app-admin',
                Authorization: `Bearer ${sessionStorage.getItem('apiIdToken')}`,
            },
        });
    }

    static async getOilSelectorConfig(): Promise<any> {
        return await api.get('/content/oilSelectorConfig');
    }

    static async setOilSelectorConfig(
        payload: OilSelectorConfig[]
    ): Promise<any> {
        return api.post('/content/oilSelectorConfig', payload, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('apiIdToken')}`,
            },
        });
    }

    static async getQuizzesDashboardStats(): Promise<any> {
        return await api.get('/content/quizzes/dashboardStats', {
            params: {
                countryMetaCode: COUNTRY_TAG_MAP[getCountry()],
            },
        });
    }

    static async getSingleQuizStats(quizId: string): Promise<any> {
        return await api.get(`/content/quizzes/${quizId}/statistics`, {
            params: {
                countryMetaCode: COUNTRY_TAG_MAP[getCountry()],
            },
        });
    }

    static async getRegisterContestsDashboardStats(): Promise<any> {
        return await api.get('/content/registerContests/dashboardStats', {
            params: {
                countryMetaCode: COUNTRY_TAG_MAP[getCountry()],
            },
        });
    }
    static async getProductFinderStats(): Promise<any> {
        return await api.get('/content/productfinder/dashboardStats');
    }

    static async getScanningContestsDashboardStats(): Promise<any> {
        return await api.get('/content/scanningContests/dashboardStats', {
            params: {
                countryMetaCode: COUNTRY_TAG_MAP[getCountry()],
            },
        });
    }

    static async getSingleScanningContestStats(scanningContestId: string): Promise<any> {
        return await api.get(`/content/scanningContests/${scanningContestId}/statistics`, {
            params: {
                countryMetaCode: COUNTRY_TAG_MAP[getCountry()],
            },
        });
    }

    static async getScanningContestProducts(
        params?: ScanningContestProductsQueryParams
    ): Promise<any> {
        return await api.get('/content/products', {
            params,
        });
    }

    static async addScanningContestProduct(
        payload: ScanningContestProductPayload
    ): Promise<any> {
        return await api.post('/content/products', payload, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('apiIdToken')}`,
            },
        });
    }

    static async updateScanningContestProduct(
        productId: string,
        payload: ScanningContestProductPayload
    ): Promise<any> {
        return await api.put(`/content/products/${productId}`, payload, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('apiIdToken')}`,
            },
        });
    }

    static async deleteScanningContestProduct(productId: string): Promise<any> {
        return await api.delete(`/content/products/${productId}`);
    }

    // Learning cards
    static async updateLearningCardTheme(entryId: string): Promise<any> {
        const payload = {
            entryId,
        };
        return await api.post('/content/learningCards/updateTheme', payload, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('apiIdToken')}`,
            },
        });
    }

    static async updateLearningCardSection(entryId: string): Promise<any> {
        const payload = {
            entryId,
        };
        return await api.post('/content/learningCards/updateSection', payload, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('apiIdToken')}`,
            },
        });
    }

    static async updateLearningCard(entryId: string): Promise<any> {
        const payload = {
            entryId,
        };
        return await api.post('/content/learningCards/updateCard', payload, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('apiIdToken')}`,
            },
        });
    }

    static async getLearningCardThemesDashboardStats(): Promise<any> {
        return await api.get('/content/learningCardThemes/dashboardStats', {
            params: {
                countryMetaCode: COUNTRY_TAG_MAP[getCountry()],
            },
        });
    }

    static async getLearningCardSectionsDashboardStats(): Promise<any> {
        return await api.get('/content/learningCardSections/dashboardStats', {
            params: {
                countryMetaCode: COUNTRY_TAG_MAP[getCountry()],
            },
        });
    }

    static async getLearningCardsDashboardStats(): Promise<any> {
        return await api.get('/content/learningCards/dashboardStats', {
            params: {
                countryMetaCode: COUNTRY_TAG_MAP[getCountry()],
            },
        });
    }

    static async getSingleLearningCardThemeStats(themeId: string): Promise<any> {
        return await api.get(`/content/learningCardThemes/${themeId}/statistics`, {
            params: {
                countryMetaCode: COUNTRY_TAG_MAP[getCountry()],
            },
        });
    }

    static async getSingleLearningCardSectionStats(sectionId: string): Promise<any> {
        return await api.get(`/content/learningCardSections/${sectionId}/statistics`, {
            params: {
                countryMetaCode: COUNTRY_TAG_MAP[getCountry()],
            },
        });
    }

    static async getSingleLearningCardStats(learningCardId: string): Promise<any> {
        return await api.get(`/content/learningCards/${learningCardId}/statistics`, {
            params: {
                countryMetaCode: COUNTRY_TAG_MAP[getCountry()],
            },
        });
    }
}
