import React from 'react';
import styled from 'styled-components';
import { Modal } from '../components/Modal';
import { Text } from '../components/Text';
import { COLORS } from '../styles/variables';
import { useSelector } from 'react-redux';
import { AppState, LoadingModalState } from '../store/store.types';

interface Props {}

const ProgressBar = styled.div<{ progress: number }>`
    width: 100%;
    background-color: ${COLORS.lightGray};
    border-radius: 5px;
    overflow: hidden;
    margin: 1em 0 0 0;

    &::before {
        content: '';
        display: block;
        height: 10px;
        width: ${(props) => Math.min(props.progress, 100)}%;
        background-color: ${COLORS.green};
        transition: width 0.2s ease;
    }
`;

const LoadingContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 1em;
`;

export const LoadingModal = ({}: Props) => {
    const loadingModal = useSelector<AppState, LoadingModalState>((s) => s.loadingModal);
    const { open, title, progress, content } = loadingModal;

    return (
        <Modal
            open={open}
            header={(title || title === '') ? "Loading..." : title}
            content={
                <LoadingContent>
                    <Text color={COLORS.darkGray}>{content}</Text>
                    <ProgressBar progress={progress} />
                </LoadingContent>
            }
            actions={null} // No actions needed for loading modal
        />
    );
};

